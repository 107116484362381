import React from "react";
import { Table, Row, Col } from "reactstrap";
import { regularValue } from "functions";

export default (props) => {
  const { entity } = props;

  return (
    <React.Fragment>
      <Row>
        <Col className={"pr-0"}>
          <Table borderless={true} size={"sm"} className={"mb-0"}>
            <tbody>
              <tr>
                <th>Smoke</th>
                <td>{regularValue(entity.smoke)}</td>
              </tr>
              <tr>
                <th>Claudication</th>
                <td>{regularValue(entity.claudication)}</td>
              </tr>
              <tr>
                <th>Gangrene</th>
                <td>{regularValue(entity.gangrene)}</td>
              </tr>
              <tr>
                <th>Prev Vasc Surg</th>
                <td>{regularValue(entity.prev_vasc_surg)}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col className={"px-1"}>
          <Table borderless={true} size={"sm"} className={"mb-0"}>
            <tbody>
              <tr>
                <th>Diabetic</th>
                <td>{regularValue(entity.diabetic)}</td>
              </tr>
              <tr>
                <th>Skin Color Change</th>
                <td>{regularValue(entity.skin_color_change)}</td>
              </tr>
              <tr>
                <th>Hyperlipidemia</th>
                <td>{regularValue(entity.hyperlipidemia)}</td>
              </tr>
              <tr>
                <th>Prev Angioplasty</th>
                <td>{regularValue(entity.prev_angioplasty)}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col className={"pl-0"}>
          <Table borderless={true} size={"sm"} className={"mb-0"}>
            <tbody>
              <tr>
                <th>Rest Pain</th>
                <td>{regularValue(entity.rest_pain)}</td>
              </tr>
              <tr>
                <th>Vascular Ulcers</th>
                <td>{regularValue(entity.vascular_ulcers)}</td>
              </tr>
              <tr>
                <th>Hypertension</th>
                <td>{regularValue(entity.hypertension)}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </React.Fragment>
  );
};
