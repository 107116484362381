import React, { Component } from "react";
import { withRouter } from "react-router";

import {
  Container,
  Row,
  Col,
  Button,
  //InputGroup,
  //InputGroupAddon,
  //Input,
  Table,
} from "reactstrap";
import { Helmet } from "react-helmet";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SortableTh from "components/Elements/SortableTh/SortableTh";
import PaginationPanel from "components/Elements/PaginationPanel/PaginationPanel";
import CreateUser from "components/Elements/Modals/CreateUser";
import axiosInstance from "axios-instance";
import { Link } from "react-router-dom";

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      createModalActive: false,
      submitting: false,
      pagination: {
        list: [],
        total: 0,
        page: 1,
        limit: 10,
        start: 0,
        end: 10,
        sort: null,
        direction: null,
      },
      conditions: {
        phraze: "",
      },
    };
    const { page, limit, sort, direction } = this.state.pagination;
    this.getList({ page, limit, sort, direction });
  }

  saveUser = (postData) => {
    this.setState(
      {
        submitting: true,
      },
      () => {
        axiosInstance()
          .post(`/admin/users.json`, postData)
          .then((response) => {
            this.setState({
              submitting: false,
              createModalActive: false,
            });
            const { page, limit, sort, direction } = this.state.pagination;
            this.getList({ page, limit, sort, direction });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };
  updateForm = (event, name) => {
    const value =
      event !== null && event.target !== undefined ? event.target.value : event;

    const conditions = JSON.parse(JSON.stringify(this.state.conditions));
    conditions.date_from = this.state.conditions.date_from;
    conditions.date_to = this.state.conditions.date_to;
    conditions[name] = value;

    this.setState(
      {
        conditions: conditions,
      },
      () => {
        const { limit, sort, direction } = this.state.pagination;
        this.getList({ page: 1, limit, sort, direction });
      }
    );
  };

  getList = (getData) => {
    getData.conditions = {
      phraze: this.state.conditions.phraze,
    };

    axiosInstance()
      .get("/admin/users.json", { params: getData })
      .then((response) => {
        this.setState({
          pagination: {
            ...response.data,
            conditions: this.state.pagination.conditions,
          },
          loaded: true,
        });
      });
  };

  toggle = (modalType) => {
    this.setState((prevState) => ({
      [modalType]: !prevState[modalType],
    }));
  };

  render = () => {
    return (
      <Container>
        <Helmet>
          <title>NewportX | Admin | Manage Users</title>
        </Helmet>
        {this.state.loaded && (
          <div className={"pt-4"}>
            <div className={"d-flex align-items-center"}>
              <h2 className={"flex-grow-1 font-weight-bold"}>Users</h2>
              <Button
                color={"primary"}
                onClick={() => this.toggle("createModalActive")}
              >
                Create New User
              </Button>

              {/*<InputGroup className={"w-50"}>
                <InputGroupAddon addonType="prepend">
                  <span className={"input-group-text"}>
                    <FontAwesomeIcon icon={["fas", "search"]} />
                  </span>
                </InputGroupAddon>
                <Input
                  placeholder={"Search by name or email"}
                  className={"input-search"}
                />
              </InputGroup> */}
            </div>
            <div className={"pt-3 pb-5"}>
              <Row className={"pt-1"}>
                <Col>
                  <Table className={"mt-3"} hover={true} bordered={true}>
                    <thead>
                      <tr>
                        <SortableTh
                          {...this.state.pagination}
                          label={"User Name"}
                          field={"fullname"}
                          paginationFunc={this.getList}
                        />
                        <SortableTh
                          {...this.state.pagination}
                          label={"Designation"}
                          field={"designation"}
                          paginationFunc={this.getList}
                        />
                        <SortableTh
                          {...this.state.pagination}
                          label={"Email"}
                          field={"email"}
                          paginationFunc={this.getList}
                        />
                        <SortableTh
                          {...this.state.pagination}
                          label={"Role"}
                          field={"role"}
                          paginationFunc={this.getList}
                        />
                        <SortableTh
                          {...this.state.pagination}
                          label={"Status"}
                          field={"status"}
                          paginationFunc={this.getList}
                        />
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.pagination.list.map((item, key) => {
                        return (
                          <tr key={"user-" + key} className={"pointer"}>
                            <td>{item.fullname}</td>
                            <td>{item.designation}</td>
                            <td>{item.email}</td>
                            <td>{item.role}</td>
                            <td>{item.status === 1 ? "Active" : "Inactive"}</td>
                            <td>
                              <Link to={`/admin/users/${item.id}`}>
                                Edit User
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <PaginationPanel
                {...this.state.pagination}
                list={null}
                paginationFunc={this.getList}
                phraze={""}
              />
            </div>
          </div>
        )}

        <CreateUser
          isActive={this.state.createModalActive}
          toggle={
            this.state.submitting
              ? null
              : () => this.toggle("createModalActive")
          }
          entity={this.state.entity}
          onSubmit={this.saveUser}
          submitting={this.state.submitting}
        />
      </Container>
    );
  };
}

export default withRouter(ManageUsers);
