import * as actionTypes from "./actionTypes";
import axiosInstance from "../../axios-instance";

export const authClear = () => {
  return {
    type: actionTypes.AUTH_CLEAR,
  };
};
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (authData) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    authData: authData,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("parts");
  localStorage.removeItem("validTo");
  localStorage.removeItem("rememberMe");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const authData = localStorage.getItem("auth");
    if (authData) {
      const validTo = localStorage.getItem("validTo");
      if (
        validTo === null ||
        parseInt(validTo, 10) < Math.floor(Date.now() / 1000)
      ) {
        dispatch(logout());
      } else {
        dispatch(authSuccess(JSON.parse(authData)));
        if (localStorage.getItem("rememberMe") !== null) {
          localStorage.setItem(
            "validTo",
            Math.floor(Date.now() / 1000) + 2592000
          );
        } else {
          localStorage.setItem("validTo", Math.floor(Date.now() / 1000) + 1800);
        }
      }
    }
  };
};

export const systemCheck = () => {
  return (dispatch) => {
    const authData = localStorage.getItem("auth");
    if (authData) {
      axiosInstance()
        .get("/users/check-status.json")
        .then((response) => {
          if (response.data.status === 0) {
            dispatch(logout());
          }
        })
        .catch((err) => {
          if (err.response.data.message === "Expired token") {
            dispatch(logout());
          }
        });
    }
  };
};
export const updateAccount = (user) => {
  return (dispatch) => {
    const authData = JSON.parse(localStorage.getItem("auth"));
    authData.user = { ...authData.user, ...user };
    localStorage.setItem("auth", JSON.stringify(authData));
    dispatch(authSuccess(authData));
  };
};

export const auth = (email, password, rememberMe) => {
  return (dispatch) => {
    dispatch(authStart());
    const authData = {
      email: email,
      password: password,
    };
    axiosInstance()
      .post("/users/token.json", authData)
      .then((response) => {
        console.log(response.data);
        localStorage.setItem("auth", JSON.stringify(response.data));
        if (rememberMe) {
          localStorage.setItem(
            "validTo",
            Math.floor(Date.now() / 1000) + 2592000
          );
          localStorage.setItem("rememberMe", true);
        } else {
          localStorage.setItem("validTo", Math.floor(Date.now() / 1000) + 1800);
          localStorage.removeItem("rememberMe");
        }
        dispatch(authSuccess(response.data));
      })
      .catch((err) => {
        if (err.response !== undefined) {
          dispatch(authFail(err.response.data.message));
        } else {
          dispatch(authFail("Problem with connection to API."));
        }
      });
  };
};
