import React, { Component } from "react";
import { Button } from "reactstrap";
import Select from "react-select";
import LabelInput from "components/Elements/LabelInput/LabelInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AreaChart from "./AreaChart";

const FormSelect = (props) => {
  return (
    <div className={"d-inline-block px-2 mb-1"} style={{ minWidth: "160px" }}>
      <Select
        value={props.value}
        options={props.options}
        menuPlacement={"auto"}
        styles={props.styles}
        isClearable={true}
        isDisabled={props.disabled}
        autoSize={true}
        onChange={props.onChange}
      />
    </div>
  );
};

const Type1 = (props) => {
  const { item, index, findings, styles, disabled } = props;
  const isFreeType =
    item.field_2 !== null &&
    ((item.field_2.label !== null && item.field_2.label === "[free type]") ||
      item.field_2 === "[free type]");

  return (
    <div className={"pb-1"}>
      There is decreased tracer uptake in the{" "}
      <FormSelect
        value={
          item.field_1 !== null
            ? isNaN(item.field_1)
              ? findings[12].find((x) => x.label === item.field_1)
              : parseInt(item.field_1)
            : ""
        }
        options={findings[12]}
        styles={styles}
        autoSize={true}
        disabled={disabled}
        onChange={(event) => {
          props.updateSentence(1, index, "field_1", event);
        }}
      />{" "}
      wall(s) consistent with{" "}
      <FormSelect
        value={
          item.field_2 !== null
            ? isNaN(item.field_2)
              ? findings[13].find((x) => x.label === item.field_2)
              : parseInt(item.field_1)
            : ""
        }
        options={findings[13]}
        styles={styles}
        autoSize={true}
        disabled={disabled || isFreeType}
        onChange={(event) => {
          props.updateSentence(1, index, "field_2", event);
        }}
      />
      {isFreeType && (
        <div className={"d-inline-block"}>
          <div className={"d-flex align-items-center"}>
            <LabelInput
              type={"text"}
              size={"sm"}
              className={"form-control form-control-sm"}
              formGroupClass={"mb-0"}
              value={item.field_2_free_type}
              onChange={(event) =>
                props.updateSentence(1, index, "field_2_free_type", event)
              }
            />
            <FontAwesomeIcon
              className={"mr-2 ml-2 pointer"}
              icon={["fas", "times"]}
              onClick={(event) => {
                props.updateSentence(1, index, "field_2", null);
              }}
            />
          </div>
        </div>
      )}
      .
      <Button
        color={"primary"}
        size={"sm"}
        className={"ml-2"}
        onClick={() => props.addSentence(props.type)}
      >
        Repeat
      </Button>
      <Button
        color={"danger"}
        size={"sm"}
        className={"ml-2"}
        onClick={() => props.onDelete(props.type, index)}
      >
        Delete
      </Button>
    </div>
  );
};

const Type2 = (props) => {
  const { item, index, findings, styles, disabled } = props;

  const field1 = (
    <FormSelect
      value={
        item.field_1 !== null && item.field_1 !== ""
          ? findings[15].find((x) => x.label === item.field_1)
          : ""
      }
      options={findings[15]}
      styles={styles}
      disabled={disabled}
      autoSize={true}
      onChange={(event) => {
        props.updateSentence(2, index, "field_1", event);
      }}
    />
  );
  const field2 = (
    <FormSelect
      value={
        item.field_2 !== null && item.field_2 !== ""
          ? findings[16].find((x) => x.label === item.field_2)
          : ""
      }
      options={findings[16]}
      styles={styles}
      disabled={disabled}
      autoSize={true}
      onChange={(event) => {
        props.updateSentence(2, index, "field_2", event);
      }}
    />
  );
  const field4 = (
    <FormSelect
      value={
        item.field_4 !== null && item.field_4 !== ""
          ? findings[18].find((x) => x.label === item.field_4)
          : ""
      }
      options={findings[18]}
      styles={styles}
      disabled={disabled}
      autoSize={true}
      onChange={(event) => {
        props.updateSentence(2, index, "field_4", event);
      }}
    />
  );
  const field5 = (
    <FormSelect
      value={
        item.field_5 !== null && item.field_5 !== ""
          ? findings["18_1"].find((x) => x.label === item.field_5)
          : ""
      }
      options={findings["18_1"]}
      styles={styles}
      disabled={disabled}
      autoSize={true}
      onChange={(event) => {
        props.updateSentence(2, index, "field_5", event);
      }}
    />
  );
  const field6 = (
    <FormSelect
      value={
        item.field_6 !== null && item.field_6 !== ""
          ? findings["18_2"].find((x) => x.label === item.field_6)
          : ""
      }
      options={findings["18_2"]}
      styles={styles}
      disabled={disabled}
      autoSize={true}
      onChange={(event) => {
        props.updateSentence(2, index, "field_6", event);
      }}
    />
  );
  const field7 = (
    <FormSelect
      value={
        item.field_7 !== null && item.field_7 !== ""
          ? findings[19].find((x) => x.label === item.field_7)
          : ""
      }
      options={findings[19]}
      styles={styles}
      disabled={disabled}
      autoSize={true}
      onChange={(event) => {
        props.updateSentence(2, index, "field_7", event);
      }}
    />
  );

  return (
    <div>
      <div>
        There is also a {field1} size perfusion abnormality of {field2}{" "}
        intensity in the{" "}
        <span
          className={disabled ? "" : "btn-link pointer"}
          onClick={disabled ? null : props.toggleModal}
        >
          {item.field_3 !== "" && item.field_3 !== null
            ? item.field_3
            : "Select Area"}
        </span>{" "}
        wall segment(s) that is {field4}{" "}
        {item.field_4 !== null && item.field_4 === "reversible" && (
          <React.Fragment>{field5}</React.Fragment>
        )}
        {item.field_4 !== null && item.field_4 === "fixed" && (
          <React.Fragment>
            {field6}
            {item.field_6 !== null && item.field_6 === "with" && (
              <React.Fragment>
                a {field7} amount of peri-infarct ischemia
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        .
        {!disabled && (
          <React.Fragment>
            <Button
              color={"primary"}
              size={"sm"}
              className={"ml-2"}
              onClick={() => props.addSentence(props.type)}
            >
              Repeat
            </Button>
            <Button
              color={"danger"}
              size={"sm"}
              className={"ml-2"}
              onClick={() => props.onDelete(props.type, index)}
            >
              Delete
            </Button>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const Type3 = (props) => {
  const { item, index, findings, styles, disabled } = props;

  const isFreeType =
    item.field_2 !== null &&
    ((item.field_2.label !== null && item.field_2.label === "[free type]") ||
      item.field_2 === "[free type]");

  return (
    <div style={disabled ? { color: "#b7b7b7" } : {}}>
      Gated SPECT imaging was performed and demonstrates
      <FormSelect
        value={
          item.field_1 !== null
            ? isNaN(item.field_1)
              ? findings[26].find((x) => x.label === item.field_1)
              : parseInt(item.field_1)
            : ""
        }
        options={findings[26]}
        styles={styles}
        disabled={disabled}
        autoSize={true}
        onChange={(event) => {
          props.updateSentence(3, index, "field_1", event);
        }}
      />{" "}
      of the{" "}
      <FormSelect
        value={
          item.field_2 !== null
            ? isNaN(item.field_2)
              ? findings[27].find((x) => x.label === item.field_2)
              : parseInt(item.field_1)
            : ""
        }
        options={findings[27]}
        disabled={disabled || isFreeType}
        styles={styles}
        autoSize={true}
        onChange={(event) => {
          props.updateSentence(3, index, "field_2", event);
        }}
      />{" "}
      {isFreeType && (
        <div className={"d-inline-block"}>
          <div className={"d-flex align-items-center"}>
            <LabelInput
              type={"text"}
              size={"sm"}
              disabled={disabled}
              className={"form-control form-control-sm"}
              formGroupClass={"mb-0"}
              value={item.field_2_free_type}
              onChange={(event) =>
                props.updateSentence(3, index, "field_2_free_type", event)
              }
            />
            <FontAwesomeIcon
              className={"mr-2 ml-2 pointer"}
              icon={["fas", "times"]}
              onClick={(event) => {
                props.updateSentence(3, index, "field_2", null);
              }}
            />
          </div>
        </div>
      )}{" "}
      wall(s).
      <Button
        color={"primary"}
        size={"sm"}
        className={"ml-2"}
        onClick={() => props.addSentence(props.type)}
      >
        Repeat
      </Button>
      <Button
        color={"danger"}
        size={"sm"}
        className={"ml-2"}
        onClick={() => props.onDelete(props.type, index)}
      >
        Delete
      </Button>
    </div>
  );
};

const Type4 = (props) => {
  const { item, index, findings, styles, disabled } = props;

  const isFreeType =
    item.field_2 !== null &&
    ((item.field_2.label !== null && item.field_2.label === "[free type]") ||
      item.field_2 === "[free type]");

  return (
    <div>
      Abnormal extracardiac uptake
      {item.field_1 !== null &&
      ((item.field_1.label !== null && item.field_1.label === "is not g") ||
        item.field_1 === "is not") ? (
        <React.Fragment>
          <FormSelect
            value={
              item.field_1 !== null
                ? isNaN(item.field_1)
                  ? findings[6].find((x) => x.label === item.field_1)
                  : parseInt(item.field_1)
                : ""
            }
            options={findings[6]}
            styles={styles}
            disabled={disabled}
            autoSize={true}
            onChange={(event) => {
              props.updateSentence(4, index, "field_1", event);
            }}
          />{" "}
          noted.
        </React.Fragment>
      ) : (
        <React.Fragment>
          <FormSelect
            value={
              item.field_1 !== null
                ? isNaN(item.field_1)
                  ? findings[6].find((x) => x.label === item.field_1)
                  : parseInt(item.field_1)
                : ""
            }
            options={findings[6]}
            styles={styles}
            disabled={disabled}
            autoSize={true}
            onChange={(event) => {
              props.updateSentence(4, index, "field_1", event);
            }}
          />{" "}
          noted in the{" "}
          <FormSelect
            value={
              item.field_2 !== null
                ? isNaN(item.field_2)
                  ? findings[7].find((x) => x.label === item.field_2)
                  : parseInt(item.field_2)
                : ""
            }
            options={findings[7]}
            styles={styles}
            disabled={disabled || isFreeType}
            autoSize={true}
            onChange={(event) => {
              props.updateSentence(4, index, "field_2", event);
            }}
          />
          {isFreeType && (
            <div className={"d-inline-block"}>
              <div className={"d-flex align-items-center"}>
                <LabelInput
                  type={"text"}
                  size={"sm"}
                  className={"form-control form-control-sm"}
                  formGroupClass={"mb-0"}
                  value={item.field_2_free_type}
                  onChange={(event) =>
                    props.updateSentence(4, index, "field_2_free_type", event)
                  }
                />
                <FontAwesomeIcon
                  className={"mr-2 ml-2 pointer"}
                  icon={["fas", "times"]}
                  onClick={(event) => {
                    props.updateSentence(4, index, "field_2", null);
                  }}
                />
              </div>
            </div>
          )}
          .
        </React.Fragment>
      )}{" "}
      <Button
        color={"primary"}
        size={"sm"}
        className={"ml-2"}
        onClick={() => props.addSentence(props.type)}
      >
        Repeat
      </Button>
      <Button
        color={"danger"}
        size={"sm"}
        className={"ml-2"}
        onClick={() => props.onDelete(props.type, index)}
      >
        Delete
      </Button>
    </div>
  );
};

class Sentences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areaChartSentenceActive: false,
      index: null,
      activeChartElement: null,
    };
  }

  updateSentencePieValue = (activeItems) => {
    const areaLabels = [];
    let findings = [];
    let key = 0;
    for (const datasetIndex in activeItems) {
      for (let index = 0; index < activeItems[datasetIndex].length; index++) {
        key = activeItems[datasetIndex][index];
        if (datasetIndex === "0") {
          findings = this.props.findings["17"].slice(0, 6).reverse();
        } else if (datasetIndex === "1") {
          findings = this.props.findings["17"].slice(6, 12).reverse();
        } else if (datasetIndex === "2") {
          findings = this.props.findings["17"].slice(12, 16).reverse();
        } else {
          findings = this.props.findings["17"].slice(16, 17).reverse();
        }
        areaLabels.push(findings[key].label);
      }
    }
    let pieValue = "";
    if (areaLabels.length === 1) {
      pieValue = areaLabels.pop();
    } else if (areaLabels.length > 1) {
      const last = areaLabels.pop();
      pieValue = areaLabels.join(", ") + " and " + last;
    }
    this.props.updateSentence(2, this.state.index, "field_3", pieValue);
    this.setState({
      areaChartSentenceActive: false,
      index: null,
      activeChartElement: null,
    });
  };

  render = () => {
    return (
      <React.Fragment>
        {this.props.entities.map((item, key) => {
          let line = null;
          switch (this.props.type) {
            case 1:
              line = (
                <Type1
                  key={"1-" + key}
                  {...this.props}
                  item={item}
                  index={key}
                />
              );
              break;
            case 2:
              line = (
                <Type2
                  key={"2-" + key}
                  {...this.props}
                  item={item}
                  index={key}
                  toggleModal={() =>
                    this.setState({
                      index: key,
                      areaChartSentenceActive: true,
                      activeChartElement: item.field_3,
                    })
                  }
                />
              );
              break;
            case 3:
              line = (
                <Type3
                  key={"3-" + key}
                  {...this.props}
                  item={item}
                  index={key}
                />
              );
              break;
            case 4:
              line = (
                <Type4
                  key={"4-" + key}
                  {...this.props}
                  item={item}
                  index={key}
                />
              );
              break;
            default:
              line = null;
              break;
          }
          return line;
        })}

        <AreaChart
          isActive={this.state.areaChartSentenceActive}
          toggle={() =>
            this.setState({
              areaChartSentenceActive: false,
            })
          }
          findings={this.props.findings[17]}
          confirmLabel={"Submit"}
          activeChartElement={this.state.activeChartElement}
          confirmAction={(activeItems) =>
            this.updateSentencePieValue(activeItems)
          }
        />
      </React.Fragment>
    );
  };
}

export default Sentences;
