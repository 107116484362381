import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownMenu,
  DropdownToggle,
  Row,
  Tooltip,
  UncontrolledDropdown,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router";

import EditReportModal from "components/Elements/Modals/EditReport";
import StatusBadge from "components/Elements/Reports/StatusBadge";
import NextStageButton from "components/Elements/Reports/NextStageButton";
import axiosInstance from "axios-instance";

// Cards
import BasicInfo from "./BasicInfo/BasicInfo";
import Measurements from "./Measurements/Measurements";
import Findings from "./Findings/Findings";
import Summaries from "./Summaries/Summaries";
import Activities from "components/Elements/Reports/Activities";

// Status Modals
import ReportStatusModal from "components/Elements/Modals/ReportStatus";
import RetrieveModal from "components/Elements/Modals/Confirm";
import DeleteModal from "components/Elements/Modals/Confirm";
import AmendModal from "components/Elements/Modals/Confirm";
import NormalFindingsConfirmModal from "components/Elements/Modals/Confirm";
//import { Link } from "react-router-dom";

class EchoView extends Component {
  constructor(props) {
    super(props);

    const authData = JSON.parse(localStorage.getItem("auth"));

    this.state = {
      entity: {},
      measurements: {},
      showBasicInfo: true,
      showMeasurements: true,
      showFindings: true,
      showSummary: true,
      showActivities: false,
      editModalActive: false,
      statusModalActive: false,
      showRetriveMeasurementsModal: false,
      showDeleteModal: false,
      showAmendModal: false,
      measurementLoader: false,
      expandFindings: false,
      showNormalFindingsModal: false,
      allowAmend:
        authData.user.role === "Physician" || authData.user.role === "Manager",
    };
  }

  toggle = (modalType) => {
    this.setState((prevState) => ({
      [modalType]: !prevState[modalType],
    }));
  };

  retrieveMeasurements = () => {
    const { entity } = this.props;
    axiosInstance()
      .get(`/reports/retrieve-measurements/${entity.id}.json`)
      .then((response) => {
        this.setState(
          {
            showRetriveMeasurementsModal: false,
            measurementLoader: false,
            showMeasurements: true,
          },
          () => {
            this.props.getEntity(response.data);
          }
        );
      })
      .catch((err) => {
        alert("No match found. Please try again later.");
        this.setState({
          showRetriveMeasurementsModal: false,
          measurementLoader: false,
          showMeasurements: true,
        });
      });
  };

  deleteAction = () => {
    const { entity } = this.props;
    axiosInstance()
      .delete(`/reports/${entity.id}.json`)
      .then((response) => {
        this.props.history.push(`/`);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  amendAction = () => {
    this.props.amendAction(() => {
      this.setState({
        showAmendModal: false,
      });
    });
  };

  render = () => {
    const { entity, findings } = this.props;
    const {
      showBasicInfo,
      showMeasurements,
      showFindings,
      showSummary,
    } = this.state;

    const isLocked = entity.status === 2;

    return (
      <div>
        <div className={"d-flex align-items-center pt-3"}>
          <h3 className={"mr-2 mb-0"}>Report-{entity.id}</h3>
          <div className={"flex-grow-1"}>
            <StatusBadge status={entity.status} style={{ fontSize: "13px" }} />
          </div>
          <div>
            <UncontrolledDropdown className={"mr-3"}>
              <DropdownToggle caret color={"outline-primary"} className={"btn"}>
                More Options
              </DropdownToggle>
              <DropdownMenu right>
                {entity.status === 2 && this.state.allowAmend && (
                  <span
                    onClick={() => this.toggle("showAmendModal")}
                    className={"dropdown-item d-block pointer"}
                  >
                    Amend Report
                  </span>
                )}
                {entity.status < 2 && entity.status !== -1 && (
                  <span
                    onClick={() => this.toggle("showDeleteModal")}
                    className={"dropdown-item d-block pointer"}
                  >
                    Delete
                  </span>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <div>
            <a
              href={
                axiosInstance().defaults.baseURL +
                "reports/preview/" +
                entity.id
              }
              className={"btn btn-outline-primary mr-2"}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Preview
            </a>
          </div>
          <div>
            <NextStageButton
              entity={entity}
              onClick={() => this.toggle("statusModalActive")}
            />
          </div>
        </div>
        <Row className={"pt-3"}>
          <Col>
            <Card>
              <CardHeader className={"d-flex p-1 align-items-center"}>
                <div
                  className={"pointer py-2 px-3 flex-grow-1"}
                  onClick={() => {
                    this.setState((prevState) => ({
                      showBasicInfo: !prevState.showBasicInfo,
                    }));
                  }}
                >
                  Basic Info
                  {isLocked && (
                    <FontAwesomeIcon
                      className={"ml-2"}
                      icon={["fas", "lock"]}
                    />
                  )}
                  {showBasicInfo ? (
                    <FontAwesomeIcon
                      className={"ml-3"}
                      icon={["fas", "chevron-up"]}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className={"ml-3"}
                      icon={["fas", "chevron-down"]}
                    />
                  )}
                </div>
                {!isLocked && (
                  <Button
                    color={"primary"}
                    size={"sm px-4 mr-2"}
                    onClick={() => this.toggle("editModalActive")}
                  >
                    Edit
                  </Button>
                )}
              </CardHeader>
              {showBasicInfo && (
                <CardBody>
                  <BasicInfo entity={entity} />
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        <Row className={"pt-3"}>
          <Col>
            <Card>
              <CardHeader className={"d-flex p-1 align-items-center"}>
                <div
                  className={"pointer py-2 px-3 flex-grow-1"}
                  onClick={() => {
                    this.setState((prevState) => ({
                      showMeasurements: !prevState.showMeasurements,
                    }));
                  }}
                >
                  Measurements
                  {isLocked && (
                    <FontAwesomeIcon
                      className={"ml-2"}
                      icon={["fas", "lock"]}
                    />
                  )}
                  {showMeasurements ? (
                    <FontAwesomeIcon
                      className={"ml-3"}
                      icon={["fas", "chevron-up"]}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className={"ml-3"}
                      icon={["fas", "chevron-down"]}
                    />
                  )}
                </div>
                <FontAwesomeIcon
                  className={"ml-3 mr-3"}
                  icon={["fas", "exclamation-circle"]}
                  id={"tooltip-box"}
                />
                <Tooltip
                  placement="left"
                  isOpen={this.state.tooltipOpen}
                  target="tooltip-box"
                  toggle={() =>
                    this.setState((prevState) => ({
                      tooltipOpen: !prevState.tooltipOpen,
                    }))
                  }
                >
                  <span className={"pb-2 h6"}>
                    Click at the numbers or “---“ to edit each measurement.
                  </span>
                </Tooltip>
                <Button
                  color={"primary"}
                  size={"sm"}
                  className={"mr-2"}
                  onClick={() => {
                    this.setState((prevState) => ({
                      showRetriveMeasurementsModal: !prevState.showRetriveMeasurementsModal,
                    }));
                  }}
                >
                  Retrieve Measurements Data
                </Button>
              </CardHeader>
              {showMeasurements && (
                <CardBody>
                  <Measurements entityId={this.props.id} isLocked={isLocked} />
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        <Row className={"pt-3"}>
          <Col>
            <Card>
              <CardHeader className={"d-flex"}>
                <div
                  className={"pointer flex-grow-1"}
                  onClick={() => {
                    this.setState((prevState) => ({
                      showFindings: !prevState.showFindings,
                    }));
                  }}
                >
                  Findings
                  {isLocked && (
                    <FontAwesomeIcon
                      className={"ml-2"}
                      icon={["fas", "lock"]}
                    />
                  )}
                  {showFindings ? (
                    <FontAwesomeIcon
                      className={"ml-3"}
                      icon={["fas", "chevron-up"]}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className={"ml-3"}
                      icon={["fas", "chevron-down"]}
                    />
                  )}
                </div>

                <div className="custom-control custom-switch mr-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="expand-is-normal"
                    checked={entity.is_normal_findings === 1}
                    onChange={
                      isLocked
                        ? null
                        : () => this.toggle("showNormalFindingsModal")
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="expand-is-normal"
                  >
                    Add Normal Findings
                  </label>
                </div>
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="expand-findings"
                    checked={this.state.expandFindings}
                    onChange={
                      isLocked
                        ? null
                        : (event) => {
                            this.setState({
                              expandFindings: event.currentTarget.checked,
                            });
                          }
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="expand-findings"
                  >
                    Expand All
                  </label>
                </div>
              </CardHeader>
              {showFindings && !this.state.showNormalFindingsModal && (
                <CardBody>
                  <Findings
                    expandAll={this.state.expandFindings}
                    isLocked={isLocked}
                    findings={findings}
                    entity={entity}
                    entityId={this.props.id}
                    saveSummaryItem={(body) => this.props.saveSummaryItem(body)}
                    toggleSummaryItem={(body) =>
                      this.props.toggleSummaryItem(body)
                    }
                    freeTypes={() => {
                      const freeTypes = {};
                      entity.finding_items.map((item) => {
                        freeTypes[item.id] = item._joinData.body;
                        return null;
                      });
                      return freeTypes;
                    }}
                    reportFreetypeOptions={entity.finding_items
                      .filter((item) => {
                        return item._joinData.is_summary === 1;
                      })
                      .map((item) => item.id)}
                    selectedOptions={entity.finding_statements.map(
                      (statement) => statement.id
                    )}
                    reportOptions={entity.finding_statements
                      .filter((statement) => {
                        return statement._joinData.is_summary === 1;
                      })
                      .map((statement) => statement.id)}
                  />
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        <Row className={"pt-3"}>
          <Col>
            <Card>
              <CardHeader>
                <div
                  className={"pointer"}
                  onClick={() => {
                    this.setState((prevState) => ({
                      showSummary: !prevState.showSummary,
                    }));
                  }}
                >
                  Summary
                  {isLocked && (
                    <FontAwesomeIcon
                      className={"ml-2"}
                      icon={["fas", "lock"]}
                    />
                  )}
                  {showSummary ? (
                    <FontAwesomeIcon
                      className={"ml-3"}
                      icon={["fas", "chevron-up"]}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className={"ml-3"}
                      icon={["fas", "chevron-down"]}
                    />
                  )}
                </div>
              </CardHeader>
              {showSummary && (
                <CardBody>
                  <Summaries
                    isLocked={isLocked}
                    saveSummaryItem={(body, callback) =>
                      this.props.saveSummaryItem(body, callback)
                    }
                    saveIsNormalEcho={(isNormalEcho) =>
                      this.props.saveIsNormalEcho(isNormalEcho)
                    }
                    isNormalEcho={entity.is_normal_echo}
                    deleteSummaryItem={(itemId, callback) =>
                      this.props.deleteSummaryItem(itemId, callback)
                    }
                    entities={entity.report_summaries}
                    saveOrder={(entities) =>
                      this.props.saveSummaryOrder(entities)
                    }
                  />
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        <div className={"d-flex pt-3"}>
          <div className={"flex-grow-1"}>&nbsp;</div>
          <div>
            <a
              href={
                axiosInstance().defaults.baseURL +
                "reports/preview/" +
                entity.id
              }
              className={"btn btn-outline-primary mr-2"}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Preview
            </a>
          </div>
          <div>
            <NextStageButton
              entity={entity}
              onClick={() => this.toggle("statusModalActive")}
            />
          </div>
        </div>
        <Row className={"pt-3 pb-5"}>
          <Col>
            <Activities
              show={this.state.showActivities}
              toggle={() => {
                this.setState((prevState) => ({
                  showActivities: !prevState.showActivities,
                }));
              }}
              entities={entity.report_activities}
            />
          </Col>
        </Row>
        {this.state.editModalActive === true && (
          <EditReportModal
            isActive={this.state.editModalActive}
            toggle={() => this.toggle("editModalActive")}
            entity={entity}
            getEntity={this.props.getEntity}
          />
        )}
        {this.state.statusModalActive && (
          <ReportStatusModal
            isActive={this.state.statusModalActive}
            toggle={() => this.toggle("statusModalActive")}
            entity={entity}
            getEntity={this.props.getEntity}
          />
        )}

        {this.state.showNormalFindingsModal && (
          <NormalFindingsConfirmModal
            isActive={this.state.showNormalFindingsModal}
            toggle={() => this.toggle("showNormalFindingsModal")}
            title={"Confirmation"}
            body={
              entity.is_normal_findings === 0
                ? "Please confirm to add all normal findings for this report."
                : "Please confirm to unselect all normal findings for this report."
            }
            confirmAction={() =>
              this.props.saveIsNormalFindings(
                entity.is_normal_findings === 1 ? 0 : 1,
                () => this.toggle("showNormalFindingsModal")
              )
            }
            confirmLabel={
              entity.is_normal_findings === 0
                ? "Yes, add all normal findings"
                : "Yes, unselect all normal findings"
            }
            confirmClass={
              entity.is_normal_findings === 0 ? "success" : "danger"
            }
          />
        )}

        {this.state.showDeleteModal && (
          <DeleteModal
            isActive={this.state.showDeleteModal}
            toggle={
              this.state.showDeleteModal !== true
                ? null
                : () => this.toggle("showDeleteModal")
            }
            title={"Confirmation"}
            body={
              "Please confirm to delete they report. All report data will be lost and this action cannot be reversed."
            }
            confirmAction={this.deleteAction}
            confirmLabel={"Yes, delete this report"}
            confirmClass={"danger"}
          />
        )}

        {this.state.showAmendModal && (
          <AmendModal
            isActive={this.state.showAmendModal}
            toggle={
              this.state.showAmendModal !== true
                ? null
                : () => this.toggle("showAmendModal")
            }
            title={"Confirm"}
            body={
              "Please confirm to amend this report. This page will be unlocked. The report will need to be" +
              " re-finalized."
            }
            confirmAction={this.amendAction}
            confirmLabel={"Yes, I’d like to amend this report"}
            confirmClass={"danger"}
          />
        )}

        {this.state.showRetriveMeasurementsModal && (
          <RetrieveModal
            isActive={this.state.showRetriveMeasurementsModal}
            toggle={
              this.state.measurementLoader === true
                ? null
                : () => this.toggle("showRetriveMeasurementsModal")
            }
            title={"Confirmation"}
            body={
              "Please confirm to send the retrieval request for this report’s measurements data."
            }
            confirmAction={() => {
              this.setState(
                {
                  measurementLoader: true,
                  showMeasurements: false,
                },
                () => {
                  this.retrieveMeasurements();
                }
              );
            }}
            confirmLabel={"Confirm"}
            confirmClass={"primary"}
            submitting={this.state.measurementLoader}
          />
        )}
      </div>
    );
  };
}
export default withRouter(EchoView);
