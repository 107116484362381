import React from "react";

export default (props) => {
  return (
    <React.Fragment>
      {props.name !== undefined && (
        <label className={"font-weight-bold"}>
          {props.name === "&nbsp;" ? <span>&nbsp;</span> : props.name}
          {props.required !== undefined && props.required && " *"}
        </label>
      )}
      <div
        className={
          "input-group " +
          (props.inputgroupclass !== undefined ? props.inputgroupclass : "")
        }
      >
        {props.prepend !== undefined && (
          <div className="input-group-prepend">
            <span className="input-group-text">{props.prepend}</span>
          </div>
        )}
        <input
          className={
            "form-control " +
            (props.visited && props.error !== false ? "is-invalid" : "")
          }
          {...props}
          visited={null}
          error={null}
          placeholder={
            props.required === true
              ? props.placeholder !== undefined
                ? props.placeholder
                : ""
              : "Optional"
          }
        />
        {props.append !== undefined && (
          <div className="input-group-append">
            <span
              className={
                props.appendClass !== undefined
                  ? props.appendClass
                  : "input-group-text"
              }
            >
              {props.append}
            </span>
          </div>
        )}
        {props.visited && <span className={"error"}>{props.error}</span>}
      </div>
    </React.Fragment>
  );
};
