import React from "react";
import { displayValue } from "functions";

export default (props) => {
  return (
    <React.Fragment>
      {/* props.nuclear_protocol !== null && (
        <div className={"pb-2"}>
          <div className={"font-weight-bold"}>Protocol</div>
          <div>{props.nuclear_protocol.name}</div>
        </div>
      ) */}
      <div className={"pb-2"}>
        <div className={"font-weight-bold"}>Administration</div>
        <div>
          {props.is_custom_administration === 1 ? (
            <React.Fragment>
              {displayValue(props.custom_administration_location)}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {displayValue(props.administration_location)}
            </React.Fragment>
          )}{" "}
          by {displayValue(props.initials)}
        </div>
      </div>
      <div className={"pb-2"}>
        <div className={"font-weight-bold"}>Rest Dose</div>
        <div>
          {displayValue(props.rest_dose_amount)} mCi of{" "}
          {displayValue(props.rest_dose_type)}
        </div>
      </div>
      <div className={"pb-2"}>
        <div className={"font-weight-bold"}>Stress Dose</div>
        <div>
          {displayValue(props.stress_dose_amount)} mCi of{" "}
          {displayValue(props.stress_dose_type)}
        </div>
      </div>
      <div className={"pb-2"}>
        <div className={"font-weight-bold"}>Limits Exceeded</div>
        <div>{props.limit_exceeded === 1 ? "did" : "did not"}</div>
      </div>
      {props.reportEntity.report_type.slug === "exercise" && (
        <React.Fragment>
          <div className={"pb-2"}>
            <div className={"font-weight-bold"}>Stress Test</div>
            <div>
              {displayValue(props.stress_test_protocol)} protocol for{" "}
              {displayValue(props.stress_test_length)} minutes and achieved a
              maximum heart rate of {displayValue(props.stress_test_heart_rate)}{" "}
              bpm which was {displayValue(props.stress_test_percentage)} %
            </div>
          </div>
          <div className={"pb-2"}>
            <div className={"font-weight-bold"}>Rest ECG</div>
            <div>
              {displayValue(props.rest_ecg1)} and{" "}
              <strong>{displayValue(props.rest_ecg2)}</strong>
            </div>
          </div>
          <div className={"pb-2"}>
            <div className={"font-weight-bold"}>Stress Response</div>
            <div>
              {displayValue(props.exercise_response)} and the patient
              experienced {displayValue(props.exercise_response_experience)}
            </div>
          </div>
          <div className={"pb-2"}>
            <div className={"font-weight-bold"}>Arrhythmias</div>
            <div>{displayValue(props.arrhythmia)}</div>
          </div>
        </React.Fragment>
      )}
      {props.reportEntity.report_type.slug === "regadenoson" && (
        <React.Fragment>
          <div className={"pb-2"}>
            <div className={"font-weight-bold"}>Responses</div>
            <div>
              The patient was noted to have {displayValue(props.rest_ecg2)}{" "}
              during Lexiscan.
            </div>
            <div>
              The patient was noted to have{" "}
              {displayValue(props.exercise_response_experience)} during
              Lexiscan.
            </div>
            <div>
              Two minutes into the Lexiscan infusion, the patient had{" "}
              {displayValue(props.lexiscan_infusion)} mCi of Sestamibi injected.
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
