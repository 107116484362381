import React, { Component } from "react";
import Style from "./Findings.module.scss";
import { Button } from "reactstrap";
import Toggle from "react-bootstrap-toggle";
import axiosInstance from "axios-instance";

class Findings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: true,
      activeEditId: null,
      activeFreeTypeId: null,
      toggleActive: false,
      selectedOptions: props.selectedOptions,
      reportOptions: props.reportOptions,
      reportFreetypeOptions: props.reportFreetypeOptions,
      freeTypes: props.freeTypes(),
    };

    this.saveInterval = null;

    this.onToggle = this.onToggle.bind(this);
  }

  onToggle() {
    this.setState({ toggleActive: !this.state.toggleActive });
  }

  toggleFreeType = (itemId) => {
    this.setState({
      activeFreeTypeId: itemId,
    });
  };

  toggleOption = (optionId) => {
    let selected = [...this.state.selectedOptions];
    let selectedReport = [...this.state.reportOptions];
    if (selected.includes(optionId)) {
      selected = selected.filter((item) => item !== optionId);
      selectedReport = selectedReport.filter((item) => item !== optionId);
    } else {
      selected.push(optionId);
    }

    this.setState(
      {
        selectedOptions: selected,
        reportOptions: selectedReport,
      },
      () => {
        this.onFinishEditing(false);
      }
    );
  };

  toggleReportOption = (optionId) => {
    let selectedReport = [...this.state.reportOptions];
    let selectedOption = [...this.state.selectedOptions];
    if (selectedReport.includes(optionId)) {
      selectedReport = selectedReport.filter((item) => item !== optionId);
    } else {
      selectedReport.push(optionId);
      selectedOption.push(optionId);
    }

    this.setState(
      {
        selectedOptions: selectedOption,
        reportOptions: selectedReport,
      },
      () => this.onFinishEditing(false)
    );
  };

  nl2br = (str, is_xhtml) => {
    if (typeof str === "undefined" || str === null) {
      return "";
    }
    var breakTag =
      is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
    return (str + "").replace(
      /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
      "$1" + breakTag + "$2"
    );
  };

  toggleReportFreetypeOptions = (optionId) => {
    let selectedReport = [...this.state.reportFreetypeOptions];
    if (selectedReport.includes(optionId)) {
      selectedReport = selectedReport.filter((item) => item !== optionId);
    } else {
      selectedReport.push(optionId);
    }

    this.setState(
      {
        reportFreetypeOptions: selectedReport,
      },
      () => this.onFinishEditing(false)
    );
  };

  onFinishEditing = (isFinishEditClick) => {
    window.clearInterval(this.saveInterval);

    this.saveInterval = window.setTimeout(
      () => {
        axiosInstance()
          .post(`/reports/save-findings/${this.props.entityId}.json`, {
            statement_id: this.state.selectedOptions,
            summary: this.state.reportOptions,
            free_types: this.state.freeTypes,
            free_type_summary: this.state.reportFreetypeOptions,
          })
          .then((response) => {
            if (isFinishEditClick) {
              this.setState({
                activeEditId: null,
              });
            }
          })
          .catch((err) => {
            alert(err);
          });
      },
      isFinishEditClick ? 100 : 1000
    );
  };

  updateFreeType = (event, itemId) => {
    const freeTypes = JSON.parse(JSON.stringify(this.state.freeTypes));
    freeTypes[itemId] = event.target.value;
    this.setState({
      freeTypes: freeTypes,
    });
  };

  render = () => {
    const { findings, isLocked, entity } = this.props;
    return (
      <React.Fragment>
        {findings.map((item) => {
          return (
            <React.Fragment key={item.id}>
              {this.state.activeEditId === item.id || this.props.expandAll ? (
                <div
                  id={"finding-item-" + item.id}
                  className={"py-1 px-2 mb-2 rounded " + Style.itemActive}
                >
                  <strong>{item.name}</strong>
                  {!this.props.expandAll && (
                    <Button
                      color={"success"}
                      size={"sm"}
                      className={"float-right py-0 px-3"}
                      onClick={() => this.onFinishEditing(true)}
                    >
                      Finish Editing
                    </Button>
                  )}
                  <div
                    className="card-columns"
                    style={{
                      columnCount: item.finding_items.length > 1 ? 2 : 1,
                    }}
                  >
                    {item.finding_items.map((item, key) => {
                      return (
                        <div
                          className={"card p-0 border-0"}
                          style={{ backgroundColor: "transparent" }}
                          key={"finding-" + item.id}
                        >
                          <div className={"pt-3"} key={"group-" + item.name}>
                            <div className={"h6 d-flex"}>
                              <strong className={"flex-grow-1"}>
                                {item.name}
                              </strong>
                              <div>
                                <strong>Summary</strong>
                              </div>
                            </div>
                            {item.finding_statements.map((statement) => {
                              return (
                                <div
                                  className={
                                    "d-flex py-1 px-2 mb-1 border rounded " +
                                    (!this.state.selectedOptions.includes(
                                      statement.id
                                    )
                                      ? Style.option
                                      : Style.optionActive)
                                  }
                                  key={"option-" + statement.id}
                                >
                                  <div
                                    className={"pointer flex-grow-1"}
                                    onClick={() =>
                                      this.toggleOption(statement.id)
                                    }
                                  >
                                    {statement.name}
                                  </div>
                                  <div
                                    className={
                                      this.state.selectedOptions.includes(
                                        statement.id
                                      )
                                        ? ""
                                        : "invisible"
                                    }
                                  >
                                    <Toggle
                                      onClick={() => {
                                        this.toggleReportOption(statement.id);
                                        if (
                                          !this.state.reportOptions.includes(
                                            statement.id
                                          )
                                        ) {
                                          this.props.saveSummaryItem(
                                            statement.name
                                          );
                                        } else {
                                          this.props.toggleSummaryItem(
                                            statement.name
                                          );
                                        }
                                      }}
                                      style={{
                                        float: "right",
                                        fontSize: "13px",
                                      }}
                                      height={25}
                                      width={null}
                                      on={"ADDED"}
                                      off={"ADD"}
                                      size="sm"
                                      offstyle="inactive"
                                      onstyle={"active"}
                                      active={this.state.reportOptions.includes(
                                        statement.id
                                      )}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                            <div
                              className={
                                "d-flex py-1 px-2 mb-1 border rounded " +
                                (this.state.freeTypes[item.id] !== undefined &&
                                this.state.freeTypes[item.id].length > 0
                                  ? Style.optionActive
                                  : Style.option)
                              }
                              key={"freetype-" + item.id}
                            >
                              {this.state.activeFreeTypeId !== item.id ? (
                                <React.Fragment>
                                  {this.state.freeTypes[item.id] ===
                                    undefined ||
                                  this.state.freeTypes[item.id].length < 1 ? (
                                    <div
                                      onClick={() =>
                                        this.toggleFreeType(item.id)
                                      }
                                      className={"pointer flex-grow-1"}
                                    >
                                      [Free Type]
                                    </div>
                                  ) : (
                                    <React.Fragment>
                                      <div
                                        className={"flex-grow-1 pr-2"}
                                        onClick={() =>
                                          this.toggleFreeType(item.id)
                                        }
                                        style={{ wordBreak: "break-all" }}
                                      >
                                        <span>[Free Type]</span>&nbsp;
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: this.state.freeTypes[
                                              item.id
                                            ].replace(/\n/g, "<br>"),
                                          }}
                                        ></span>
                                      </div>
                                      <div>
                                        <Toggle
                                          onClick={() => {
                                            this.toggleReportFreetypeOptions(
                                              item.id
                                            );
                                            if (
                                              !this.state.reportFreetypeOptions.includes(
                                                item.id
                                              )
                                            ) {
                                              this.props.saveSummaryItem(
                                                this.state.freeTypes[item.id]
                                              );
                                            } else {
                                              this.props.toggleSummaryItem(
                                                this.state.freeTypes[item.id]
                                              );
                                            }
                                          }}
                                          style={{
                                            float: "right",
                                            fontSize: "13px",
                                          }}
                                          height={25}
                                          width={null}
                                          on={"ADDED"}
                                          off={"ADD"}
                                          size="sm"
                                          offstyle="inactive"
                                          onstyle={"active"}
                                          active={this.state.reportFreetypeOptions.includes(
                                            item.id
                                          )}
                                        />
                                      </div>
                                    </React.Fragment>
                                  )}
                                </React.Fragment>
                              ) : (
                                <textarea
                                  autoFocus={true}
                                  onChange={(event) => {
                                    this.updateFreeType(event, item.id);
                                  }}
                                  onBlur={() => {
                                    this.toggleFreeType(null);
                                    this.onFinishEditing(false);
                                  }}
                                  onKeyPress={(e) => {
                                    const keyboardCode = e.keyCode
                                      ? e.keyCode
                                      : e.which;
                                    if (keyboardCode === 13 && !e.shiftKey) {
                                      //Enter keycode
                                      this.toggleFreeType(null);
                                      this.onFinishEditing(false);
                                    }
                                  }}
                                  className={"form-control"}
                                  value={this.state.freeTypes[item.id]}
                                ></textarea>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div
                  id={"finding-item-" + item.id}
                  className={
                    (!isLocked ? "pointer " : "") +
                    "py-1 px-2 mb-2 border rounded " +
                    Style.item
                  }
                  onClick={
                    isLocked
                      ? null
                      : () => {
                          this.setState(
                            {
                              activeEditId: item.id,
                            },
                            () => {
                              window.scrollTo(
                                0,
                                window.scrollY +
                                  document
                                    .querySelector("#finding-item-" + item.id)
                                    .getBoundingClientRect().top -
                                  50
                              );
                            }
                          );
                        }
                  }
                >
                  <strong>{item.name}</strong>
                  {!isLocked && (
                    <Button
                      color={"primary"}
                      size={"sm"}
                      className={"float-right py-0 px-3"}
                      onClick={() =>
                        this.setState({
                          activeEditId: item.id,
                        })
                      }
                    >
                      Edit
                    </Button>
                  )}
                  <div>
                    {item.finding_items.map((item) => {
                      return (
                        <React.Fragment key={"group-preview-" + item.id}>
                          {item.finding_statements.map((statement) => {
                            return (
                              <React.Fragment
                                key={"option-review-" + statement.id}
                              >
                                {this.state.selectedOptions.includes(
                                  statement.id
                                ) &&
                                  (entity.status === 2 ? (
                                    <span>
                                      {
                                        entity.finding_statements.find(
                                          (item) =>
                                            item._joinData
                                              .finding_statement_id ===
                                            statement.id
                                        )._joinData.final_name
                                      }
                                      &nbsp;
                                    </span>
                                  ) : (
                                    <span>{statement.name}&nbsp;</span>
                                  ))}
                              </React.Fragment>
                            );
                          })}
                          {this.state.freeTypes[item.id] !== undefined &&
                            this.state.freeTypes[item.id].length > 0 && (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: this.state.freeTypes[item.id].replace(
                                    /\n/g,
                                    "<br>"
                                  ),
                                }}
                              ></span>
                            )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };
}
export default Findings;
