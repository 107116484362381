import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default props => {
  return (
    <React.Fragment>
      {props.sort === props.field && props.direction === 'ASC' ? (
        <th
          className={'pointer'}
          onClick={() =>
            props.paginationFunc({
              phraze: props.phraze,
              limit: props.limit,
              page: props.page,
              sort: props.field,
              direction: 'DESC'
            })
          }
        >
          {props.label}&nbsp;&nbsp;
          <FontAwesomeIcon icon={['fas', 'chevron-up']} />
        </th>
      ) : (
        <th
          className={'pointer'}
          onClick={() =>
            props.paginationFunc({
              phraze: props.phraze,
              limit: props.limit,
              page: 1,
              sort: props.field,
              direction: 'ASC'
            })
          }
        >
          {props.label}&nbsp;&nbsp;
          <FontAwesomeIcon icon={['fas', 'chevron-down']} />
        </th>
      )}
    </React.Fragment>
  );
};
