import * as actionTypes from 'store/actions/actionTypes';

const initialState = {
  loading: false,
  error: null,
  success: null,
  token: null,
  id: null,
  email: null,
  first_name: null,
  last_name: null,
  status: null,
  designation: null,
  ecw_id: null,
  created: null,
  modified: null
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_CLEAR:
      return {
        ...state,
        error: null,
        success: null,
        loading: false
      };
    case actionTypes.AUTH_START:
      return {
        ...state,
        error: null,
        success: null,
        loading: false
      };
    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        error: null,
        success: null,
        loading: false,
        token: action.authData.token,
        ...action.authData.user
      };
    case actionTypes.AUTH_FAIL:
      return {
        ...state,
        error: action.error,
        success: null,
        loading: false
      };
    case actionTypes.AUTH_LOGOUT:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default reducer;
