import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { withRouter } from "react-router";

import EditReportModal from "components/Elements/Modals/EditReport";
import StatusBadge from "components/Elements/Reports/StatusBadge";
import NextStageButton from "components/Elements/Reports/NextStageButton";

// Cards
import BasicInfo from "./BasicInfo/BasicInfo";
import History from "./History/History";

// Forms
import HistoryForm from "./History/HistoryForm";

// Status Modals
import ReportStatusModal from "components/Elements/Modals/ReportStatus";
import DeleteModal from "components/Elements/Modals/Confirm";
import AmendModal from "components/Elements/Modals/Confirm";

import Activities from "components/Elements/Reports/Activities";
import ReportCardHeader from "components/Elements/Reports/CardHeader";

import axiosInstance from "axios-instance";

class VascularView extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.historyChild = React.createRef();

    const authData = JSON.parse(localStorage.getItem("auth"));
    this.state = {
      measurements: {},
      showBasicInfo: true,
      showProcedure: true,
      showHistory: true,
      showProcedureForm: false,
      showHistoryForm: false,
      editModalActive: false,
      statusModalActive: false,

      allowAmend:
        authData.user.role === "Physician" || authData.user.role === "Manager",
    };
  }

  toggle = (modalType) => {
    this.setState((prevState) => ({
      [modalType]: !prevState[modalType],
    }));
  };

  deleteAction = () => {
    const { entity } = this.props;
    axiosInstance()
      .delete(`/reports/${entity.id}.json`)
      .then((response) => {
        this.props.history.push(`/`);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  amendAction = () => {
    this.props.amendAction(() => {
      this.setState({
        showAmendModal: false,
      });
    });
  };

  saveHistory = () => {
    this.historyChild.onSubmit((data) => {
      this.props.getEntity(data);
      this.toggle("showHistoryForm");
    });
  };

  render = () => {
    const { entity } = this.props;
    const { showBasicInfo, showProcedure, showHistory } = this.state;

    const isLocked = entity.status === 2;
    return (
      <div className={"pb-5"}>
        <div className={"d-flex align-items-center pt-3"}>
          <h3 className={"mr-2 mb-0"}>Report-{entity.id}</h3>
          <div className={"flex-grow-1"}>
            <StatusBadge status={entity.status} style={{ fontSize: "13px" }} />
          </div>
          <div>
            <UncontrolledDropdown className={"mr-3"}>
              <DropdownToggle caret color={"outline-primary"} className={"btn"}>
                More Options
              </DropdownToggle>
              <DropdownMenu right>
                {entity.status === 2 && this.state.allowAmend && (
                  <span
                    onClick={() => this.toggle("showAmendModal")}
                    className={"dropdown-item d-block pointer"}
                  >
                    Amend Report
                  </span>
                )}
                {entity.status < 2 && entity.status !== -1 && (
                  <span
                    onClick={() => this.toggle("showDeleteModal")}
                    className={"dropdown-item d-block pointer"}
                  >
                    Delete
                  </span>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <div>
            <a
              href={
                axiosInstance().defaults.baseURL +
                "reports/preview/" +
                entity.id
              }
              className={"btn btn-outline-primary mr-2"}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Preview
            </a>
          </div>
          <div>
            <NextStageButton
              entity={entity}
              onClick={() => this.toggle("statusModalActive")}
            />
          </div>
        </div>

        <Row className={"pt-3"}>
          <Col>
            <Card>
              <ReportCardHeader
                title={"Basic Info"}
                isLocked={isLocked}
                showContent={showBasicInfo}
                onEditClick={() => this.toggle("editModalActive")}
                onHeaderClick={() => this.toggle("showBasicInfo")}
              />
              {showBasicInfo && (
                <CardBody>
                  <BasicInfo
                    entity={entity}
                    isLocked={isLocked}
                    technicianLabel={"Nuclear Technician"}
                  />
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        {/* Procedure */}
        <Row className={"pt-3"}>
          <Col>
            <Card>
              <ReportCardHeader
                title={"Procedure"}
                isLocked={isLocked}
                showContent={showProcedure}
                showEdit={this.state.showProcedureForm}
                onEditClick={() => this.toggle("showProcedureForm")}
                onHeaderClick={() => this.toggle("showProcedure")}
              />
              {showProcedure && <div className={"text-center"}>---</div>}
            </Card>
          </Col>
        </Row>

        {/* History */}
        <Card className={"mt-3"}>
          <ReportCardHeader
            title={"History"}
            isLocked={isLocked}
            showContent={showHistory}
            showEdit={this.state.showHistoryForm}
            onEditClick={() => this.toggle("showHistoryForm")}
            onSaveClick={this.saveHistory}
            onHeaderClick={() => this.toggle("showHistory")}
          />
          {showHistory && (
            <CardBody>
              <React.Fragment>
                {!this.state.showHistoryForm && (
                  <History entity={entity.report_history} />
                )}
                {this.state.showHistoryForm && (
                  <HistoryForm
                    reportId={entity.id}
                    entity={entity.report_history}
                    childRef={(ref) => (this.historyChild = ref)}
                  />
                )}
              </React.Fragment>
            </CardBody>
          )}
        </Card>

        {/* Measurements */}
        <Card className={"mt-3"}>
          <ReportCardHeader
            title={"Measurements"}
            isLocked={true}
            showContent={true}
          />
          <CardBody>---</CardBody>
        </Card>

        {/* Impressions */}
        <Card className={"mt-3"}>
          <ReportCardHeader
            title={"Impressions"}
            isLocked={true}
            showContent={true}
          />
          <CardBody>---</CardBody>
        </Card>

        {/* Activities */}
        <Row className={"pt-3 pb-5"}>
          <Col>
            <Activities
              show={this.state.showActivities}
              toggle={() => {
                this.setState((prevState) => ({
                  showActivities: !prevState.showActivities,
                }));
              }}
              entities={entity.report_activities}
            />
          </Col>
        </Row>

        {this.state.editModalActive === true && (
          <EditReportModal
            isActive={this.state.editModalActive}
            toggle={() => this.toggle("editModalActive")}
            technicianLabel={"Nuclear Technician"}
            entity={entity}
            getEntity={this.props.getEntity}
          />
        )}
        {this.state.statusModalActive && (
          <ReportStatusModal
            isActive={this.state.statusModalActive}
            toggle={() => this.toggle("statusModalActive")}
            entity={this.props.entity}
            getEntity={this.props.getEntity}
          />
        )}

        {this.state.showDeleteModal && (
          <DeleteModal
            isActive={this.state.showDeleteModal}
            toggle={
              this.state.showDeleteModal !== true
                ? null
                : () => this.toggle("showDeleteModal")
            }
            title={"Confirmation"}
            body={
              "Please confirm to delete they report. All report data will be lost and this action cannot be reversed."
            }
            confirmAction={this.deleteAction}
            confirmLabel={"Yes, delete this report"}
            confirmClass={"danger"}
          />
        )}

        {this.state.showAmendModal && (
          <AmendModal
            isActive={this.state.showAmendModal}
            toggle={
              this.state.showAmendModal !== true
                ? null
                : () => this.toggle("showAmendModal")
            }
            title={"Confirm"}
            body={
              "Please confirm to amend this report. This page will be unlocked. The report will need to be" +
              " re-finalized."
            }
            confirmAction={this.amendAction}
            confirmLabel={"Yes, I’d like to amend this report"}
            confirmClass={"danger"}
          />
        )}
      </div>
    );
  };
}
export default withRouter(VascularView);
