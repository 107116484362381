import React, { Component } from "react";
import { withRouter } from "react-router";

import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { required } from "validation";
import axiosInstance from "axios-instance";
import Select from "react-select";

const ReadyForReview = (props) => {
  return (
    <React.Fragment>
      <ModalHeader toggle={props.toggle}>
        {props.entity.physician_id !== undefined
          ? "Confirmation"
          : "Assign reading Physician"}
      </ModalHeader>
      <div className={"position-relative"}>
        <ModalBody className={"p-4"}>
          <Row>
            {props.entity.physician_id !== undefined &&
            props.entity.physician_id !== null ? (
              <div>
                <p className={"pl-3"}>
                  Please confirm to mark this report as Ready for Review.
                </p>
              </div>
            ) : (
              <Col xs={12}>
                <p>
                  Please assign a reading physician for this report before
                  marking it as Ready for Review.
                </p>
                <div className={"form-group"}>
                  <label className={"font-weight-bold"}>
                    Reading Physician
                  </label>
                  <Select
                    value={props.fieldProviderId.value}
                    options={props.listProviders}
                    isClearable={true}
                    menuPlacement={"bottom"}
                    onChange={(event) => {
                      props.updateForm(event, "physician_id");
                    }}
                  />
                </div>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter className={"py-4"}>
          <Button
            color="light"
            className={"btn px-3"}
            type={"button"}
            onClick={props.toggle}
          >
            Cancel
          </Button>
          {props.submitting === false ? (
            <Button color="primary" className={"btn px-3"} type={"submit"}>
              Mark as Ready For Review
            </Button>
          ) : (
            <button className="btn btn-primary px-5" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              &nbsp;&nbsp; Loading...
            </button>
          )}
        </ModalFooter>
      </div>
    </React.Fragment>
  );
};

const ReadyForFinalize = (props) => {
  return (
    <React.Fragment>
      <ModalHeader toggle={props.toggle}>{"Confirmation"}</ModalHeader>
      <div className={"position-relative"}>
        <ModalBody className={"p-4"}>
          <div>
            <p className={"pl-3"}>
              Please confirm to finalize this report. This action cannot be
              undone.
            </p>
          </div>
        </ModalBody>
        <ModalFooter className={"py-4"}>
          <Button
            color="light"
            className={"btn px-3"}
            type={"button"}
            onClick={props.toggle}
          >
            Cancel
          </Button>
          {props.submitting === false ? (
            <Button color="primary" className={"btn px-3"} type={"submit"}>
              Yes, finalize this report
            </Button>
          ) : (
            <button className="btn btn-primary px-5" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              &nbsp;&nbsp; Loading...
            </button>
          )}
        </ModalFooter>
      </div>
    </React.Fragment>
  );
};

const validator = {
  physician_id: [required],
};
class ReportStatus extends Component {
  constructor(props) {
    super(props);

    const fields = {};
    Object.keys(validator).forEach((key) => {
      fields[key] = {
        value:
          props.entity[key] !== undefined && props.entity[key] !== null
            ? props.entity[key]
            : "",
        visited: false,
        error: false,
      };
    });
    this.state = {
      ...fields,
      loaded: false,
      activePhysicianId: null,
      errorMessage: false,
      validator: validator,
      submitting: false,
    };
    this.loadOptions();
  }

  loadOptions = () => {
    axiosInstance()
      .get(`/reports/load-options/${this.props.entity.id}.json`)
      .then((response) => {
        const fields = {};
        if (this.state.physician_id.value !== "") {
          fields["physician_id"] = {
            value: {
              value: this.state.physician_id.value,
              label: response.data.providers.find(
                (x) => x.value === this.state.physician_id.value
              ).label,
            },
            visited: false,
            error: false,
          };
        }
        this.setState({
          ...fields,
          loaded: true,
          listProviders: response.data.providers,
        });
      })
      .catch((err) => {
        alert("Error, please try again later");
      });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const formData = JSON.parse(JSON.stringify(this.state));
    const postData = {};
    let isValid = true;

    Object.keys(this.state.validator).forEach((key) => {
      postData[key] =
        formData[key].value !== null && formData[key].value.value !== undefined
          ? formData[key].value.value
          : formData[key].value;
      for (let i = 0; i < this.state.validator[key].length; i++) {
        const validationMsg = this.state.validator[key][i](formData[key].value);
        formData[key].visited = true;
        if (validationMsg !== undefined) {
          formData[key].error = validationMsg;
          isValid = false;
          break;
        } else {
          formData[key].error = false;
        }
      }
    });
    this.setState({ ...formData });

    if (isValid) {
      this.setState(
        {
          submitting: true,
        },
        () => {
          postData["status"] =
            this.props.entity.status === -1 ? 2 : this.props.entity.status + 1;
          axiosInstance()
            .post(`/reports/set-status/${this.props.entity.id}.json`, postData)
            .then((response) => {
              if (response.data.entity.status === 2) {
                this.props.history.push(`/`);
              } else {
                this.props.getEntity(response.data.entity);
                this.props.toggle();
              }
            })
            .catch((err) => {});
        }
      );
    }
    return false;
  };

  updateForm = (event, name) => {
    const inputData = {
      value:
        event !== null && event.target !== undefined
          ? event.target.value
          : event,
      visited: true,
      error: false,
    };

    for (let i = 0; i < this.state.validator[name].length; i++) {
      const validationMsg = this.state.validator[name][i](inputData.value);
      if (validationMsg !== undefined) {
        inputData.error = validationMsg;
        break;
      }
    }

    this.setState({
      [name]: inputData,
    });
  };

  render = () => {
    return (
      <Modal
        isOpen={this.props.isActive}
        toggle={this.state.submitting === false ? this.props.toggle : null}
        className={this.props.className}
        size={"lg"}
      >
        <form
          ref={(c) => {
            this.form = c;
          }}
          onSubmit={this.submitHandler.bind(this)}
        >
          {this.state.loaded && (
            <React.Fragment>
              {this.props.entity.status === 0 && (
                <ReadyForReview
                  entity={this.props.entity}
                  toggle={
                    this.state.submitting === false ? this.props.toggle : null
                  }
                  listProviders={this.state.listProviders}
                  fieldProviderId={this.state.physician_id}
                  updateForm={this.updateForm}
                  submitting={this.state.submitting}
                />
              )}
              {(this.props.entity.status === -1 ||
                this.props.entity.status === 1) && (
                <ReadyForFinalize
                  entity={this.props.entity}
                  toggle={
                    this.state.submitting === false ? this.props.toggle : null
                  }
                  submitting={this.state.submitting}
                />
              )}
            </React.Fragment>
          )}
        </form>
      </Modal>
    );
  };
}
export default withRouter(ReportStatus);
