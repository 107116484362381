import React from "react";
import { displayValue } from "functions";

export default (props) => {
  return (
    <React.Fragment>
      <div className={"pb-2"}>
        <div className={"font-weight-bold"}>Rest Dose</div>
        <div>
          {displayValue(props.rest_dose_amount)} mCi of Rubidium 82 was injected
          during resting imaging.
        </div>
      </div>
      <div className={"pb-2"}>
        <div className={"font-weight-bold"}>Stress Dose</div>
        <div>
          {displayValue(props.rest_dose_amount)} mCi of Rubidium 82 was injected
          during stress imaging.
        </div>
      </div>
      <div className={"pb-2"}>
        <div className={"font-weight-bold"}>Stress Test</div>
        <div>
          {displayValue(props.stress_test_percentage)} mg of{" "}
          {displayValue(props.stress_dose_type)} was injected during stress
          test.
        </div>
      </div>
      <div className={"pb-2"}>
        <div className={"font-weight-bold"}>Post Stress Test</div>
        <div>
          {displayValue(props.stress_test_length)} mg of Aminophylline was
          injected post stress test.
        </div>
      </div>
      <div className={"pb-2"}>
        <div className={"font-weight-bold"}>Lexicon Infusion</div>
        <div>
          The patient was noted to have {displayValue(props.lexicon_infusion_1)}{" "}
          during Lexiscan infusion.
        </div>
        <div>
          The patient was noted to have {displayValue(props.lexicon_infusion_2)}{" "}
          during Lexiscan infusion.
        </div>
      </div>

      <div className={"pb-2"}>
        <div className={"font-weight-bold"}>Administration</div>
        <div>
          {props.is_custom_administration === 1 ? (
            <React.Fragment>
              {displayValue(props.custom_administration_location)}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {displayValue(props.administration_location)}
            </React.Fragment>
          )}{" "}
          by {displayValue(props.initials)}
        </div>
      </div>
      <div className={"pb-2"}>
        <div>
          {props.limit_exceeded === 1
            ? "Attenuation corrected images were obtained."
            : ""}
        </div>
      </div>
    </React.Fragment>
  );
};
