import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Alert
} from 'reactstrap';
import LabelInput from 'components/Elements/LabelInput/LabelInput';
import { required, passwordCompare, passwordLength } from 'validation';
import ProgressBox from 'components/ProgressBox/ProgressBox';

const validator = {
  password: [required, passwordLength],
  repassword: [required, passwordLength]
};
class UserModal extends React.Component {
  constructor(props) {
    super(props);
    const fields = {};

    Object.keys(validator).forEach(key => {
      fields[key] = {
        value: '',
        visited: false,
        error: false
      };
    });

    this.state = { ...fields };
  }

  updateForm = (event, name) => {
    const inputData = {
      value: event.target.value,
      visited: true,
      error: false
    };

    for (let i = 0; i < validator[name].length; i++) {
      const validationMsg = validator[name][i](inputData.value);
      if (validationMsg !== undefined) {
        inputData.error = validationMsg;
        break;
      }
    }
    if (name === 'repassword' && inputData.error === false) {
      let validationPass = passwordCompare(
        inputData.value,
        this.state.password.value
      );
      if (validationPass !== undefined) {
        inputData.error = validationPass;
      }
    }

    this.setState({
      [name]: inputData
    });
  };

  submitHandler = e => {
    e.preventDefault();
    const formData = JSON.parse(JSON.stringify(this.state));
    const postData = {};
    let isValid = true;

    Object.keys(validator).forEach(key => {
      postData[key] = formData[key].value;
      for (let i = 0; i < validator[key].length; i++) {
        const validationMsg = validator[key][i](formData[key].value);
        formData[key].visited = true;
        if (validationMsg !== undefined) {
          formData[key].error = validationMsg;
          isValid = false;
          break;
        } else {
          formData[key].error = false;
        }
      }

      if (key === 'repassword' && formData[key].error === false) {
        let validationPass = passwordCompare(
          formData[key].value,
          this.state.password.value
        );
        if (validationPass !== undefined) {
          formData[key].error = validationPass;
          isValid = false;
        }
      }
    });

    this.setState({ ...formData });

    if (isValid) {
      this.props.onSubmit(postData);
    }
    return false;
  };

  render = () => {
    return (
      <Modal
        isOpen={this.props.isActive}
        toggle={this.props.toggle}
        className={this.props.className}
      >
        <form
          ref={c => {
            this.form = c;
          }}
          onSubmit={this.submitHandler.bind(this)}
        >
          <ModalHeader toggle={this.props.toggle}>
            {'Reset User Password'}
          </ModalHeader>
          <div className={'position-relative'}>
            {this.props.loading && <ProgressBox text={'Submiting ...'} />}
            <ModalBody className={'p-4'}>
              <Row>
                <Col>
                  {this.props.error !== false && (
                    <Alert color={'danger'}>{this.props.error}</Alert>
                  )}

                  <p>Email: {this.props.entity.email}</p>
                  <div>
                    <LabelInput
                      name={'New Password'}
                      type={'password'}
                      required={true}
                      {...this.state.password}
                      onChange={event => this.updateForm(event, 'password')}
                    />
                    <LabelInput
                      name={'Confirm New Password'}
                      type={'password'}
                      required={true}
                      {...this.state.repassword}
                      onChange={event => this.updateForm(event, 'repassword')}
                    />
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className={'py-4'}>
              <Button color="primary" className={'btn px-3'} type={'submit'}>
                Update User Password
              </Button>
            </ModalFooter>
          </div>
        </form>
      </Modal>
    );
  };
}

export default UserModal;
