import React from "react";
import { Button } from "reactstrap";

export default (props) => {
  const authData = JSON.parse(localStorage.getItem("auth")).user;
  const { status } = props.entity;
  return (
    <React.Fragment>
      {status === 0 && (
        <Button color={"primary"} {...props}>
          Mark as Ready for Review
        </Button>
      )}
      {(status === 1 || status === -1) && authData.role !== "Technician" && (
        <Button color={"primary"} {...props}>
          Finalize Report
        </Button>
      )}
    </React.Fragment>
  );
};
