import React, { Component } from "react";
import Select from "react-select";
import axiosInstance from "axios-instance";
import LabelInput from "components/Elements/LabelInput/LabelInput";
import { displayValue } from "functions";

const _objectWithoutProperties = (obj, keys) => {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
};

class FindingsForm extends Component {
  constructor(props) {
    super(props);
    const fields = {};

    Object.keys(props.conclusions).map((fieldNo) => {
      let key = `field_${fieldNo}`;
      fields[key] = {
        value:
          props.entity !== null &&
          props.entity[key] !== undefined &&
          props.entity[key] !== null
            ? isNaN(props.entity[key])
              ? props.conclusions[fieldNo].find(
                  (x) => x.label === props.entity[key]
                )
              : props.entity[key]
            : "",
        visited: false,
        error: false,
      };
      return null;
    });

    fields["selected_field"] = {
      value:
        props.entity.selected_field !== undefined
          ? props.entity.selected_field
          : 0,
      visited: false,
      error: false,
    };

    fields["notes"] = {
      value: props.entity.notes !== undefined ? props.entity.notes : "",
      visited: false,
      error: false,
    };

    this.state = { ...fields };
  }

  componentDidMount() {
    const { childRef } = this.props;
    if (childRef !== undefined) {
      childRef(this);
    }
  }
  componentWillUnmount() {
    const { childRef } = this.props;
    if (childRef !== undefined) {
      childRef(undefined);
    }
  }

  updateForm = (event, name) => {
    let value =
      event !== null && event.target !== undefined ? event.target.value : event;

    if (name === "selected_field") {
      value = this.state.selected_field.value + value;
    }

    const inputData = {
      value: value,
      visited: true,
      error: false,
    };

    this.setState({
      [name]: inputData,
    });
  };

  onSubmit = (callback) => {
    const formData = JSON.parse(JSON.stringify(this.state));
    const postData = {};
    let isValid = true;

    Object.keys(formData).map((key) => {
      postData[key] = formData[key].value;
      return null;
    });

    if (isValid) {
      axiosInstance()
        .post(
          `/reports/save-exercise-conclusions/${this.props.reportId}.json`,
          postData
        )
        .then((response) => {
          callback(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return false;
  };

  render = () => {
    const fields = {};

    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: "30px",
        minHeight: "30px",
      }),
      singleValue: (styles) =>
        _objectWithoutProperties(styles, ["maxWidth", "position", "transform"]),
      menu: (styles) => ({
        ...styles,
        whiteSpace: "nowrap",
        width: "auto",
        right: 0,
      }),
    };

    Object.keys(this.props.conclusions).map((key) => {
      fields[key] =
        this.props.conclusions[key].length > 1 ? (
          (disabled) => (
            <div
              className={"d-inline-block px-2 mb-1"}
              style={{ minWidth: "160px" }}
            >
              <Select
                value={this.state[`field_${key}`].value}
                options={this.props.conclusions[key]}
                menuPlacement={"auto"}
                styles={customStyles}
                isClearable={true}
                isDisabled={disabled}
                onChange={(event) => {
                  this.updateForm(event, `field_${key}`);
                }}
              />
            </div>
          )
        ) : (
          <span>{this.props.conclusions[key][0].label}</span>
        );
      return null;
    });

    return (
      <div className={"position-relative"}>
        <ol>
          <li>
            <div className="radio mb-1 text-left">
              <label>
                <input
                  type={"checkbox"}
                  name={"selected_field"}
                  checked={
                    this.state["selected_field"].value > 0 &&
                    (this.state["selected_field"].value & 1) > 0
                  }
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 1 : -1,
                      `selected_field`
                    )
                  }
                />{" "}
                <span>Myocardial perfusion imaging is normal.</span>
              </label>
            </div>
            <div className="radio mb-1 text-left">
              <label>
                <input
                  type={"checkbox"}
                  name={"selected_field"}
                  checked={
                    this.state["selected_field"].value > 0 &&
                    (this.state["selected_field"].value & 2) > 0
                  }
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 2 : -2,
                      `selected_field`
                    )
                  }
                />{" "}
                <span>
                  Myocardial perfusion imaging is normal with presumed
                  attenuation in the{"  "}
                  <div className={"d-inline-block"}>
                    <LabelInput
                      type={"text"}
                      size={"sm"}
                      formGroupClass={"mb-0"}
                      readOnly={(this.state["selected_field"].value & 2) === 0}
                      className={"form-control form-control-sm"}
                      {...this.state.field_1}
                      onChange={(event) => this.updateForm(event, "field_1")}
                    />
                  </div>{" "}
                  wall(s).
                </span>
              </label>
            </div>
            <div className="radio mb-1 text-left">
              <label>
                <input
                  type={"checkbox"}
                  name={"selected_field"}
                  checked={
                    this.state["selected_field"].value > 0 &&
                    (this.state["selected_field"].value & 4) > 0
                  }
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 4 : -4,
                      `selected_field`
                    )
                  }
                />{" "}
                <span>
                  Myocardial perfusion imaging is probably normal. There is a
                  fixed defect in the{"  "}
                  <div className={"d-inline-block"}>
                    <LabelInput
                      type={"text"}
                      size={"sm"}
                      formGroupClass={"mb-0"}
                      readOnly={(this.state["selected_field"].value & 4) === 0}
                      className={"form-control form-control-sm"}
                      {...this.state.field_2}
                      onChange={(event) => this.updateForm(event, "field_2")}
                    />
                  </div>{" "}
                  wall(s). While this likely represents an attenuation artifact
                  a true perfusion defect cannot be completely excluded.
                </span>
              </label>
            </div>

            <div className="radio mb-1 text-left">
              <label>
                <input
                  type={"checkbox"}
                  name={"selected_field"}
                  checked={
                    this.state["selected_field"].value > 0 &&
                    (this.state["selected_field"].value & 8) > 0
                  }
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 8 : -8,
                      `selected_field`
                    )
                  }
                />{" "}
                <span>
                  Myocardial perfusion imaging is equivocal. There is
                  reversibility in the{"  "}
                </span>
              </label>
              <div className={"d-inline-block pl-1"}>
                <LabelInput
                  type={"text"}
                  size={"sm"}
                  formGroupClass={"mb-0"}
                  readOnly={(this.state["selected_field"].value & 8) === 0}
                  className={"form-control form-control-sm"}
                  {...this.state.field_3}
                  onChange={(event) => this.updateForm(event, "field_3")}
                />
              </div>{" "}
              <span
                style={
                  (this.state["selected_field"].value & 8) === 0
                    ? { color: "#b7b7b7" }
                    : {}
                }
              >
                wall(s) possibly related to{" "}
                {fields[4]((this.state["selected_field"].value & 8) === 0)} ,
                however ischemia cannot be completely excluded.
              </span>
            </div>
            <div className="radio mb-1 text-left">
              <label>
                <input
                  type={"checkbox"}
                  name={"selected_field"}
                  checked={
                    this.state["selected_field"].value > 0 &&
                    (this.state["selected_field"].value & 16) > 0
                  }
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 16 : -16,
                      `selected_field`
                    )
                  }
                />{" "}
                <span>
                  Myocardial perfusion imaging is abnormal due to [
                  <span>
                    {this.props.report_exercise_finding.field_14 === "1" && (
                      <span>{this.props.findings[14][0].label}</span>
                    )}
                    {this.props.report_exercise_finding.field_14 !== "1" && (
                      <span>
                        a{" "}
                        {displayValue(
                          this.props.report_exercise_finding.field_15
                        )}{" "}
                        size perfusion abnormality of{" "}
                        {displayValue(
                          this.props.report_exercise_finding.field_16
                        )}{" "}
                        intensity in the{" "}
                        {displayValue(
                          this.props.report_exercise_finding.field_17
                        )}{" "}
                        wall segment(s) that is{" "}
                        {displayValue(
                          this.props.report_exercise_finding.field_18
                        )}{" "}
                        {this.props.field_18 === "reversible" &&
                          displayValue(
                            this.props.report_exercise_finding.field_18_1
                          )}
                        {this.props.field_18 === "fixed" && (
                          <React.Fragment>
                            {displayValue(
                              this.props.report_exercise_finding.field_18_2
                            )}
                            {this.props.report_exercise_finding.field_18_2 ===
                              "with" && (
                              <React.Fragment>
                                {" "}
                                a{" "}
                                {displayValue(
                                  this.props.report_exercise_finding.field_19
                                )}{" "}
                                amount of peri-infarct ischemia
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                        .
                        {this.props.report_exercise_finding.report_exercise_finding_sentences.map(
                          (item) => {
                            if (item.type !== 2) return null;
                            return (
                              <span key={`item-` + item.id}>
                                {" "}
                                There is also a {displayValue(
                                  item.field_1
                                )}{" "}
                                size perfusion abnormality of{" "}
                                {displayValue(item.field_2)} intensity in the{" "}
                                {displayValue(item.field_3)} that is{" "}
                                {displayValue(item.field_4)}{" "}
                                {item.field_4 === "reversible" &&
                                  displayValue(item.field_5)}
                                {item.field_4 === "fixed" && (
                                  <React.Fragment>
                                    {displayValue(item.field_6)}
                                    {item.field_6 === "with" && (
                                      <React.Fragment>
                                        {" "}
                                        a {displayValue(item.field_7)} amount of
                                        peri-infarct ischemia
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                )}
                                .
                              </span>
                            );
                          }
                        )}
                      </span>
                    )}
                  </span>
                  ]
                </span>
              </label>
            </div>

            <div className="radio mb-1 text-left">
              <label>
                <input
                  type={"checkbox"}
                  name={"selected_field"}
                  checked={
                    this.state["selected_field"].value > 0 &&
                    (this.state["selected_field"].value & 32) > 0
                  }
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 32 : -32,
                      `selected_field`
                    )
                  }
                />{" "}
                <span>Compared to a prior study these findings are </span>
              </label>
              <span>
                {fields[6]((this.state["selected_field"].value & 32) === 0)}.
              </span>
            </div>
          </li>
          <li>
            <div className="radio mb-1 text-left">
              <label>
                <input
                  type={"checkbox"}
                  name={"selected_field"}
                  checked={
                    this.state["selected_field"].value > 0 &&
                    (this.state["selected_field"].value & 64) > 0
                  }
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 64 : -64,
                      `selected_field`
                    )
                  }
                />{" "}
                <span>The left ventricular systolic function is </span>
              </label>
              <span>
                {fields[7]((this.state["selected_field"].value & 64) === 0)}.
              </span>
            </div>
            <div className="radio mb-1 text-left">
              <label>
                <input
                  type={"checkbox"}
                  name={"selected_field"}
                  checked={
                    this.state["selected_field"].value > 0 &&
                    (this.state["selected_field"].value & 128) > 0
                  }
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 128 : -128,
                      `selected_field`
                    )
                  }
                />{" "}
                <span>
                  The left ventricular ejection fraction was not calculated
                  because the study was not gated.
                </span>
              </label>
            </div>
          </li>
          <li>
            <div className="radio mb-1 text-left">
              <label>
                <input
                  type={"checkbox"}
                  name={"selected_field"}
                  checked={
                    this.state["selected_field"].value > 0 &&
                    (this.state["selected_field"].value & 256) > 0
                  }
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 256 : -256,
                      `selected_field`
                    )
                  }
                />{" "}
                <span>The stress test results are described below.</span>
              </label>
            </div>
          </li>
          <li>
            <LabelInput
              type={"text"}
              {...this.state.notes}
              onChange={(event) => this.updateForm(event, "notes")}
            />
          </li>
        </ol>
        {/* this.state.conclusion_type.value !== null &&
          this.state.conclusion_type.value.value === "normal" && (
            <React.Fragment>
              <div className="radio mt-3 text-left">
                <label>
                  <input
                    type={"radio"}
                    name={"conclusion_type_value"}
                    checked={this.state["conclusion_type_value"].value === 1}
                    onChange={(event) =>
                      this.updateForm(
                        event.currentTarget.checked ? 1 : 0,
                        `conclusion_type_value`
                      )
                    }
                  />
                  <span>&nbsp;Myocardial perfusion imaging is normal.</span>
                </label>
              </div>
              <div className="radio mb-1 text-left">
                <label>
                  <input
                    type={"radio"}
                    name={"conclusion_type_value"}
                    checked={this.state["conclusion_type_value"].value === 2}
                    onChange={(event) =>
                      this.updateForm(
                        event.currentTarget.checked ? 2 : 0,
                        `conclusion_type_value`
                      )
                    }
                  />
                  <span>
                    &nbsp;Myocardial perfusion imaging is normal with presumed
                    attenuation of the
                  </span>
                </label>
                {fields[1](this.state["conclusion_type_value"].value !== 2)}{" "}
                wall(s).
              </div>
            </React.Fragment>
          ) */}
        {/* this.state.conclusion_type.value !== null &&
          this.state.conclusion_type.value.value === "abnormal" && (
            <React.Fragment>
              <div className="checkbox mt-3 text-left">
                <input
                  type={"checkbox"}
                  checked={this.state["conclusion_type_value"].value === 1}
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 1 : 0,
                      `conclusion_type_value`
                    )
                  }
                />{" "}
                <span>
                  Myocardial perfusion imaging is abnormal and demonstrates{" "}
                  {fields[2](this.state["conclusion_type_value"].value !== 1)}{" "}
                  ischemia in the{" "}
                  {fields[3](this.state["conclusion_type_value"].value !== 1)}{" "}
                  wall(s).{" "}
                </span>
              </div>
              <div className="checkbox mb-1 text-left">
                <input
                  type={"checkbox"}
                  checked={this.state["conclusion_type_value_2"].value === 1}
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 1 : 0,
                      `conclusion_type_value_2`
                    )
                  }
                />{" "}
                <span>
                  A{" "}
                  {fields[4](this.state["conclusion_type_value_2"].value !== 1)}{" "}
                  scar involving the{" "}
                  {fields[5](this.state["conclusion_type_value_2"].value !== 1)}{" "}
                  wall(s).
                </span>
              </div>
            </React.Fragment>
          )*/}

        {/* this.state.conclusion_type.value !== null &&
          this.state.conclusion_type.value.value === "equivocal" && (
            <React.Fragment>
              <div className={"pt-3"}>
                Myocardial perfusion imaging is abnormal and demonstrates
                reversibility in the {fields[6](false)} wall(s){" "}
                {fields[7](false)}.
              </div>
            </React.Fragment>
          )}
        {this.state.conclusion_type.value !== null &&
          this.state.conclusion_type.value.value === "probably-normal" && (
            <React.Fragment>
              <div className={"pt-3"}>
                <ol>
                  <li>
                    Myocardial perfusion imaging demonstrates a fixed defect in
                    the {fields[8](false)} wall(s). While this may represent an
                    attenuation artifact, a true perfusion defect cannot be
                    excluded.
                  </li>
                  {/*<li>
                    The left ventricular systolic function is {fields[9](false)}
                    .
                  </li>*/}
        {/*<li>The stress test results are described above.</li>
                  <li>
                    <div className="checkbox text-left">
                      <label className={"mb-0"}>
                        <input
                          type={"checkbox"}
                          checked={
                            this.state["conclusion_type_value"].value === 1
                          }
                          onChange={(event) =>
                            this.updateForm(
                              event.currentTarget.checked ? 1 : 0,
                              `conclusion_type_value`
                            )
                          }
                        />
                        <span>
                          &nbsp;The study was not gated. (If checked, 2) will be
                          taken out of the conclusion).
                        </span>
                      </label>
                    </div>
                  </li>}
                  <li>
                    <div className="checkbox mb-1 text-left">
                      <label>
                        <input
                          type={"checkbox"}
                          checked={
                            this.state["conclusion_type_value_2"].value === 1
                          }
                          onChange={(event) =>
                            this.updateForm(
                              event.currentTarget.checked ? 1 : 0,
                              `conclusion_type_value_2`
                            )
                          }
                        />
                        <span>
                          &nbsp;Motion artifact was present on this study which
                          may reduce the sensitivity and/or specificity of the
                          findings.
                        </span>
                      </label>
                    </div>
                  </li>
                </ol>
              </div>
            </React.Fragment>
          ) */}
      </div>
    );
  };
}

export default FindingsForm;
