import React, { Component } from "react";
import { Container, Alert } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "store/actions";
import ProgressBox from "components/ProgressBox/ProgressBox";

import Style from "./Login.module.scss";
import Logo from "assets/images/legacy-logo.jpeg";
// Images

class Login extends Component {
  state = {
    email: "",
    password: "",
    rememberMe: true,
  };

  updateForm = (event, name) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  submitHandler = (event) => {
    event.preventDefault();
    this.props.onAuth(
      this.state.email,
      this.state.password,
      this.state.rememberMe
    );
    return false;
  };

  onKeepLoggedIn = (event) => {
    this.setState({
      rememberMe: event.currentTarget.checked,
    });
  };

  render = () => {
    return (
      <Container fluid={true} className={"h-100 text-center d-flex"}>
        <form className={Style["form-signin"]} onSubmit={this.submitHandler}>
          <div className={"position-relative p-2"}>
            {this.props.loading && (
              <ProgressBox
                text={"Submitting ..."}
                loader={"white-background"}
              />
            )}
            <div className={"mb-3"}>
              <img src={Logo} className={"img-fluid"} alt={"Logo"} />
            </div>
            <h1 className="h3 mb-3 font-weight-normal">NewportX</h1>
            {this.props.error !== null && (
              <div>
                <Alert color={"danger"}>{this.props.error}</Alert>
              </div>
            )}
            <input
              className={"form-control mb-3"}
              name={"Email"}
              type={"text"}
              placeholder={"Email"}
              required={true}
              value={this.state.email}
              onChange={(event) => this.updateForm(event, "email")}
            />

            <input
              className={"form-control"}
              name={"Password"}
              type={"password"}
              placeholder={"Password"}
              required={true}
              autoComplete={"off"}
              value={this.state.password}
              onChange={(event) => this.updateForm(event, "password")}
            />
            <div className="checkbox mb-3 text-left pt-3">
              <label>
                <input
                  type={"checkbox"}
                  checked={this.state.rememberMe}
                  onChange={this.onKeepLoggedIn}
                />{" "}
                Keep Me Signed In
              </label>
            </div>
            <button className="btn btn-lg btn-primary btn-block" type="submit">
              Sign in
            </button>
          </div>
        </form>
      </Container>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    loading: state.auth.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password, rememberMe) =>
      dispatch(actions.auth(email, password, rememberMe)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
