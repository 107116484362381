import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import LabelInput from "components/Elements/LabelInput/LabelInput";

import { required } from "validation";
const validator = {
  id: [],
  name: [required],
};

class CreateStatement extends React.Component {
  constructor(props) {
    super(props);

    const fields = {};
    Object.keys(validator).forEach((key) => {
      fields[key] = {
        value:
          this.props.entity[key] !== undefined &&
          this.props.entity[key] !== null
            ? this.props.entity[key]
            : "",
        visited: false,
        error: false,
      };
    });

    this.state = {
      ...fields,
    };
  }

  onSave = (event) => {
    event.preventDefault();
    const formData = JSON.parse(JSON.stringify(this.state));
    const postData = {};
    let isValid = true;

    Object.keys(validator).forEach((key) => {
      postData[key] = formData[key].value;
      for (let i = 0; i < validator[key].length; i++) {
        const validationMsg = validator[key][i](formData[key].value);
        formData[key].visited = true;
        if (validationMsg !== undefined) {
          formData[key].error = validationMsg;
          isValid = false;
          break;
        } else {
          formData[key].error = false;
        }
      }
    });

    if (isValid) {
      this.props.onSubmit(postData);
    }
    console.log(postData);
  };

  updateForm = (event, name) => {
    const inputData = {
      value:
        event !== null && event.target !== undefined
          ? event.target.value
          : event,
      visited: true,
      error: false,
    };

    for (let i = 0; i < validator[name].length; i++) {
      const validationMsg = validator[name][i](inputData.value);
      if (validationMsg !== undefined) {
        inputData.error = validationMsg;
        break;
      }
    }
    const toUpdate = {
      [name]: inputData,
    };

    this.setState(toUpdate);
  };

  render = () => {
    return (
      <Modal
        isOpen={this.props.isActive}
        toggle={this.props.toggle}
        className={this.props.className}
        size={"lg"}
        backdrop={"static"}
      >
        <ModalHeader toggle={this.props.toggle}>
          {this.props.entity.id !== undefined
            ? "Edit Statement"
            : "Add New Statement"}
        </ModalHeader>
        <div className={"position-relative"}>
          <ModalBody>
            <div className={"pb-3"}>
              <span className={"font-weight-bold d-block"}>Finding</span>
              <span>{this.props.findingName}</span>
            </div>
            <div className={"pb-3"}>
              <span className={"font-weight-bold d-block"}>Finding Item</span>
              <span>{this.props.findingItem.name}</span>
            </div>
            <LabelInput
              name={"Statement"}
              type={"textarea"}
              {...this.state.name}
              rows={3}
              required={true}
              onChange={(event) => this.updateForm(event, "name")}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="light"
              className={"btn px-3"}
              type={"button"}
              onClick={this.props.toggle}
            >
              Cancel
            </Button>

            {this.props.submitting === false ? (
              <Button
                color="primary"
                className={"btn px-3"}
                type={"submit"}
                onClick={this.onSave}
              >
                {this.props.entity.id !== undefined ? "Edit" : "Create"}
              </Button>
            ) : (
              <button className="btn btn-primary px-5" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                &nbsp;&nbsp; Loading...
              </button>
            )}
          </ModalFooter>
        </div>
      </Modal>
    );
  };
}

export default CreateStatement;
