import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

import "./Header.module.scss";
import connect from "react-redux/es/connect/connect";

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
      <header>
        <Container>
          <Navbar expand="md" className={"py-0 px-0"}>
            <Link className={"navbar-brand"} to="/">
              NewPortX
            </Link>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <Link to={"/"} className={"nav-link rounded-0"}>
                    View Reports
                  </Link>
                </NavItem>
                <NavItem active={this.props.activeMenu === "eois"}>
                  <Link to={"/reports/create"} className={"nav-link rounded-0"}>
                    Create Report
                  </Link>
                </NavItem>
                <UncontrolledDropdown nav inNavbar className={"mr-0"}>
                  <DropdownToggle nav caret>
                    <FontAwesomeIcon icon={["fas", "user-circle"]} />{" "}
                    {this.props.name}
                  </DropdownToggle>
                  <DropdownMenu right>
                    {this.props.isManager && (
                      <React.Fragment>
                        <Link to={"/admin/users"} className={"dropdown-item"}>
                          Manage Users
                        </Link>
                      </React.Fragment>
                    )}
                    <Link to={"/my-account"} className={"dropdown-item"}>
                      Manage My Account
                    </Link>
                    {(this.props.isManager ||
                      this.props.isFindingsDatabaseAllow) && (
                      <Link
                        to={"/admin/echo-findings-database"}
                        className={"dropdown-item"}
                      >
                        Echo Findings Database
                      </Link>
                    )}
                    <Link to={"/logout"} className={"dropdown-item"}>
                      Logout
                    </Link>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Navbar>
        </Container>
      </header>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    name: state.auth.first_name + " " + state.auth.last_name,
  };
};
export default connect(mapStateToProps, null)(Header);
