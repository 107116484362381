import React from "react";

export default (props) => {
  return (
    <div
      className={
        "form-group " +
        (props.formGroupClass !== undefined ? props.formGroupClass : "")
      }
    >
      {props.name !== undefined && (
        <label className={"font-weight-bold"}>
          {props.name}
          {props.required !== undefined && props.required && " *"}
        </label>
      )}
      {props.type === "textarea" ? (
        <textarea
          className={
            "form-control " +
            (props.visited && props.error !== false ? "is-invalid" : "")
          }
          {...props}
          visited={null}
          error={null}
          placeholder={props.required === true ? "" : "Optional"}
        />
      ) : (
        <input
          className={
            "form-control " +
            (props.visited && props.error !== false ? "is-invalid" : "")
          }
          {...props}
          visited={null}
          error={null}
          placeholder={
            props.required === true
              ? props.placeholder !== undefined
                ? props.placeholder
                : ""
              : props.placeholder !== undefined
              ? props.placeholder
              : "Optional"
          }
        />
      )}
      {props.visited && <span className={"error"}>{props.error}</span>}
    </div>
  );
};
