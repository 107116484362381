import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// Pages
import Login from "components/Login/Login";
import MyAccount from "components/MyAccount/MyAccount";
import Dashboard from "components/Dashboard/Dashboard";
//Reports
import CreateReport from "components/Reports/CreateReport/CreateReport";
import ViewReport from "components/Reports/ViewReport/ViewReport";
// Admin
import AdminUsers from "components/Admin/ManageUsers/ManageUsers";
import AdminEditUsers from "components/Admin/EditUser/EditUser";
import AdminFindings from "components/Admin/Findings/Findings";

// Layouts
import Default from "components/Layout/Default";
import Auth from "components/Layout/Auth";

export const Router = (props) => (
  <Switch>
    <Route
      path={"/"}
      exact
      render={() => {
        if (props.isLogged) {
          return (
            <Default {...props} activeMenu={"dashboard"}>
              <Dashboard {...props} />
            </Default>
          );
        } else {
          return (
            <Auth {...props}>
              <Login {...props} />
            </Auth>
          );
        }
      }}
    />

    {/* Admin */}
    <Route
      path={"/admin/users"}
      exact
      render={() => {
        return (
          <Default {...props} activeMenu={"admin-users"}>
            <AdminUsers {...props} />
          </Default>
        );
      }}
    />
    <Route
      path={"/admin/echo-findings-database"}
      exact
      render={() => {
        return (
          <Default {...props} activeMenu={"admin-findings"}>
            <AdminFindings {...props} />
          </Default>
        );
      }}
    />
    <Route
      path={"/admin/users/:userId"}
      exact
      render={(routeOption) => {
        return (
          <Default {...props} activeMenu={"admin-users"}>
            <AdminEditUsers {...routeOption.match.params} {...props} />
          </Default>
        );
      }}
    />

    {/* Reports */}
    <Route
      path={"/reports/create"}
      exact
      render={() => {
        return (
          <Default {...props} activeMenu={"reports"}>
            <CreateReport {...props} />
          </Default>
        );
      }}
    />
    <Route
      path={"/reports/:id"}
      exact
      render={(routeOption) => (
        <Default {...props} activeMenu={"associations"}>
          <ViewReport {...routeOption.match.params} {...props} />
        </Default>
      )}
    />

    {/* My Account */}
    <Route
      path={"/my-account"}
      exact
      render={() => {
        return (
          <Default {...props} activeMenu={"account"}>
            <MyAccount {...props} />
          </Default>
        );
      }}
    />

    <Route
      path={"/unathorized"}
      exact
      render={() => {
        return (
          <Default {...props} activeMenu={"account"}>
            <h1 className={"text-center pt-5 color-danger"}>
              Your account is not authorized to access this view.
            </h1>
          </Default>
        );
      }}
    />

    <Route
      path={"/logout"}
      exact
      render={() => {
        props.onLogout();
        return (
          <Default {...props}>
            <Redirect to="/" />
          </Default>
        );
      }}
    />
    <Redirect to={"/"} />
  </Switch>
);
