import React, { Component } from 'react';

import Header from './Header/Header';
import { Redirect } from 'react-router';

class Default extends Component {
  render = () => {
    return (
      <React.Fragment>
        {this.props.isLogged === false && <Redirect to="/" />}
        <Header {...this.props} />
        <div className={'mt-5'}>{this.props.children}</div>
      </React.Fragment>
    );
  };
}

export default Default;
