import React from "react";
import { Table, Row, Col } from "reactstrap";
import moment from "moment";

export default (props) => {
  const { entity } = props;
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <Table size={"sm"} borderless={true} className={"mb-0"}>
            <tbody>
              <tr>
                <th className={"w-25"}>Report Type</th>
                <td>{entity.report_type.name}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <hr className={"my-1"} />
      <Row>
        <Col className={"pr-0"}>
          <Table size={"sm"} borderless={true} className={"mb-0"}>
            <tbody>
              <tr>
                <th className={"w-50"}>Patient</th>
                <td className={"w-50"}>
                  {entity.patient.first_name} {entity.patient.last_name}
                </td>
              </tr>
              <tr>
                <th className={"w-50"}>Gender</th>
                <td className={"w-50"}>{entity.gender}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col className={"pl-0"}>
          <Table size={"sm"} borderless={true} className={"mb-0"}>
            <tbody>
              <tr>
                <th className={"w-50"}>Study Date</th>
                <td className={"w-50"}>
                  {moment(entity.study_date * 1000).format("MM/DD/YYYY")}
                </td>
              </tr>
              <tr>
                <th className={"w-50"}>Age</th>
                <td className={"w-50"}>{entity.age}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <hr className={"my-1"} />
      <Row>
        <Col>
          <Table size={"sm"} borderless={true} className={"mb-0"}>
            <tbody>
              <tr>
                <th className={"w-25"}>Clinical Indications</th>
                <td>{entity.notes}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <hr className={"my-1"} />
      <Row>
        <Col className={"pr-0"}>
          <Table size={"sm"} borderless={true} className={"mb-0"}>
            <tbody>
              <tr>
                <th className={"w-50"}>{"Technician"}</th>
                <td className={"w-50"}>
                  {entity.technician_id !== null && (
                    <React.Fragment>{`${entity.technician.first_name} ${entity.technician.last_name}`}</React.Fragment>
                  )}
                </td>
              </tr>
              <tr>
                <th className={"w-50"}>Reading Physician</th>
                <td className={"w-50"}>
                  {entity.physician !== null && (
                    <React.Fragment>{`${entity.physician.first_name} ${entity.physician.last_name}`}</React.Fragment>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col className={"pl-0"}>
          <Table size={"sm"} borderless={true} className={"mb-0"}>
            <tbody>
              {entity.referring_physicians.map((physician) => {
                return (
                  <React.Fragment key={"report-view-physician-" + physician.id}>
                    <tr>
                      <th className={"w-50"}>Referring Physician</th>
                      <td className={"w-50"}>
                        {physician.last_name}, {physician.first_name},{" "}
                        {physician.suffix}
                      </td>
                    </tr>
                    <tr>
                      <th className={"w-50"}>Fax</th>
                      <td className={"w-50"}>{physician.fax}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </React.Fragment>
  );
};
