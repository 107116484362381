import React, { Component } from "react";
import { withRouter } from "react-router";

import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Input,
  Table,
  Badge,
} from "reactstrap";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import LabelInput from "components/Elements/LabelInput/LabelInput";
import SortableTh from "components/Elements/SortableTh/SortableTh";
import PaginationPanel from "components/Elements/PaginationPanel/PaginationPanel";
import axiosInstance from "axios-instance";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import Select from "react-select";

let searchTimeout = null;
class Dashboard extends Component {
  constructor(props) {
    super(props);
    const authData = JSON.parse(localStorage.getItem("auth"));
    this.state = {
      phraze: "",
      loaded: false,
      pagination: {
        reportTypes: {},
        providers: [],
        list: [],
        total: 0,
        page: 1,
        limit: 10,
        start: 0,
        end: 10,
        sort: null,
        direction: null,
      },
      conditions: {
        physician_id:
          authData.user.role === "Physician" ? authData.user.id : -1,
        phraze: "",
        report_type_id: 0,
        status: "1", //authData.user.role === "Physician" ? "0" : "",
        date_from: moment().subtract(28, "d"),
        date_to: moment(),
      },
    };
    const { page, limit, sort, direction } = this.state.pagination;
    this.getList({ page, limit, sort, direction });

    this.searchTimeout = null;
  }

  updateForm = (event, name) => {
    const value =
      event !== null && event.target !== undefined ? event.target.value : event;

    const conditions = JSON.parse(JSON.stringify(this.state.conditions));
    conditions.date_from = this.state.conditions.date_from;
    conditions.date_to = this.state.conditions.date_to;
    conditions[name] = value;

    this.setState(
      {
        conditions: conditions,
      },
      () => {
        const { limit, sort, direction } = this.state.pagination;
        if (name === "phraze") {
          this.setSearchTimeout({ page: 1, limit, sort, direction });
        } else {
          this.getList({ page: 1, limit, sort, direction });
        }
      }
    );
  };

  setSearchTimeout = (options) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => this.getList(options), 500);
  };

  getList = (getData) => {
    getData.conditions = {
      phraze: this.state.conditions.phraze,
      report_type_id: this.state.conditions.report_type_id,
      physician_id: this.state.conditions.physician_id,
      status: this.state.conditions.status,
      date_from: this.state.conditions.date_from.format("MM/DD/YYYY"),
      date_to: this.state.conditions.date_to.format("MM/DD/YYYY"),
    };

    axiosInstance()
      .get("/reports.json", { params: getData })
      .then((response) => {
        this.setState({
          pagination: {
            ...response.data,
            conditions: this.state.pagination.conditions,
          },
          loaded: true,
        });
      });
  };

  render = () => {
    return (
      <Container>
        <Helmet>
          <title>NewportX | Manage My Account</title>
        </Helmet>
        {this.state.loaded && (
          <div className={"pt-4"}>
            <div className={"d-flex align-items-center"}>
              <h2 className={"flex-grow-1 font-weight-bold"}>Reports</h2>

              <InputGroup className={"w-50"}>
                <InputGroupAddon addonType="prepend">
                  <span className={"input-group-text"}>
                    <FontAwesomeIcon icon={["fas", "search"]} />
                  </span>
                </InputGroupAddon>
                <Input
                  placeholder={"Search by patient name or patient ECW ID"}
                  className={"input-search"}
                  value={this.state.conditions.phraze}
                  onChange={(event) => {
                    this.updateForm(event, "phraze");
                  }}
                />
              </InputGroup>
            </div>
            <div className={"pt-3 pb-5"}>
              <Row>
                <Col>
                  <div className={"form-group"}>
                    <label className={"font-weight-bold"}>Physician</label>
                    <Select
                      value={{
                        value: this.state.conditions.physician_id,
                        label: this.state.pagination.providers.find(
                          (item) =>
                            item.value === this.state.conditions.physician_id
                        ).label,
                      }}
                      options={this.state.pagination.providers}
                      menuPlacement={"auto"}
                      onChange={(event) => {
                        this.updateForm(event.value, "physician_id");
                      }}
                    />
                  </div>
                </Col>
                <Col>
                  <div className={"form-group"}>
                    <label className="font-weight-bold">Report Type</label>
                    <select
                      className={"form-control"}
                      value={this.state.conditions.report_type_id}
                      onChange={(event) =>
                        this.updateForm(event, "report_type_id")
                      }
                    >
                      <option value={"0"}>All</option>
                      {Object.keys(this.state.pagination.reportTypes).map(
                        (key) => {
                          return (
                            <option value={key} key={"report-type-" + key}>
                              {this.state.pagination.reportTypes[key]}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                </Col>
                <Col>
                  <div className={"form-group"}>
                    <label className="font-weight-bold">Status</label>
                    <select
                      className={"form-control"}
                      value={this.state.conditions.status}
                      onChange={(event) => this.updateForm(event, "status")}
                    >
                      <option value={""}>All</option>
                      <option value={"0"}>In Progress</option>
                      <option value={"1"}>Ready for Review</option>
                      <option value={"2"}>Finalized</option>
                      <option value={"3"}>Sent</option>
                      <option value={"-1"}>Amendment In Progress</option>
                    </select>
                  </div>
                </Col>
                <Col>
                  <div className={"form-group"}>
                    <label className={"font-weight-bold"}>
                      Study Date From
                    </label>
                    <div>
                      <SingleDatePicker
                        date={this.state.conditions.date_from} // momentPropTypes.momentObj or null
                        onDateChange={(startDate) =>
                          this.updateForm(startDate, "date_from")
                        } // PropTypes.func.isRequired
                        focused={this.state.studyDateFocused} // PropTypes.bool
                        block={true}
                        isOutsideRange={() => false}
                        onFocusChange={({ focused }) =>
                          this.setState({ studyDateFocused: focused })
                        } // PropTypes.func.isRequired
                        id="study-date-from" // PropTypes.string.isRequired,
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className={"form-group"}>
                    <label className={"font-weight-bold"}>To</label>
                    <div>
                      <SingleDatePicker
                        date={this.state.conditions.date_to} // momentPropTypes.momentObj or null
                        onDateChange={(startDate) =>
                          this.updateForm(startDate, "date_to")
                        } // PropTypes.func.isRequired
                        focused={this.state.studyDateToFocused} // PropTypes.bool
                        block={true}
                        isOutsideRange={() => false}
                        onFocusChange={({ focused }) =>
                          this.setState({ studyDateToFocused: focused })
                        } // PropTypes.func.isRequired
                        id="study-date-from" // PropTypes.string.isRequired,
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className={"pt-1"}>
                <Col>
                  <Table className={"mt-3"} hover={true} bordered={true}>
                    <thead>
                      <tr>
                        <SortableTh
                          {...this.state.pagination}
                          label={"Patient"}
                          field={"Reports.id"}
                          paginationFunc={this.getList}
                        />
                        <SortableTh
                          {...this.state.pagination}
                          label={"Report #"}
                          field={"Reports.id"}
                          paginationFunc={this.getList}
                        />
                        <SortableTh
                          {...this.state.pagination}
                          label={"Report Type"}
                          field={"Reports.report_type_id"}
                          paginationFunc={this.getList}
                        />
                        <SortableTh
                          {...this.state.pagination}
                          label={"Physician"}
                          field={"Provider.first_name"}
                          paginationFunc={this.getList}
                        />

                        <SortableTh
                          {...this.state.pagination}
                          label={"DOB"}
                          field={"Reports.id"}
                          paginationFunc={this.getList}
                        />
                        <SortableTh
                          {...this.state.pagination}
                          label={"Study Date"}
                          field={"Reports.study_date"}
                          paginationFunc={this.getList}
                        />
                        <SortableTh
                          {...this.state.pagination}
                          label={"Report Status"}
                          field={"Reports.status"}
                          paginationFunc={this.getList}
                        />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.pagination.list.map((item, key) => {
                        return (
                          <tr
                            key={"report-" + key}
                            onClick={() =>
                              this.props.history.push(`/reports/${item.id}`)
                            }
                            className={"pointer"}
                          >
                            <td>
                              {item.patient.first_name} {item.patient.last_name}
                            </td>

                            <td>{item.id}</td>
                            <td>{item.report_type.name}</td>
                            <td>
                              {item.physician !== null
                                ? `${item.physician.first_name} ${item.physician.last_name}`
                                : "---"}
                            </td>
                            <td>---</td>
                            <td>
                              {moment(item.study_date * 1000).format(
                                "MM/DD/YYYY"
                              )}
                            </td>
                            <td>
                              {item.status === 1 && (
                                <Badge color={"primary"}>
                                  Ready for Review
                                </Badge>
                              )}
                              {item.status === 0 && (
                                <Badge color={"warning"}>In Progress</Badge>
                              )}
                              {item.status === 2 && (
                                <Badge color={"success"}>Finalized</Badge>
                              )}
                              {item.status === 3 && (
                                <Badge color={"dark"}>Sent</Badge>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <PaginationPanel
                {...this.state.pagination}
                list={null}
                paginationFunc={this.getList}
                phraze={""}
              />
            </div>
          </div>
        )}
      </Container>
    );
  };
}

export default withRouter(Dashboard);
