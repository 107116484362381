import React from "react";
import { Row, Col } from "reactstrap";
import LabelInput from "components/Elements/LabelInput/LabelInput";
import InputGroup from "components/Elements/InputGroup/InputGroup";
import Select from "react-select";
import axiosInstance from "axios-instance";

const fieldValidator = {
  rest_dose_amount: [],
  stress_dose_amount: [],
  stress_test_percentage: [],
  stress_test_length: [],
  stress_dose_type_id: [],
  lexicon_infusion_1: [],
  lexicon_infusion_2: [],
  administration_location_id: [],
  is_custom_administration: [],
  custom_administration_location: [],
  initials: [],
  limit_exceeded: [],
  /*
  lexiscan_infusion: [],
  
  rest_dose_type_id: [],
  stress_dose_statement: [],
  
  
  stress_test_protocol_id: [],
  stress_test_length: [],
  stress_test_heart_rate: [],
  
  ,
  rest_ecg_1_id: [],
  rest_ecg_2_id: [],
  exercise_response_id: [],
  exercise_response_experience_id: [],
  arrhythmia_id: [],
  low_level_exercise: [],*/
};

const fieldLists = {
  administration_location_id: "administration_locations",
  rest_dose_type_id: "rest_dose_types",
  stress_dose_type_id: "stress_dose_types",
  stress_test_protocol_id: "stress_test_protocols",
  rest_ecg_1_id: "rest_ecgs_1",
  rest_ecg_2_id: "rest_ecgs_2",
  exercise_response_id: "exercise_responses",
  exercise_response_experience_id: "exercise_response_experiences",
  arrhythmia_id: "arrhythmias",
};

class PetProcedureForm extends React.Component {
  constructor(props) {
    super(props);
    const fields = {};

    Object.keys(fieldValidator).forEach((key) => {
      fields[key] = {
        value:
          props.entity !== null &&
          props.entity[key] !== undefined &&
          props.entity[key] !== null
            ? props.entity[key]
            : "",
        visited: false,
        error: false,
      };
    });

    Object.keys(fieldLists).forEach((item, key) => {
      if (
        props.entity !== null &&
        props.entity[item] !== undefined &&
        props.entity[item] !== "" &&
        props.entity[item] !== null
      ) {
        fields[item].value = {
          value: fields[item].value,
          label: props[fieldLists[item]].find(
            (x) => x.value === props.entity[item]
          ).label,
        };
      }
    });

    this.state = { ...fields };
  }

  componentDidMount() {
    const { childRef } = this.props;
    if (childRef !== undefined) {
      childRef(this);
    }
  }
  componentWillUnmount() {
    const { childRef } = this.props;
    if (childRef !== undefined) {
      childRef(undefined);
    }
  }

  updateForm = (event, name) => {
    const value =
      event !== null && event.target !== undefined ? event.target.value : event;

    const inputData = {
      value: value,
      visited: true,
      error: false,
    };

    for (let i = 0; i < fieldValidator[name].length; i++) {
      const validationMsg = fieldValidator[name][i](inputData.value);
      if (validationMsg !== undefined) {
        inputData.error = validationMsg;
        break;
      }
    }

    this.setState({
      [name]: inputData,
    });
  };

  onSubmit = (callback) => {
    const formData = JSON.parse(JSON.stringify(this.state));
    const postData = {};
    let isValid = true;

    Object.keys(fieldValidator).forEach((key) => {
      postData[key] = formData[key].value;
      for (let i = 0; i < fieldValidator[key].length; i++) {
        const validationMsg = fieldValidator[key][i](formData[key].value);
        formData[key].visited = true;
        if (validationMsg !== undefined) {
          formData[key].error = validationMsg;
          isValid = false;
          break;
        } else {
          formData[key].error = false;
        }
      }
    });

    this.setState({ ...formData, successMessage: false, errorMessage: false });

    if (isValid) {
      axiosInstance()
        .post(`/reports/save-procedure/${this.props.reportId}.json`, postData)
        .then((response) => {
          callback(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return false;
  };

  render = () => {
    return (
      <React.Fragment>
        <div className={"font-weight-bold pb-1"}>Rest Dose</div>
        <div className={"d-flex align-items-center pb-3"}>
          <div>
            <InputGroup
              append={"mCI"}
              type={"text"}
              required={false}
              {...this.state.rest_dose_amount}
              onChange={(event) => this.updateForm(event, "rest_dose_amount")}
            />
          </div>
          <div className={"flex-grow-1 pl-3"}>
            of Rubidium 82 was injected during resting imaging.
          </div>
        </div>
        <div className={"font-weight-bold pb-1"}>Stress Dose</div>
        <div className={"d-flex align-items-center pb-3"}>
          <div>
            <InputGroup
              append={"mCI"}
              type={"text"}
              required={false}
              {...this.state.stress_dose_amount}
              onChange={(event) => this.updateForm(event, "stress_dose_amount")}
            />
          </div>
          <div className={"flex-grow-1 pl-3"}>
            of Rubidium 82 was injected during stress imaging.
          </div>
        </div>

        <div className={"font-weight-bold pb-1"}>Stress Test</div>
        <div className={"d-flex align-items-center pb-3"}>
          <div>
            <InputGroup
              append={"mg of"}
              type={"text"}
              required={false}
              {...this.state.stress_test_percentage}
              onChange={(event) =>
                this.updateForm(event, "stress_test_percentage")
              }
            />
          </div>
          <div className={"px-2"} style={{ width: "200px" }}>
            <Select
              value={this.state.stress_dose_type_id.value}
              options={this.props.stress_dose_types}
              menuPlacement={"auto"}
              isClearable={true}
              onChange={(event) => {
                this.updateForm(event, "stress_dose_type_id");
              }}
            />
          </div>
          <div className={"flex-grow-1"}>was injected during stress test.</div>
        </div>

        <div className={"font-weight-bold pb-1"}>Post Stress Test</div>
        <div className={"d-flex align-items-center pb-3"}>
          <div>
            <InputGroup
              append={"mg"}
              type={"text"}
              required={false}
              {...this.state.stress_test_length}
              onChange={(event) => this.updateForm(event, "stress_test_length")}
            />
          </div>
          <div className={"flex-grow-1 pl-2"}>
            {" "}
            of Aminophylline was injected post stress test.
          </div>
        </div>

        <div className={"font-weight-bold pb-1"}>Lexicon Infusion</div>
        <div className={"d-flex align-items-center pb-2"}>
          <div>The patient was noted to have</div>
          <div className={"px-2"}>
            <InputGroup
              type={"text"}
              required={false}
              {...this.state.lexicon_infusion_1}
              onChange={(event) => this.updateForm(event, "lexicon_infusion_1")}
            />
          </div>
          <div className={"flex-grow-1"}>during Lexiscan infusion.</div>
        </div>
        <div className={"d-flex align-items-center pb-3"}>
          <div>The patient was noted to have</div>
          <div className={"px-2"}>
            <InputGroup
              type={"text"}
              required={false}
              {...this.state.lexicon_infusion_2}
              onChange={(event) => this.updateForm(event, "lexicon_infusion_2")}
            />
          </div>
          <div className={"flex-grow-1"}>during Lexiscan infusion.</div>
        </div>

        <Row>
          <Col>
            <div className={"form-group"}>
              <label className={"font-weight-bold"}>Administration</label>
              <Select
                value={this.state.administration_location_id.value}
                options={this.props.administration_locations}
                menuPlacement={"auto"}
                isClearable={true}
                isDisabled={this.state.is_custom_administration.value === 1}
                onChange={(event) => {
                  this.updateForm(event, "administration_location_id");
                }}
              />
            </div>
          </Col>
          <div
            className={
              "flex-shrink-1 px-0 pt-4 font-weight-bold text-center mt-1"
            }
          >
            <label className={"font-weight-bold pt-2"}>or</label>
          </div>
          <Col>
            <div className="checkbox mb-0 text-left">
              <label className={"font-weight-bold"}>
                <input
                  type={"checkbox"}
                  checked={this.state.is_custom_administration.value === 1}
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 1 : 0,
                      "is_custom_administration"
                    )
                  }
                />{" "}
                Custom Administration Location
              </label>
            </div>
            <LabelInput
              type={"text"}
              required={true}
              disabled={this.state.is_custom_administration.value !== 1}
              {...this.state.custom_administration_location}
              onChange={(event) =>
                this.updateForm(event, "custom_administration_location")
              }
            />
          </Col>
          <div
            className={
              "flex-shrink-1 px-0 pt-4 font-weight-bold text-center mt-1"
            }
          >
            <label className={"font-weight-bold pt-2"}>by</label>
          </div>
          <Col>
            <LabelInput
              name={"Initials"}
              type={"text"}
              required={false}
              {...this.state.initials}
              onChange={(event) => this.updateForm(event, "initials")}
            />
          </Col>
        </Row>
        <div>
          <label className={"font-weight-bold"}>&nbsp;</label>
          <label>
            <input
              type={"checkbox"}
              checked={this.state.limit_exceeded.value === 1}
              onChange={(event) =>
                this.updateForm(
                  event.currentTarget.checked ? 1 : 0,
                  "limit_exceeded"
                )
              }
            />{" "}
            Attenuation corrected images were obtained.
          </label>
        </div>
        {/*
        <div>
          <label className={"font-weight-bold"}>Stress Dose</label>
          <div className={"d-flex align-items-center pb-1"}>
            <div className="radio mr-2 text-left">
              <label>
                <input
                  type={"radio"}
                  name={"stress_dose_statement"}
                  checked={this.state["stress_dose_statement"].value === 0}
                  onChange={(event) =>
                    this.updateForm(0, `stress_dose_statement`)
                  }
                />{" "}
                <span>0.4mg regadenoson followed by</span>
              </label>
            </div>
            <div>
              <InputGroup
                append={"mCI"}
                type={"text"}
                required={false}
                disabled={this.state["stress_dose_statement"].value === 1}
                {...this.state.stress_dose_amount}
                value={
                  this.state["stress_dose_statement"].value === 1
                    ? ""
                    : this.state.stress_dose_amount.value
                }
                onChange={(event) =>
                  this.updateForm(event, "stress_dose_amount")
                }
              />
            </div>
            <div className={"px-2"}>mCi of</div>
            <div style={{ width: "150px" }}>
              <Select
                value={
                  this.state["stress_dose_statement"].value === 1
                    ? null
                    : this.state.stress_dose_type_id.value
                }
                options={this.props.stress_dose_types}
                isDisabled={this.state["stress_dose_statement"].value === 1}
                menuPlacement={"auto"}
                isClearable={true}
                onChange={(event) => {
                  this.updateForm(event, "stress_dose_type_id");
                }}
              />
            </div>
            <div className={"pl-2"}>four minutes later</div>
          </div>
          <div className={"d-flex align-items-center pb-2"}>
            <div className="radio mr-2 text-left">
              <label>
                <input
                  type={"radio"}
                  name={"stress_dose_statement"}
                  checked={this.state["stress_dose_statement"].value === 1}
                  onChange={(event) =>
                    this.updateForm(1, `stress_dose_statement`)
                  }
                />{" "}
                <span>
                  adenosine at 140mcg/kg for a total of four minutes receiving
                </span>
              </label>
            </div>
            <div>
              <InputGroup
                append={"mCI"}
                type={"text"}
                disabled={this.state["stress_dose_statement"].value === 0}
                required={false}
                {...this.state.stress_dose_amount}
                value={
                  this.state["stress_dose_statement"].value === 0
                    ? ""
                    : this.state.stress_dose_amount.value
                }
                onChange={(event) =>
                  this.updateForm(event, "stress_dose_amount")
                }
              />
            </div>
            <div className={"px-2"}>mCi of</div>
            <div style={{ width: "150px" }}>
              <Select
                value={
                  this.state["stress_dose_statement"].value === 0
                    ? null
                    : this.state.stress_dose_type_id.value
                }
                options={this.props.stress_dose_types}
                isDisabled={this.state["stress_dose_statement"].value === 0}
                menuPlacement={"auto"}
                isClearable={true}
                onChange={(event) => {
                  this.updateForm(event, "stress_dose_type_id");
                }}
              />
            </div>
            <div className={"pl-2"}>after two minutes</div>
          </div>
        </div>

        <div className={"pb-2"}>
          <label className={"font-weight-bold"}>Low level exercise</label>
          <div className={"d-flex align-items-center"}>
            <span>Low level exercise</span>
            <div className={"px-2"} style={{ width: "180px" }}>
              <Select
                value={
                  this.state.low_level_exercise.value.length > 0
                    ? {
                        value: this.state.low_level_exercise.value,
                        label: this.state.low_level_exercise.value,
                      }
                    : null
                }
                options={[
                  { value: "was", label: "was" },
                  { value: "was not", label: "was not" },
                ]}
                menuPlacement={"auto"}
                isClearable={true}
                onChange={(event) => {
                  this.updateForm(
                    event !== null ? event.label : "",
                    "low_level_exercise"
                  );
                }}
              />
            </div>
            <span>.</span>
          </div>
        </div>

        <Row>
          <Col xs={4}>
            <div className={"form-group"}>
              <label className={"font-weight-bold"}>Rest ECG</label>
              <Select
                value={this.state.rest_ecg_1_id.value}
                options={this.props.rest_ecgs_1}
                menuPlacement={"auto"}
                isClearable={true}
                onChange={(event) => {
                  this.updateForm(event, "rest_ecg_1_id");
                }}
              />
            </div>
          </Col>
          <Col xs={4}>
            <div className={"form-group"}>
              <label className={"font-weight-bold"}>&nbsp;</label>
              <Select
                value={this.state.rest_ecg_2_id.value}
                options={this.props.rest_ecgs_2}
                menuPlacement={"auto"}
                isClearable={true}
                onChange={(event) => {
                  this.updateForm(event, "rest_ecg_2_id");
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={"form-group"}>
              <label className={"font-weight-bold"}>Stress Response</label>
              <Select
                value={this.state.exercise_response_id.value}
                options={this.props.exercise_responses}
                menuPlacement={"auto"}
                isClearable={true}
                onChange={(event) => {
                  this.updateForm(event, "exercise_response_id");
                }}
              />
            </div>
          </Col>
          <div
            className={
              "flex-shrink-1 px-0 pt-4 font-weight-bold text-center mt-1"
            }
          >
            <label className={"font-weight-bold pt-2"}>
              and the patient experienced
            </label>
          </div>
          <Col>
            <div className={"form-group"}>
              <label className={"font-weight-bold"}>&nbsp;</label>
              <Select
                value={this.state.exercise_response_experience_id.value}
                options={this.props.exercise_response_experiences}
                menuPlacement={"auto"}
                isClearable={true}
                onChange={(event) => {
                  this.updateForm(event, "exercise_response_experience_id");
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <div className={"form-group"}>
              <label className={"font-weight-bold"}>Arrhythmias</label>
              <Select
                value={this.state.arrhythmia_id.value}
                options={this.props.arrhythmias}
                menuPlacement={"auto"}
                isClearable={true}
                onChange={(event) => {
                  this.updateForm(event, "arrhythmia_id");
                }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={"form-group"}>
              <label className={"font-weight-bold"}>Administration</label>
              <Select
                value={this.state.administration_location_id.value}
                options={this.props.administration_locations}
                menuPlacement={"auto"}
                isClearable={true}
                isDisabled={this.state.is_custom_administration.value === 1}
                onChange={(event) => {
                  this.updateForm(event, "administration_location_id");
                }}
              />
            </div>
          </Col>
          <div
            className={
              "flex-shrink-1 px-0 pt-4 font-weight-bold text-center mt-1"
            }
          >
            <label className={"font-weight-bold pt-2"}>or</label>
          </div>
          <Col>
            <div className="checkbox mb-0 text-left">
              <label className={"font-weight-bold"}>
                <input
                  type={"checkbox"}
                  checked={this.state.is_custom_administration.value === 1}
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 1 : 0,
                      "is_custom_administration"
                    )
                  }
                />{" "}
                Custom Administration Location
              </label>
            </div>
            <LabelInput
              type={"text"}
              required={true}
              disabled={this.state.is_custom_administration.value !== 1}
              {...this.state.custom_administration_location}
              onChange={(event) =>
                this.updateForm(event, "custom_administration_location")
              }
            />
          </Col>
          <div
            className={
              "flex-shrink-1 px-0 pt-4 font-weight-bold text-center mt-1"
            }
          >
            <label className={"font-weight-bold pt-2"}>by</label>
          </div>
          <Col>
            <LabelInput
              name={"Initials"}
              type={"text"}
              required={false}
              {...this.state.initials}
              onChange={(event) => this.updateForm(event, "initials")}
            />
          </Col>
        </Row>
         */}
      </React.Fragment>
    );
  };
}
export default PetProcedureForm;
