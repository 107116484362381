import React, { Component } from "react";
import { Chart, PieController, ArcElement } from "chart.js";
import Style from "./AreaChart.module.scss";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
Chart.register(PieController, ArcElement);

class AreaChart extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();

    this.state = {
      activeDataset: { 0: [], 1: [], 2: [], 3: [] },
    };
  }

  initChart(chartRef) {
    const myChartRef = chartRef.current.getContext("2d");

    const backgroundColors = [
      "rgba(255,255,255,0.1)",
      "rgba(255,255,255,0.1)",
      "rgba(255,255,255,0.1)",
      "rgba(255,255,255,0.1)",
      "rgba(255,255,255,0.1)",
      "rgba(255,255,255,0.1)",
      "rgba(255,255,255,0.1)",
    ];

    const myPieChart = new Chart(myChartRef, {
      type: "pie",
      data: {
        //Bring in data
        datasets: [
          {
            borderColor: "#000000",
            backgroundColor: backgroundColors,
            hoverBackgroundColor: "rgba(0,0,0,0.1)",
            data: [60, 60, 60, 60, 60, 60].reverse(),
            rotation: 30,
          },
          {
            borderColor: "#000000",
            backgroundColor: backgroundColors,
            hoverBackgroundColor: "rgba(0,0,0,0.2)",
            rotation: 30,
            data: [60, 60, 60, 60, 60, 60].reverse(),
          },
          {
            borderColor: "#000000",
            backgroundColor: backgroundColors,
            hoverBackgroundColor: "rgba(0,0,0,0.3)",
            data: [90, 90, 90, 90].reverse(),
            rotation: 45,
          },
          {
            data: [360],
            borderColor: "#FFFFFF",
            hoverBorderColor: "rgba(0,0,0,0.4)",
            borderWidth: 0,
            backgroundColor: ["rgba(255,255,255,0.1)"],
            hoverBackgroundColor: "rgba(0,0,0,0.4)",
          },
        ],
      },
      options: {
        onClick: (evt, elements) => {
          let datasetIndex;

          /*for (
            datasetIndex = 0;
            datasetIndex < myPieChart.data.datasets.length;
            ++datasetIndex
          ) {
            dataset = myPieChart.data.datasets[datasetIndex];
            dataset.backgroundColor = backgroundColors.slice();
          }
          myPieChart.update();*/

          if (elements.length) {
            let index = elements[0].index;
            datasetIndex = elements[0].datasetIndex;

            // Reset old state
            const activeDataset = { ...this.state.activeDataset };
            const existingIndex = activeDataset[datasetIndex].findIndex(
              (item) => item === index
            );
            if (existingIndex === -1) {
              activeDataset[datasetIndex].push(index);
            } else {
              activeDataset[datasetIndex] = activeDataset[datasetIndex].filter(
                (item) => item !== index
              );
            }

            this.setState(
              {
                activeDataset: activeDataset,
              },
              () => {
                for (const datasetIndex in this.state.activeDataset) {
                  myPieChart.data.datasets[
                    datasetIndex
                  ].backgroundColor = backgroundColors.slice();
                  this.state.activeDataset[datasetIndex].forEach(
                    (item, key) => {
                      myPieChart.data.datasets[datasetIndex].backgroundColor[
                        item
                      ] = "rgba(0,0,0,0.4)";
                    }
                  );
                }
                myPieChart.update();
              }
            );
          } /* else {
            this.setState({
              index: null,
              datasetIndex: null,
            });
          }*/
        },
        //Customize chart options
      },
    });

    if (
      this.props.activeChartElement !== undefined &&
      this.props.activeChartElement !== null
    ) {
      let elements = this.props.activeChartElement.replace(" and ", ", ");
      elements = elements.split(", ");

      const activeDataset = { 0: [], 1: [], 2: [], 3: [] };

      elements.forEach((value) => {
        let tmpIndex = this.props.findings.findIndex((x) => x.label === value);
        if (tmpIndex >= 0) {
          let index;
          let datasetIndex = 0;
          if (tmpIndex < 6) {
            index = 5 - tmpIndex;
          } else if (tmpIndex < 12) {
            index = 11 - tmpIndex;
            datasetIndex = 1;
          } else if (tmpIndex < 16) {
            index = 15 - tmpIndex;
            datasetIndex = 2;
          } else {
            datasetIndex = 3;
            index = 0;
          }
          activeDataset[datasetIndex].push(index);
        }
      });

      this.setState(
        {
          activeDataset: activeDataset,
        },
        () => {
          for (const datasetIndex in this.state.activeDataset) {
            myPieChart.data.datasets[
              datasetIndex
            ].backgroundColor = backgroundColors.slice();
            this.state.activeDataset[datasetIndex].forEach((item, key) => {
              myPieChart.data.datasets[datasetIndex].backgroundColor[item] =
                "rgba(0,0,0,0.4)";
            });
          }
          myPieChart.update();
        }
      );
    }
  }

  render = () => {
    return (
      <Modal
        fade={false}
        isOpen={this.props.isActive}
        toggle={this.props.toggle}
        onOpened={() => {
          this.initChart(this.chartRef);
        }}
      >
        <ModalHeader toggle={this.props.toggle}>{"Select Area"}</ModalHeader>
        <ModalBody className={"p-4"}>
          <div className={"position-relative"}>
            <div>
              {/* prettier-ignore */}
              <span className={Style.number} style={{left: "215px", top: "5px"}}>1</span>
              {/* prettier-ignore */}
              <span className={Style.number} style={{left: "48px", top: "100px"}}>2</span>
              {/* prettier-ignore */}
              <span className={Style.number} style={{left: "48px", top: "295px"}}>3</span>
              {/* prettier-ignore */}
              <span className={Style.number} style={{left: "215px", top: "397px"}}>4</span>
              {/* prettier-ignore */}
              <span className={Style.number} style={{right: "48px", top: "295px"}}>5</span>
              {/* prettier-ignore */}
              <span className={Style.number} style={{right: "48px", top: "100px"}}>6</span>

              {/* prettier-ignore */}
              <span className={Style.number} style={{left: "215px", top: "62px"}}>7</span>
              {/* prettier-ignore */}
              <span className={Style.number} style={{left: "91px", top: "135px"}}>8</span>
              {/* prettier-ignore */}
              <span className={Style.number} style={{left: "91px", top: "260px"}}>9</span>
              {/* prettier-ignore */}
              <span className={Style.number} style={{left: "210px", top: "340px"}}>10</span>
              {/* prettier-ignore */}
              <span className={Style.number} style={{right: "87px", top: "260px"}}>11</span>
              {/* prettier-ignore */}
              <span className={Style.number} style={{right: "87px", top: "135px"}}>12</span>

              {/* prettier-ignore */}
              <span className={Style.number} style={{left: "210px", top: "119px"}}>13</span>
              {/* prettier-ignore */}
              <span className={Style.number} style={{left: "130px", top: "200px"}}>14</span>
              {/* prettier-ignore */}
              <span className={Style.number} style={{left: "210px", top: "283px"}}>15</span>
              {/* prettier-ignore */}
              <span className={Style.number} style={{right: "130px", top: "200px"}}>16</span>
              {/* prettier-ignore */}
              <span className={Style.number} style={{left: "210px", top: "200px"}}>17</span>

              <canvas
                id="myChart"
                ref={this.chartRef}
                style={{ zIndex: 1, position: "relative", cursor: "pointer" }}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={"text-right justify-content-end"}>
          <Button color="light" onClick={this.props.toggle}>
            Cancel
          </Button>
          {this.props.submitting !== undefined &&
          this.props.submitting === true ? (
            <Button
              color={
                this.props.confirmClass !== undefined
                  ? this.props.confirmClass
                  : "danger"
              }
              className="px-5"
              type="button"
              disabled
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              &nbsp;&nbsp; Loading...
            </Button>
          ) : (
            <Button
              color={"primary"}
              onClick={() => {
                this.props.confirmAction({ ...this.state.activeDataset });
              }}
            >
              {this.props.confirmLabel}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  };
}

export default AreaChart;
