import React from 'react';
import { Row, Col, InputGroupAddon, InputGroup } from 'reactstrap';
import ReactPaginate from 'react-paginate';

export default class extends React.Component {
  render = () => {
    const { page, limit, sort, direction, phraze } = this.props;
    const paginationParams = { page, limit, sort, direction, phraze };
    return (
      <Row className={'pt-2 font-sm'}>
        <Col className={'col-3'}>
          <InputGroup size={'sm'}>
            <InputGroupAddon addonType="prepend">
              Records per page
            </InputGroupAddon>
            <select
              className={'form-control'}
              value={this.props.limit}
              onChange={e =>
                this.props.paginationFunc({
                  ...paginationParams,
                  page: 1,
                  limit: e.currentTarget.value
                })
              }
            >
              <option>10</option>
              <option>20</option>
              <option>50</option>
              <option>100</option>
            </select>
          </InputGroup>
        </Col>
        <Col className={'col-6 text-center'}>
          <nav className={'d-inline-block'}>
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.props.total / this.props.limit}
              forcePage={this.props.page - 1}
              marginPagesDisplayed={2}
              pageRangeDisplayed={4}
              onPageChange={data => {
                this.props.paginationFunc({
                  ...paginationParams,
                  page: data.selected + 1
                });
              }}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link pointer'}
              previousClassName={'page-item'}
              nextClassName={
                'page-item ' +
                (Math.ceil(this.props.total / this.props.limit) ===
                this.props.page
                  ? 'disabled'
                  : '')
              }
              previousLinkClassName={'page-link pointer'}
              nextLinkClassName={'page-link pointer'}
            />
          </nav>
        </Col>
        <Col className={'text-right col-3 pt-1'}>
          Showing records {this.props.start} to {this.props.end} of{' '}
          {this.props.total}
        </Col>
      </Row>
    );
  };
}
