import React from "react";
import { Router } from "./Router";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "store/actions/index";
import "./App.module.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserCircle,
  faSearch,
  faChevronUp,
  faChevronDown,
  faTrashAlt,
  faExclamationCircle,
  faEllipsisV,
  faLock,
  faEdit,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faUserCircle,
  faSearch,
  faChevronUp,
  faChevronDown,
  faTrashAlt,
  faExclamationCircle,
  faEllipsisV,
  faLock,
  faEdit,
  faTimes
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageWidth: window.innerWidth,
      pageHeight: window.innerHeight,
    };
  }

  updateDimensions = () => {
    if (this.state.pageWidth !== window.innerWidth) {
      this.setState({
        pageWidth: window.innerWidth,
        pageHeight: window.innerHeight,
      });
    }
  };

  componentDidMount = () => {
    this.props.onTryAutoSignup();
    window.addEventListener("resize", this.updateDimensions);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions);
    window.removeEventListener("click", this.sessionHandle);
  };

  render = () => {
    const isMobile = window.innerWidth < 992;
    const isMedium = this.state.pageWidth < 1200;

    const authData = localStorage.getItem("auth");
    let isLogged = true;
    if (authData) {
      const validTo = localStorage.getItem("validTo");
      if (
        validTo === null ||
        parseInt(validTo, 10) < Math.floor(Date.now() / 1000)
      ) {
        isLogged = false;
      }
    } else {
      isLogged = false;
    }
    return (
      <Router
        onLogout={this.props.onLogout}
        isMobile={isMobile}
        isMedium={isMedium}
        isManager={this.props.isManager}
        isFindingsDatabaseAllow={this.props.isFindingsDatabaseAllow}
        isLogged={isLogged}
      />
    );
  };
}

const mapStateToProps = (state) => {
  return {
    isLogged: state.auth.token !== null && state.auth.token.length > 0,
    isManager: state.auth.token !== null && state.auth.role === "Manager",
    isFindingsDatabaseAllow:
      state.auth.token !== null && state.auth.echo_findings_access === 1,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onLogout: () => dispatch(actions.logout()),
    onSessionExpiry: () => dispatch(actions.logout()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
