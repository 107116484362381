import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import EchoForm from "components/Elements/Reports/EchoForm";
import ExerciseForm from "components/Elements/Reports/ExerciseForm";
import axiosInstance from "axios-instance";

class EditReport extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();

    this.state = {
      laoded: false,
    };
    this.loadOptions();
  }

  onSave = (event) => {
    this.child.onSubmit(event);
  };

  loadOptions = () => {
    axiosInstance()
      .get(`/reports/load-options/${this.props.entity.id}.json`)
      .then((response) => {
        this.setState({
          loaded: true,
          reportTypes: response.data.reportTypes,
          heartRhythms: response.data.heartRhythms,
          listPhysicians: response.data.physicians,
          listTechnician: response.data.technicians,
          listProviders: response.data.providers,
        });
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  render = () => {
    return (
      <Modal
        isOpen={this.props.isActive}
        toggle={this.props.toggle}
        className={this.props.className}
        size={"lg"}
        backdrop={"static"}
      >
        <ModalHeader toggle={this.props.toggle}>
          {this.props.entity.id !== undefined ? "Edit Report" : "New Report"}
        </ModalHeader>
        <div className={"position-relative"}>
          {this.state.loaded && (
            <ModalBody>
              {parseInt(this.props.entity.report_type_id) === 1 &&
                this.props.isActive && (
                  <EchoForm
                    {...this.props.entity}
                    childRef={(ref) => (this.child = ref)}
                    onSubmit={() => {
                      this.props.getEntity();
                      this.props.toggle();
                    }}
                    showSubmit={false}
                    {...this.state}
                    report_type_id={1}
                  />
                )}
              {[2, 3, 4, 5, 6].includes(
                parseInt(this.props.entity.report_type_id)
              ) && (
                <ExerciseForm
                  {...this.props.entity}
                  childRef={(ref) => (this.child = ref)}
                  onSubmit={() => {
                    this.props.getEntity();
                    this.props.toggle();
                  }}
                  showSubmit={false}
                  {...this.state}
                  report_type_id={this.props.entity.report_type_id}
                  technicianLabel={
                    [2, 3, 4, 5].includes(
                      parseInt(this.props.entity.report_type_id)
                    )
                      ? "Nuclear Technician"
                      : "Technician"
                  }
                />
              )}
            </ModalBody>
          )}
          <ModalFooter className={"py-4"}>
            <Button
              color="light"
              className={"btn px-3"}
              type={"button"}
              onClick={this.props.toggle}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              className={"btn px-3"}
              type={"submit"}
              onClick={this.onSave}
            >
              Save
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    );
  };
}

export default EditReport;
