import React from "react";

export const displayValue = (value) => {
  if (value === "-") {
    return "";
  }
  return (
    <React.Fragment>
      {value !== undefined &&
      value !== null &&
      (value.length > 0 || value.name !== undefined) ? (
        <span className={"font-weight-bold"}>
          {value.name !== undefined ? value.name : value}
        </span>
      ) : (
        "---"
      )}
    </React.Fragment>
  );
};

export const regularValue = (value) => {
  if (value === "-") {
    return "";
  }
  return (
    <React.Fragment>
      {value !== undefined &&
      value !== null &&
      (value.length > 0 || value.name !== undefined) ? (
        <span>{value.name !== undefined ? value.name : value}</span>
      ) : (
        "---"
      )}
    </React.Fragment>
  );
};

export const isBinary = (value, divider) => {
  const bitwise = value & divider;
  return value > 0 && bitwise > 0;
};
