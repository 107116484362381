import React from "react";
import { Card, CardBody, CardHeader, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

export default (props) => {
  return (
    <Card>
      <CardHeader>
        <div className={"pointer"} onClick={props.toggle}>
          Report History
          {props.show ? (
            <FontAwesomeIcon className={"ml-3"} icon={["fas", "chevron-up"]} />
          ) : (
            <FontAwesomeIcon
              className={"ml-3"}
              icon={["fas", "chevron-down"]}
            />
          )}
        </div>
      </CardHeader>
      {props.show && (
        <CardBody>
          <ul>
            {props.entities.map((item) => {
              let time = moment(item.created * 1000).format(
                "dddd, MM/DD/YYYY hh:mmA"
              );
              return (
                <li>
                  {time} - {item.body}{" "}
                  {item.fax_token !== null &&
                    item.body.indexOf("[Failure]") !== -1 && (
                      <React.Fragment>
                        <FontAwesomeIcon
                          className={"ml-2 mr-3"}
                          icon={["fas", "exclamation-circle"]}
                          id={"tooltip-box"}
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="tooltip-box"
                        >
                          <span className={"pb-2 h6"}>
                            Please log on to your Innoport Fax Services account
                            to view more details.
                          </span>
                        </UncontrolledTooltip>
                      </React.Fragment>
                    )}
                </li>
              );
            })}
          </ul>
        </CardBody>
      )}
    </Card>
  );
};
