const historyFields = {
  smoke: [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
    { value: "Previous", label: "Previous" },
  ],
  claudication: [
    { value: "Left", label: "Left" },
    { value: "Right", label: "Right" },
  ],
  gangrene: [
    { value: "Yes", label: "Yes" },
    { value: "None", label: "None" },
  ],
  prev_vasc_surg: [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ],
  diabetic: [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ],
  skin_color_change: [
    { value: "Yes", label: "Yes" },
    { value: "None", label: "None" },
  ],
  hyperlipidemia: [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ],
  prev_angioplasty: [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ],
  rest_pain: [{ value: "None", label: "None" }],
  vascular_ulcers: [{ value: "None", label: "None" }],
  hypertension: [{ value: "No", label: "No" }],
};

export { historyFields };
