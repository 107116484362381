import React, { Component } from "react";
import { Table, Input } from "reactstrap";
import axiosInstance from "axios-instance";

class Measurements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };

    this.loadMeasurements(props.entityId);
  }

  loadMeasurements = (entityId) => {
    axiosInstance()
      .get(`/reports/load-measurements/${entityId}.json`)
      .then((response) => {
        this.setState({
          measurementList: response.data.measurementList,
          measurements: response.data.measurements,
          loaded: true,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  updateForm = (id, value) => {
    const measurements = JSON.parse(JSON.stringify(this.state.measurements));
    measurements[id] = value;

    this.setState({
      measurements: measurements,
    });
  };

  saveForm = () => {
    const measurements = JSON.parse(JSON.stringify(this.state.measurements));

    const formData = {};
    Object.keys(measurements).map((index) => {
      if (measurements[index] !== null && measurements[index].length > 0) {
        if (!isNaN(measurements[index])) {
          formData[index] = parseFloat(measurements[index]).toFixed(1);
        } else {
          formData[index] = measurements[index];
        }
      }

      return true;
    });

    this.setState({
      measurements: formData,
    });
    axiosInstance()
      .post(`/reports/save-measurements/${this.props.entityId}.json`, {
        measurements: formData,
      })
      .then((response) => {})
      .catch((err) => {
        alert(err);
      });
  };

  render = () => {
    const { isLocked } = this.props;
    return (
      <div className={"d-flex"}>
        {this.state.loaded && (
          <React.Fragment>
            {Object.keys(this.state.measurementList).map((key) => {
              return (
                <div className={"w-50"} key={"mesurements-table-" + key}>
                  <Table striped={true} size={"sm"}>
                    <thead>
                      <tr>
                        <th colSpan={2}>{key}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(this.state.measurementList[key]).map(
                        (item) => {
                          return (
                            <tr key={"measurement-" + item}>
                              <td>
                                {this.state.measurementList[key][item].name}
                              </td>
                              <td className={"w-25"}>
                                <Input
                                  value={
                                    this.state.measurements[item] !==
                                      undefined &&
                                    this.state.measurements[item] !== null
                                      ? this.state.measurements[item]
                                      : ""
                                  }
                                  className={
                                    "text-right " +
                                    (this.state.activeInput !== item
                                      ? "border-0 bg-transparent"
                                      : "")
                                  }
                                  readOnly={isLocked}
                                  bsSize={"sm"}
                                  type={
                                    this.state.measurementList[key][item]
                                      .is_text === 1
                                      ? "text"
                                      : "number"
                                  }
                                  step={"0.1"}
                                  placeholder={"---"}
                                  onBlur={() => {
                                    this.setState(
                                      {
                                        activeInput: null,
                                      },
                                      this.saveForm
                                    );
                                  }}
                                  onFocus={() =>
                                    this.setState({
                                      activeInput: item,
                                    })
                                  }
                                  onChange={(event) =>
                                    this.updateForm(item, event.target.value)
                                  }
                                />
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </div>
              );
            })}
          </React.Fragment>
        )}
      </div>
    );
  };
}

export default Measurements;
