import React from "react";
import { Row, Col } from "reactstrap";
import LabelInput from "components/Elements/LabelInput/LabelInput";
import InputGroup from "components/Elements/InputGroup/InputGroup";
import Select from "react-select";
import axiosInstance from "axios-instance";
import { isBinary } from "functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const fieldValidator = {
  administration_location_id: [],
  is_custom_administration: [],
  custom_administration_location: [],
  initials: [],
  lexiscan_infusion: [],
  rest_dose_amount: [],
  rest_dose_type_id: [],
  stress_dose_statement: [],
  stress_dose_amount: [],
  stress_dose_free_1: [],
  stress_dose_free_2: [],
  stress_dose_free_3: [],
  stress_dose_free_4: [],
  stress_dose_type_id: [],
  stress_test_protocol_id: [],
  stress_test_length: [],
  stress_test_heart_rate: [],
  stress_test_percentage: [],
  limit_exceeded: [],
  rest_ecg_1_id: [],
  rest_ecg_1_free_type: [],
  rest_ecg_2_id: [],
  rest_ecg_2_free_type: [],
  exercise_response_id: [],
  exercise_response_experience_id: [],
  exercise_response_experience: [],
  exercise_response_experience_free_type: [],
  rest_heart_rate_amount: [],
  rest_blood_pressure_amount: [],
  stress_blood_pressure_amount: [],
  arrhythmia_id: [],
  arrhythmia_free_type: [],
  low_level_exercise: [],
};

const fieldLists = {
  //administration_location_id: "administration_locations",
  rest_dose_type_id: "rest_dose_types",
  stress_dose_type_id: "stress_dose_types",
  stress_test_protocol_id: "stress_test_protocols",
  rest_ecg_1_id: "rest_ecgs_1",
  rest_ecg_2_id: "rest_ecgs_2",
  exercise_response_id: "exercise_responses",
  exercise_response_experience_id: "exercise_response_experiences",
  arrhythmia_id: "arrhythmias",
};

class PharmacologicProcedureForm extends React.Component {
  constructor(props) {
    super(props);
    const fields = {};

    Object.keys(fieldValidator).forEach((key) => {
      fields[key] = {
        value:
          props.entity !== null &&
          props.entity[key] !== undefined &&
          props.entity[key] !== null
            ? props.entity[key]
            : "",
        visited: false,
        error: false,
      };
    });

    Object.keys(fieldLists).forEach((item, key) => {
      if (
        props.entity !== null &&
        props.entity[item] !== undefined &&
        props.entity[item] !== "" &&
        props.entity[item] !== null
      ) {
        fields[item].value = {
          value: fields[item].value,
          label: props[fieldLists[item]].find(
            (x) => x.value === props.entity[item]
          ).label,
        };
      }
    });
    if (fields.administration_location_id.value.length === 0) {
      fields.administration_location_id.value = 2;
    }

    if (fields.exercise_response_experience.value.length > 0) {
      const labels = fields.exercise_response_experience.value.split(", ");
      fields.exercise_response_experience_id.value = props.exercise_response_experiences.filter(
        (item) => {
          return labels.includes(item.label);
        }
      );
    } else {
      fields.exercise_response_experience_id.value = null;
    }

    this.state = { ...fields };
  }

  componentDidMount() {
    const { childRef } = this.props;
    if (childRef !== undefined) {
      childRef(this);
    }
  }
  componentWillUnmount() {
    const { childRef } = this.props;
    if (childRef !== undefined) {
      childRef(undefined);
    }
  }

  updateForm = (event, name) => {
    const value =
      event !== null && event.target !== undefined ? event.target.value : event;

    const inputData = {
      value: value,
      visited: true,
      error: false,
    };

    for (let i = 0; i < fieldValidator[name].length; i++) {
      const validationMsg = fieldValidator[name][i](inputData.value);
      if (validationMsg !== undefined) {
        inputData.error = validationMsg;
        break;
      }
    }

    const toUpdate = {};
    toUpdate[name] = inputData;
    if (name === "stress_test_heart_rate") {
      toUpdate["stress_test_percentage"] = {
        value: Math.round((value / (220 - this.props.patientAge)) * 100),
        visited: true,
        error: false,
      };
    }

    if (name === "stress_dose_statement") {
      toUpdate["stress_dose_amount"] = {
        value: "",
        visited: true,
        error: false,
      };
    }

    this.setState(toUpdate);
  };

  onSubmit = (callback) => {
    const formData = JSON.parse(JSON.stringify(this.state));
    const postData = {};
    let isValid = true;

    Object.keys(fieldValidator).forEach((key) => {
      postData[key] = formData[key].value;
      for (let i = 0; i < fieldValidator[key].length; i++) {
        const validationMsg = fieldValidator[key][i](formData[key].value);
        formData[key].visited = true;
        if (validationMsg !== undefined) {
          formData[key].error = validationMsg;
          isValid = false;
          break;
        } else {
          formData[key].error = false;
        }
      }
    });

    this.setState({ ...formData, successMessage: false, errorMessage: false });

    if (isValid) {
      axiosInstance()
        .post(`/reports/save-procedure/${this.props.reportId}.json`, postData)
        .then((response) => {
          callback(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return false;
  };

  render = () => {
    const stressDoseStatement = this.state["stress_dose_statement"].value;
    const isStressDose1 = isBinary(stressDoseStatement, 1);
    const isStressDose2 = isBinary(stressDoseStatement, 2);
    const isStressDose3 = isBinary(stressDoseStatement, 4);
    const isStressDose4 = isBinary(stressDoseStatement, 8);

    return (
      <React.Fragment>
        <Row>
          <Col>
            <div className={"form-group"}>
              <label className={"font-weight-bold d-block"}>
                Administration
              </label>
              {this.props.administration_locations.map((item) => {
                return (
                  <label className={"mr-2"}>
                    <input
                      type={"radio"}
                      checked={
                        this.state.administration_location_id.value ===
                        item.value
                      }
                      onChange={(event) =>
                        this.updateForm(
                          item.value,
                          `administration_location_id`
                        )
                      }
                    />{" "}
                    <span>{item.label}</span>
                  </label>
                );
              })}
              {/*<Select
                value={this.state.administration_location_id.value}
                options={this.props.administration_locations}
                menuPlacement={"auto"}
                isClearable={true}
                isDisabled={this.state.is_custom_administration.value === 1}
                onChange={(event) => {
                  this.updateForm(event, "administration_location_id");
                }}
              /> */}
            </div>
          </Col>
          <div
            className={
              "flex-shrink-1 px-0 pt-4 font-weight-bold text-center mt-1"
            }
          >
            <label className={"font-weight-bold pt-2"}>or</label>
          </div>
          <Col>
            <div className="checkbox mb-0 text-left">
              <label className={"font-weight-bold"}>
                <input
                  type={"checkbox"}
                  checked={this.state.is_custom_administration.value === 1}
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 1 : 0,
                      "is_custom_administration"
                    )
                  }
                />{" "}
                Custom Administration Location
              </label>
            </div>
            <LabelInput
              type={"text"}
              required={true}
              disabled={this.state.is_custom_administration.value !== 1}
              {...this.state.custom_administration_location}
              onChange={(event) =>
                this.updateForm(event, "custom_administration_location")
              }
            />
          </Col>
          <div
            className={
              "flex-shrink-1 px-0 pt-4 font-weight-bold text-center mt-1"
            }
          >
            <label className={"font-weight-bold pt-2"}>by</label>
          </div>
          <Col>
            <LabelInput
              name={"Initials"}
              type={"text"}
              required={false}
              {...this.state.initials}
              onChange={(event) => this.updateForm(event, "initials")}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={3}>
            <InputGroup
              append={"mCI"}
              name={"Rest Dose"}
              type={"text"}
              required={false}
              {...this.state.rest_dose_amount}
              onChange={(event) => this.updateForm(event, "rest_dose_amount")}
            />
          </Col>
          <Col xs={6} sm={3}>
            <div className={"form-group"}>
              <label className={"font-weight-bold"}>&nbsp;</label>
              <Select
                value={this.state.rest_dose_type_id.value}
                options={this.props.rest_dose_types}
                menuPlacement={"auto"}
                isClearable={true}
                onChange={(event) => {
                  this.updateForm(event, "rest_dose_type_id");
                }}
              />
            </div>
          </Col>
        </Row>

        <div>
          <label className={"font-weight-bold"}>Stress Dose</label>
          <div
            className={"d-flex align-items-center pb-1"}
            style={isStressDose1 ? {} : { color: "#b7b7b7" }}
          >
            <div className="radio mr-2 text-left">
              <label>
                <input
                  type={"radio"}
                  name={"stress_dose_statement"}
                  checked={isStressDose1}
                  onChange={(event) =>
                    this.updateForm(1, `stress_dose_statement`)
                  }
                />{" "}
                <span>
                  0.4mg of regadenoson followed by a normal saline flush then
                </span>
              </label>
            </div>
            <div style={{ width: "150px" }}>
              <InputGroup
                append={"mCI"}
                type={"text"}
                required={false}
                disabled={!isStressDose1}
                {...this.state.stress_dose_amount}
                value={
                  !isStressDose1 ? "" : this.state.stress_dose_amount.value
                }
                onChange={(event) =>
                  this.updateForm(event, "stress_dose_amount")
                }
              />
            </div>
            <div className={"px-2"}>of</div>
            <div style={{ width: "160px" }}>
              <Select
                value={
                  !isStressDose1 ? null : this.state.stress_dose_type_id.value
                }
                options={this.props.stress_dose_types}
                isDisabled={!isStressDose1}
                menuPlacement={"auto"}
                isClearable={true}
                onChange={(event) => {
                  this.updateForm(event, "stress_dose_type_id");
                }}
              />
            </div>
            <div className={"pl-2"}>10-20 seconds later</div>
          </div>
          {/* Options2 */}
          <div
            className={"d-flex align-items-center pb-2"}
            style={!isStressDose2 ? { color: "#b7b7b7" } : {}}
          >
            <div className="radio mr-2 text-left">
              <label>
                <input
                  type={"radio"}
                  name={"stress_dose_statement"}
                  checked={isStressDose2}
                  onChange={(event) =>
                    this.updateForm(2, `stress_dose_statement`)
                  }
                />{" "}
                <span>
                  adenosine at 140mcg/kg for a total of four minutes receiving
                </span>
              </label>
            </div>
            <div style={{ width: "150px" }}>
              <InputGroup
                append={"mCI"}
                type={"text"}
                disabled={!isStressDose2}
                required={false}
                {...this.state.stress_dose_amount}
                value={
                  !isStressDose2 ? "" : this.state.stress_dose_amount.value
                }
                onChange={(event) =>
                  this.updateForm(event, "stress_dose_amount")
                }
              />
            </div>
            <div className={"px-2"}>of</div>
            <div style={{ width: "160px" }}>
              <Select
                value={
                  !isStressDose2 ? null : this.state.stress_dose_type_id.value
                }
                options={this.props.stress_dose_types}
                isDisabled={!isStressDose2}
                menuPlacement={"auto"}
                isClearable={true}
                onChange={(event) => {
                  this.updateForm(event, "stress_dose_type_id");
                }}
              />
            </div>
            <div className={"pl-2"}>after two minutes</div>
          </div>
        </div>

        {/* Options3 */}
        <div
          className={"align-items-center pb-2"}
          style={!isStressDose3 ? { color: "#b7b7b7" } : {}}
        >
          <div className="radio mr-2 text-left d-inline-block">
            <label>
              <input
                type={"radio"}
                name={"stress_dose_statement"}
                checked={isStressDose3}
                onChange={(event) =>
                  this.updateForm(4, `stress_dose_statement`)
                }
              />{" "}
              <span>Dobutamine at</span>
            </label>
          </div>
          <div style={{ width: "165px" }} className={"d-inline-block"}>
            <InputGroup
              append={"mcg/kg/min"}
              appendClass={"input-group-text px-1"}
              type={"text"}
              disabled={!isStressDose3}
              required={false}
              {...this.state.stress_dose_amount}
              value={!isStressDose3 ? "" : this.state.stress_dose_amount.value}
              onChange={(event) => this.updateForm(event, "stress_dose_amount")}
            />
          </div>
          &nbsp;&nbsp;and increased at 2-3 minute intervals to a maximum dose
          of&nbsp;&nbsp;
          <div style={{ width: "165px" }} className={"d-inline-block mt-1"}>
            <InputGroup
              append={"mcg/kg/min"}
              appendClass={"input-group-text px-1"}
              type={"text"}
              disabled={!isStressDose3}
              required={false}
              {...this.state.stress_dose_free_1}
              value={!isStressDose3 ? "" : this.state.stress_dose_free_1.value}
              onChange={(event) => this.updateForm(event, "stress_dose_free_1")}
            />
          </div>
          .
          <div className={isStressDose3 ? "pt-2 pl-3" : "d-none"}>
            <div style={{ width: "150px" }} className={"d-inline-block"}>
              <InputGroup
                append={"mCi"}
                type={"text"}
                disabled={!isStressDose3}
                required={false}
                {...this.state.stress_dose_free_2}
                value={
                  !isStressDose3 ? "" : this.state.stress_dose_free_2.value
                }
                onChange={(event) =>
                  this.updateForm(event, "stress_dose_free_2")
                }
              />
            </div>
            &nbsp;&nbsp;of&nbsp;&nbsp;
            <div style={{ width: "160px" }} className={"d-inline-block"}>
              <Select
                value={
                  !isStressDose3 ? null : this.state.stress_dose_type_id.value
                }
                options={this.props.stress_dose_types}
                isDisabled={!isStressDose3}
                menuPlacement={"auto"}
                isClearable={true}
                onChange={(event) => {
                  this.updateForm(event, "stress_dose_type_id");
                }}
              />
            </div>
            &nbsp;&nbsp;was aministered at peak stress and dobutamine was
            continued for one minute longer.
          </div>
          <div className={isStressDose3 ? "pt-2 pl-3" : "d-none"}>
            <div style={{ width: "150px" }} className={"d-inline-block"}>
              <InputGroup
                append={"mg"}
                type={"text"}
                disabled={!isStressDose3}
                required={false}
                {...this.state.stress_dose_free_3}
                value={
                  !isStressDose3 ? "" : this.state.stress_dose_free_3.value
                }
                onChange={(event) =>
                  this.updateForm(event, "stress_dose_free_3")
                }
              />
            </div>
            &nbsp;&nbsp;of atropine was also used.
          </div>
          <Row className={isStressDose3 ? "pt-2" : "d-none"}>
            <Col xs={6} sm={3}>
              <InputGroup
                name={"Maximum Heart Rate"}
                append={"bpm"}
                type={"number"}
                required={false}
                {...this.state.stress_test_heart_rate}
                onChange={(event) =>
                  this.updateForm(event, "stress_test_heart_rate")
                }
              />
            </Col>
            <Col xs={6} sm={3}>
              <InputGroup
                name={"&nbsp;"}
                append={"%"}
                type={"number"}
                required={false}
                {...this.state.stress_test_percentage}
                onChange={(event) =>
                  this.updateForm(event, "stress_test_percentage")
                }
              />
            </Col>
          </Row>
        </div>

        {/* Options4 */}
        <div
          className={"align-items-center pb-2"}
          style={!isStressDose4 ? { color: "#b7b7b7" } : {}}
        >
          <div className="radio mr-2 text-left d-inline-block">
            <label>
              <input
                type={"radio"}
                name={"stress_dose_statement"}
                checked={isStressDose4}
                onChange={(event) =>
                  this.updateForm(8, `stress_dose_statement`)
                }
              />{" "}
            </label>
          </div>
          <div style={{ width: "165px" }} className={"d-inline-block mr-4"}>
            <InputGroup
              append={"mCI"}
              type={"text"}
              required={false}
              disabled={!isStressDose4}
              {...this.state.stress_dose_amount}
              value={!isStressDose4 ? "" : this.state.stress_dose_amount.value}
              onChange={(event) => this.updateForm(event, "stress_dose_amount")}
            />
          </div>
          <div style={{ width: "165px" }} className={"d-inline-block"}>
            <div className={"form-group mb-0"}>
              <Select
                options={this.props.stress_dose_types}
                value={
                  !isStressDose4 ? null : this.state.stress_dose_type_id.value
                }
                menuPlacement={"auto"}
                isClearable={true}
                isDisabled={!isStressDose4}
                onChange={(event) => {
                  this.updateForm(event, "stress_dose_type_id");
                }}
              />
            </div>
          </div>
          {isStressDose4 && (
            <React.Fragment>
              <Row className={"pl-4 pb-2"}>
                <Col>
                  <div className={"form-group mb-0"}>
                    <label className={"font-weight-bold"}>Stress Test</label>
                    <Select
                      value={this.state.stress_test_protocol_id.value}
                      options={this.props.stress_test_protocols}
                      menuPlacement={"auto"}
                      isClearable={true}
                      onChange={(event) => {
                        this.updateForm(event, "stress_test_protocol_id");
                      }}
                    />
                  </div>
                </Col>
                <div
                  className={
                    "flex-shrink-1 px-0 pt-4 font-weight-bold text-center mt-1"
                  }
                >
                  <label className={"font-weight-bold pt-2"}>
                    protocol for
                  </label>
                </div>
                <Col>
                  <InputGroup
                    name={"&nbsp;"}
                    append={"minutes"}
                    type={"text"}
                    required={false}
                    {...this.state.stress_test_length}
                    onChange={(event) =>
                      this.updateForm(event, "stress_test_length")
                    }
                  />
                </Col>
                <Col>
                  <InputGroup
                    name={"Maximum Heart Rate"}
                    append={"bpm"}
                    type={"number"}
                    required={false}
                    {...this.state.stress_test_heart_rate}
                    onChange={(event) =>
                      this.updateForm(event, "stress_test_heart_rate")
                    }
                  />
                </Col>
                <Col>
                  <InputGroup
                    name={"&nbsp;"}
                    append={"%"}
                    type={"number"}
                    required={false}
                    {...this.state.stress_test_percentage}
                    onChange={(event) =>
                      this.updateForm(event, "stress_test_percentage")
                    }
                  />
                </Col>
              </Row>
              <Row className={"pl-4 pb-3"}>
                <Col>
                  <InputGroup
                    append={"bpm"}
                    name={"Rest Heart Rate"}
                    type={"text"}
                    required={false}
                    {...this.state.rest_heart_rate_amount}
                    onChange={(event) =>
                      this.updateForm(event, "rest_heart_rate_amount")
                    }
                  />
                </Col>
                <Col>
                  <InputGroup
                    append={"mmHg"}
                    name={"Rest Blood Pressure"}
                    type={"text"}
                    required={false}
                    {...this.state.rest_blood_pressure_amount}
                    onChange={(event) =>
                      this.updateForm(event, "rest_blood_pressure_amount")
                    }
                  />
                </Col>
                <Col>
                  <InputGroup
                    append={"mmHg"}
                    name={"Stress Blood Pressure"}
                    type={"text"}
                    required={false}
                    {...this.state.stress_blood_pressure_amount}
                    onChange={(event) =>
                      this.updateForm(event, "stress_blood_pressure_amount")
                    }
                  />
                </Col>
              </Row>
            </React.Fragment>
          )}
        </div>
        <div className={"pb-2 pt-2"}>
          <label className={"font-weight-bold"}>Limit Exceeded</label>
          <br />
          <label>
            <input
              type={"checkbox"}
              checked={this.state.limit_exceeded.value === 1}
              onChange={(event) =>
                this.updateForm(
                  event.currentTarget.checked ? 1 : 0,
                  "limit_exceeded"
                )
              }
            />{" "}
            Check if the administered patient doses did exceed Appendix U: Table
            U.1 and Table U.2 limits.
          </label>
        </div>
        <div className={"pb-2"}>
          <label className={"font-weight-bold"}>Low Level Exercise</label>
          <div className={"d-flex align-items-center"}>
            <span>Low level exercise</span>
            <div className={"px-2"} style={{ width: "180px" }}>
              <Select
                value={
                  this.state.low_level_exercise.value.length > 0
                    ? {
                        value: this.state.low_level_exercise.value,
                        label: this.state.low_level_exercise.value,
                      }
                    : null
                }
                options={[
                  { value: "was", label: "was" },
                  { value: "was not", label: "was not" },
                ]}
                menuPlacement={"auto"}
                isClearable={true}
                onChange={(event) => {
                  this.updateForm(
                    event !== null ? event.label : "",
                    "low_level_exercise"
                  );
                }}
              />
            </div>
            <span> used.</span>
          </div>
        </div>

        <Row>
          <Col xs={3}>
            <div className={"form-group"}>
              <label className={"font-weight-bold"}>Rest ECG</label>
              <Select
                value={this.state.rest_ecg_1_id.value}
                options={this.props.rest_ecgs_1}
                menuPlacement={"auto"}
                isClearable={true}
                isDisabled={
                  this.state.rest_ecg_1_id.value !== null &&
                  this.state.rest_ecg_1_id.value.label === "[free type]"
                }
                onChange={(event) => {
                  this.updateForm(event, "rest_ecg_1_id");
                }}
              />
            </div>
          </Col>
          {this.state.rest_ecg_1_id.value !== null &&
            this.state.rest_ecg_1_id.value.label === "[free type]" && (
              <Col xs={3}>
                <label className={"d-block"}>&nbsp;</label>
                <div className={"d-flex align-items-center"}>
                  <LabelInput
                    type={"text"}
                    formGroupClass={"mb-0 flex-grow-1"}
                    className={"form-control"}
                    {...this.state.rest_ecg_1_free_type}
                    onChange={(event) =>
                      this.updateForm(event, "rest_ecg_1_free_type")
                    }
                  />
                  <FontAwesomeIcon
                    className={"mr-2 ml-2 pointer"}
                    icon={["fas", "times"]}
                    onClick={(event) => {
                      this.updateForm(null, "rest_ecg_1_free_type");
                      this.updateForm(null, "rest_ecg_1_id");
                    }}
                  />
                </div>
              </Col>
            )}
          <Col xs={3}>
            <div className={"form-group"}>
              <label className={"font-weight-bold"}>&nbsp;</label>
              <Select
                value={this.state.rest_ecg_2_id.value}
                options={this.props.rest_ecgs_2}
                menuPlacement={"auto"}
                isClearable={true}
                isDisabled={
                  this.state.rest_ecg_2_id.value !== null &&
                  this.state.rest_ecg_2_id.value.label === "[free type]"
                }
                onChange={(event) => {
                  this.updateForm(event, "rest_ecg_2_id");
                }}
              />
            </div>
          </Col>
          {this.state.rest_ecg_2_id.value !== null &&
            this.state.rest_ecg_2_id.value.label === "[free type]" && (
              <Col xs={3}>
                <label className={"d-block"}>&nbsp;</label>
                <div className={"d-flex align-items-center"}>
                  <LabelInput
                    type={"text"}
                    formGroupClass={"mb-0 flex-grow-1"}
                    className={"form-control"}
                    {...this.state.rest_ecg_2_free_type}
                    onChange={(event) =>
                      this.updateForm(event, "rest_ecg_2_free_type")
                    }
                  />
                  <FontAwesomeIcon
                    className={"mr-2 ml-2 pointer"}
                    icon={["fas", "times"]}
                    onClick={(event) => {
                      this.updateForm(null, "rest_ecg_2_free_type");
                      this.updateForm(null, "rest_ecg_2_id");
                    }}
                  />
                </div>
              </Col>
            )}
        </Row>
        <Row>
          <Col>
            <div className={"form-group"}>
              <label className={"font-weight-bold"}>Stress Response</label>
              <Select
                value={this.state.exercise_response_id.value}
                options={this.props.exercise_responses}
                menuPlacement={"auto"}
                isClearable={true}
                onChange={(event) => {
                  this.updateForm(event, "exercise_response_id");
                }}
              />
            </div>
          </Col>
          <div
            className={
              "flex-shrink-1 px-0 pt-4 font-weight-bold text-center mt-1"
            }
          >
            <label className={"font-weight-bold pt-2"}>
              and the patient experienced
            </label>
          </div>
          <Col>
            <div className={"form-group"}>
              <label className={"font-weight-bold"}>&nbsp;</label>
              <Select
                value={this.state.exercise_response_experience_id.value}
                options={this.props.exercise_response_experiences}
                menuPlacement={"auto"}
                isMulti={true}
                isClearable={true}
                isDisabled={
                  this.state.exercise_response_experience_id.value !== null &&
                  this.state.exercise_response_experience_id.value.filter(
                    (e) => e.label === "[free type]"
                  ).length > 0
                }
                onChange={(event) => {
                  this.updateForm(event, "exercise_response_experience_id");
                }}
              />
            </div>
          </Col>
          {this.state.exercise_response_experience_id.value !== null &&
            this.state.exercise_response_experience_id.value.filter(
              (e) => e.label === "[free type]"
            ).length > 0 && (
              <Col xs={12}>
                <div className={"d-flex align-items-center pb-3"}>
                  <LabelInput
                    type={"text"}
                    className={"form-control"}
                    formGroupClass={"mb-0 flex-grow-1"}
                    {...this.state.exercise_response_experience_free_type}
                    onChange={(event) =>
                      this.updateForm(
                        event,
                        "exercise_response_experience_free_type"
                      )
                    }
                  />
                  <FontAwesomeIcon
                    className={"mr-2 ml-2 pointer"}
                    icon={["fas", "times"]}
                    onClick={(event) => {
                      this.updateForm(
                        null,
                        "exercise_response_experience_free_type"
                      );
                      this.updateForm(null, "exercise_response_experience_id");
                    }}
                  />
                </div>
              </Col>
            )}
        </Row>
        <Row>
          <Col xs={6}>
            <div className={"form-group"}>
              <label className={"font-weight-bold"}>Arrhythmias</label>
              <Select
                value={this.state.arrhythmia_id.value}
                options={this.props.arrhythmias}
                menuPlacement={"auto"}
                isClearable={true}
                isDisabled={
                  this.state.arrhythmia_id.value !== null &&
                  this.state.arrhythmia_id.value.label === "[free type]"
                }
                onChange={(event) => {
                  this.updateForm(event, "arrhythmia_id");
                }}
              />
            </div>
          </Col>
          {this.state.arrhythmia_id.value !== null &&
            this.state.arrhythmia_id.value.label === "[free type]" && (
              <Col xs={6}>
                <label className={"d-block"}>&nbsp;</label>
                <div className={"d-flex align-items-center"}>
                  <LabelInput
                    type={"text"}
                    formGroupClass={"mb-0 flex-grow-1"}
                    className={"form-control"}
                    {...this.state.arrhythmia_free_type}
                    onChange={(event) =>
                      this.updateForm(event, "arrhythmia_free_type")
                    }
                  />
                  <FontAwesomeIcon
                    className={"mr-2 ml-2 pointer"}
                    icon={["fas", "times"]}
                    onClick={(event) => {
                      this.updateForm(null, "arrhythmia_free_type");
                      this.updateForm(null, "arrhythmia_id");
                    }}
                  />
                </div>
              </Col>
            )}
        </Row>
      </React.Fragment>
    );
  };
}
export default PharmacologicProcedureForm;
