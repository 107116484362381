import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
} from "reactstrap";
import axiosInstance from "axios-instance";
import LabelInput from "components/Elements/LabelInput/LabelInput";

import {
  required,
  email,
  maxLength,
  passwordCompare,
  passwordLength,
} from "validation";
const validator = {
  email: [required, email, (value) => maxLength(value, 255)],
  first_name: [required, (value) => maxLength(value, 64)],
  last_name: [required, (value) => maxLength(value, 64)],
  role: [required, (value) => maxLength(value, 64)],
  designation: [(value) => maxLength(value, 64)],
  echo_findings_access: [],
  password: [required, passwordLength],
  repassword: [required, passwordLength],
};

class CreateUser extends React.Component {
  constructor(props) {
    super(props);

    const fields = {};
    Object.keys(validator).forEach((key) => {
      fields[key] = {
        value: "",
        visited: false,
        error: false,
      };
    });
    fields["role"].value = "Technician";

    this.state = {
      ...fields,
    };
  }

  onSave = (event) => {
    event.preventDefault();
    const formData = JSON.parse(JSON.stringify(this.state));
    const postData = {};
    let isValid = true;

    Object.keys(validator).forEach((key) => {
      postData[key] = formData[key].value;
      for (let i = 0; i < validator[key].length; i++) {
        const validationMsg = validator[key][i](formData[key].value);
        formData[key].visited = true;
        if (validationMsg !== undefined) {
          formData[key].error = validationMsg;
          isValid = false;
          break;
        } else {
          formData[key].error = false;
        }
      }

      if (key === "repassword" && formData[key].error === false) {
        let validationPass = passwordCompare(
          formData[key].value,
          this.state.password.value
        );
        if (validationPass !== undefined) {
          formData[key].error = validationPass;
          isValid = false;
        }
      }
    });

    this.setState({ ...formData });
    if (isValid) {
      axiosInstance()
        .get(`/admin/users/check-email/${formData.email.value}.json`)
        .then((response) => {
          if (!response.data.exist) {
            this.props.onSubmit(postData);
          } else {
            this.setState({
              email: {
                ...formData.email,
                error: "Duplicated email address. Please try again.",
              },
            });
          }
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
    }

    return false;
  };

  updateForm = (event, name) => {
    const inputData = {
      value:
        event !== null && event.target !== undefined
          ? event.target.value
          : event,
      visited: true,
      error: false,
    };

    for (let i = 0; i < validator[name].length; i++) {
      const validationMsg = validator[name][i](inputData.value);
      if (validationMsg !== undefined) {
        inputData.error = validationMsg;
        break;
      }
    }
    if (name === "repassword" && inputData.error === false) {
      let validationPass = passwordCompare(
        inputData.value,
        this.state.password.value
      );
      if (validationPass !== undefined) {
        inputData.error = validationPass;
      }
    }

    const toUpdate = {
      [name]: inputData,
    };

    this.setState(toUpdate);
  };

  render = () => {
    return (
      <Modal
        isOpen={this.props.isActive}
        toggle={this.props.toggle}
        className={this.props.className}
        backdrop={"static"}
      >
        <ModalHeader toggle={this.props.toggle}>
          {"Create New User"}
        </ModalHeader>
        <div className={"position-relative"}>
          <ModalBody>
            <LabelInput
              name={"Email"}
              type={"email"}
              required={true}
              {...this.state.email}
              onChange={(event) => this.updateForm(event, "email")}
            />
            <div className={"form-group"}>
              <label className="font-weight-bold">Role</label>
              <select
                className={"form-control"}
                value={this.state.role.value}
                onChange={(event) => this.updateForm(event, "role")}
              >
                <option value={"Technician"}>Technician</option>
                <option value={"Physician"}>Physician</option>
                <option value={"Manager"}>Manager</option>
              </select>
            </div>
            <div className="checkbox mb-3 text-left">
              <label>
                <input
                  type={"checkbox"}
                  checked={this.state.echo_findings_access.value === 1}
                  disabled={this.state.isManager === 0}
                  value={1}
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 1 : 0,
                      "echo_findings_access"
                    )
                  }
                />
                &nbsp;&nbsp;&nbsp;Echo Findings Database Access
              </label>
            </div>
            <Row>
              <Col>
                <LabelInput
                  name={"First Name"}
                  type={"text"}
                  required={true}
                  {...this.state.first_name}
                  onChange={(event) => this.updateForm(event, "first_name")}
                />
              </Col>
              <Col>
                <LabelInput
                  name={"Last Name"}
                  type={"text"}
                  required={true}
                  {...this.state.last_name}
                  onChange={(event) => this.updateForm(event, "last_name")}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <LabelInput
                  name={"Designation"}
                  type={"text"}
                  {...this.state.designation}
                  onChange={(event) => this.updateForm(event, "designation")}
                />
              </Col>
            </Row>
            <div>
              <LabelInput
                name={"New Password"}
                type={"password"}
                required={true}
                {...this.state.password}
                onChange={(event) => this.updateForm(event, "password")}
              />
              <LabelInput
                name={"Confirm New Password"}
                type={"password"}
                required={true}
                {...this.state.repassword}
                onChange={(event) => this.updateForm(event, "repassword")}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="light"
              className={"btn px-3"}
              type={"button"}
              onClick={this.props.toggle}
            >
              Cancel
            </Button>

            {this.props.submitting === false ? (
              <Button
                color="primary"
                className={"btn px-3"}
                type={"submit"}
                onClick={this.onSave}
              >
                {"Create"}
              </Button>
            ) : (
              <button className="btn btn-primary px-5" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                &nbsp;&nbsp; Loading...
              </button>
            )}
          </ModalFooter>
        </div>
      </Modal>
    );
  };
}

export default CreateUser;
