import React from "react";
import { displayValue } from "functions";

export default (props) => {
  return (
    <div className={"position-relative"}>
      <ol type={"(1)"}>
        <li
          style={{ minHeight: "25px" }}
          className={
            (props.selected_field & 63) > 0 ? "d-block" : "invisible-1"
          }
        >
          {(props.selected_field & 1) > 0 && (
            <div>Myocardial perfusion imaging is normal.</div>
          )}
          {(props.selected_field & 2) > 0 && (
            <div>
              Myocardial perfusion imaging is normal with presumed attenuation
              in the {displayValue(props.field_1)} wall(s).
            </div>
          )}
          {(props.selected_field & 4) > 0 && (
            <div>
              Myocardial perfusion imaging is probably normal. There is a fixed
              defect in the {displayValue(props.field_2)} wall(s). While this
              likely represents an attenuation artifact a true perfusion defect
              cannot be completely excluded.
            </div>
          )}
          {(props.selected_field & 8) > 0 && (
            <div>
              Myocardial perfusion imaging is equivocal. There is reversibility
              in the {displayValue(props.field_3)} wall(s) possibly related to{" "}
              {displayValue(props.field_4)}, however ischemia cannot be
              completely excluded.
            </div>
          )}
          {(props.selected_field & 16) > 0 && (
            <div>
              Myocardial perfusion imaging is abnormal due to [
              <span>
                {props.report_exercise_finding.field_14 === "1" && (
                  <span>{props.findings[14][0].label}</span>
                )}
                {props.report_exercise_finding.field_14 !== "1" && (
                  <span>
                    a {displayValue(props.report_exercise_finding.field_15)}{" "}
                    size perfusion abnormality of{" "}
                    {displayValue(props.report_exercise_finding.field_16)}{" "}
                    intensity in the{" "}
                    {displayValue(props.report_exercise_finding.field_17)} that
                    is {displayValue(props.report_exercise_finding.field_18)}{" "}
                    {props.field_18 === "reversible" &&
                      displayValue(props.report_exercise_finding.field_18_1)}
                    {props.field_18 === "fixed" && (
                      <React.Fragment>
                        {displayValue(props.report_exercise_finding.field_18_2)}
                        {props.report_exercise_finding.field_18_2 ===
                          "with" && (
                          <React.Fragment>
                            {" "}
                            a{" "}
                            {displayValue(
                              props.report_exercise_finding.field_19
                            )}{" "}
                            amount of peri-infarct ischemia
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                    .
                    {props.report_exercise_finding.report_exercise_finding_sentences.map(
                      (item) => {
                        if (item.type !== 2) return null;
                        return (
                          <span key={`item-` + item.id}>
                            {" "}
                            There is also a {displayValue(item.field_1)} size
                            perfusion abnormality of{" "}
                            {displayValue(item.field_2)} intensity in the{" "}
                            {displayValue(item.field_3)} that is{" "}
                            {displayValue(item.field_4)}{" "}
                            {item.field_4 === "reversible" &&
                              displayValue(item.field_5)}
                            {item.field_4 === "fixed" && (
                              <React.Fragment>
                                {displayValue(item.field_6)}
                                {item.field_6 === "with" && (
                                  <React.Fragment>
                                    {" "}
                                    a {displayValue(item.field_7)} amount of
                                    peri-infarct ischemia
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            )}
                            .
                          </span>
                        );
                      }
                    )}
                  </span>
                )}
              </span>
              ]
            </div>
          )}
          {(props.selected_field & 32) > 0 && (
            <div>
              Compared to a prior study these findings are{" "}
              {displayValue(props.field_6)}.
            </div>
          )}
        </li>

        <li
          style={{ minHeight: "25px" }}
          className={
            (props.selected_field & 192) > 0 ? "d-block" : "invisible-1"
          }
        >
          {(props.selected_field & 64) > 0 && (
            <div>
              The left ventricular systolic function is{" "}
              {displayValue(props.field_7)}.
            </div>
          )}
          {(props.selected_field & 128) > 0 && (
            <div>
              The left ventricular ejection fraction was not calculated because
              the study was not gated.
            </div>
          )}
        </li>

        <li
          style={{ minHeight: "25px" }}
          className={
            (props.selected_field & 256) > 0 ? "d-block" : "invisible-1"
          }
        >
          The stress test results are described below.
        </li>
        <li
          className={
            props.notes !== null &&
            props.notes !== undefined &&
            props.notes.length > 0
              ? "d-block"
              : "invisible-1"
          }
        >
          {props.notes}&nbsp;
        </li>
      </ol>
      {/*<div>
        {props.conclusion_type === "Normal" && (
          <React.Fragment>
            {props.conclusion_type_value === 1 && (
              <span>Myocardial perfusion imaging is normal.</span>
            )}
            {props.conclusion_type_value === 2 && (
              <span>
                Myocardial perfusion imaging is normal with presumed attenuation
                of the {displayValue(props.field_1)} wall(s).
              </span>
            )}
          </React.Fragment>
        )}
        {/* props.conclusion_type === "Abnormal" && (
          <React.Fragment>
            {props.conclusion_type_value === 1 && (
              <span>
                Myocardial perfusion imaging is abnormal and demonstrates{" "}
                {displayValue(props.field_2)} ischemia in the{" "}
                {displayValue(props.field_3)} wall(s).&nbsp;
              </span>
            )}
            {props.conclusion_type_value_2 === 1 && (
              <span>
                A {displayValue(props.field_4)} scar involving the{" "}
                {displayValue(props.field_5)} wall(s).
              </span>
            )}
          </React.Fragment>
        
        {props.conclusion_type === "Equivocal" && (
          <React.Fragment>
            <span>
              Myocardial perfusion imaging is abnormal and demonstrates
              reversibility in the {displayValue(props.field_6)} wall(s){" "}
              {displayValue(props.field_7)}.
            </span>
          </React.Fragment>
        )}
        {props.conclusion_type === "Probably normal" && (
          <React.Fragment>
            <ol>
              <li>
                Myocardial perfusion imaging demonstrates a fixed defect in the{" "}
                {displayValue(props.field_8)} wall(s). While this may represent
                an attenuation artifact, a true perfusion defect cannot be
                excluded.
              </li>
              {/*props.conclusion_type_value !== 1 && (
                <li>
                  The left ventricular systolic function is{" "}
                  {displayValue(props.field_9)}.
                </li>
              )}
              <li>The stress test results are described above.</li>
              {props.conclusion_type_value === 1 && (
                <li>
                  The study was not gated. (If checked, 2) will be taken out of
                  the conclusion).
                </li>
              
              {props.conclusion_type_value_2 === 1 && (
                <li>
                  Motion artifact was present on this study which may reduce the
                  sensitivity and/or specificity of the findings.
                </li>
              )}
            </ol>
          </React.Fragment>
        )}
        <p>{props.notes}</p>
      </div>*/}
    </div>
  );
};
