import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Container } from "reactstrap";
import axiosInstance from "axios-instance";

import EchoForm from "components/Elements/Reports/EchoForm";
import ExerciseForm from "components/Elements/Reports/ExerciseForm";
import VascularForm from "components/Elements/Reports/VascularForm";

class CreateReport extends Component {
  constructor(props) {
    super(props);

    const authData = JSON.parse(localStorage.getItem("auth"));

    this.state = {
      loaded: false,
      report_type_id: {
        value: 1,
        visited: false,
        error: false,
      },
      technician_id:
        authData.user.role === "Technician" ? authData.user.id : "",
    };

    this.loadOptions();
  }

  loadOptions = () => {
    axiosInstance()
      .get(`/reports/load-options.json`)
      .then((response) => {
        this.setState({
          loaded: true,
          reportTypes: response.data.reportTypes,
          heartRhythms: response.data.heartRhythms,
          listPhysicians: response.data.physicians,
          listTechnician: response.data.technicians,
          listProviders: response.data.providers,
        });
        console.log(response.data);
        console.log(response.data.reportTypes);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  updateForm = (event, name) => {
    const inputData = {
      value:
        event !== null && event.target !== undefined
          ? event.target.value
          : event,
      visited: true,
      error: false,
    };

    this.setState({
      [name]: inputData,
    });
  };

  render = () => {
    return (
      <Container>
        <Helmet>
          <title>NewportX | Create Report</title>
        </Helmet>
        <div className={"pt-2 pb-5"}>
          <h2>Create New Report</h2>
          {this.state.loaded && (
            <div className={"py-4"}>
              <div className={"form-group"}>
                <label className="font-weight-bold">
                  Select Report Type to Continue*
                </label>
                <select
                  className={
                    this.state.report_type_id.error
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  value={this.state.report_type_id.value}
                  onChange={(event) => this.updateForm(event, "report_type_id")}
                >
                  <option value={""}></option>
                  {Object.keys(this.state.reportTypes).map((key) => {
                    const typeId = this.state.reportTypes[key].id;
                    const name = this.state.reportTypes[key].name;
                    return (
                      <option value={typeId} key={"report-type-" + typeId}>
                        {name}
                      </option>
                    );
                  })}
                </select>
                {this.state.report_type_id.error && (
                  <span className={"error"}>
                    {this.state.report_type_id.error}
                  </span>
                )}
              </div>
              {parseInt(this.state.report_type_id.value) === 1 && (
                <EchoForm
                  showSubmit={true}
                  {...this.state}
                  report_type_id={1}
                  is_heart_rhythm_custom={0}
                />
              )}
              {[2, 3, 4, 5].includes(
                parseInt(this.state.report_type_id.value)
              ) && (
                <ExerciseForm
                  technicianLabel={"Nuclear Technician"}
                  showSubmit={true}
                  {...this.state}
                  report_type_id={parseInt(this.state.report_type_id.value)}
                />
              )}

              {[6].includes(parseInt(this.state.report_type_id.value)) && (
                <VascularForm
                  showSubmit={true}
                  {...this.state}
                  report_type_id={parseInt(this.state.report_type_id.value)}
                />
              )}
            </div>
          )}
        </div>
      </Container>
    );
  };
}
export default CreateReport;
