import React from "react";
import { Row, Col } from "reactstrap";
import LabelInput from "components/Elements/LabelInput/LabelInput";
import InputGroup from "components/Elements/InputGroup/InputGroup";
import Select from "react-select";
import axiosInstance from "axios-instance";

const fieldValidator = {
  administration_location_id: [],
  is_custom_administration: [],
  custom_administration_location: [],
  initials: [],
  lexiscan_infusion: [],
  rest_dose_amount: [],
  rest_dose_type_id: [],
  stress_dose_amount: [],
  stress_dose_type_id: [],
  stress_test_protocol_id: [],
  stress_test_length: [],
  stress_test_heart_rate: [],
  stress_test_percentage: [],
  limit_exceeded: [],
  rest_ecg_1_id: [],
  rest_ecg_2_id: [],
  exercise_response_id: [],
  exercise_response_experience_id: [],
  arrhythmia_id: [],
};

const fieldLists = {
  //administration_location_id: "administration_locations",
  rest_dose_type_id: "rest_dose_types",
  stress_dose_type_id: "stress_dose_types",
  stress_test_protocol_id: "stress_test_protocols",
  rest_ecg_1_id: "rest_ecgs_1",
  rest_ecg_2_id: "rest_ecgs_2",
  exercise_response_id: "exercise_responses",
  exercise_response_experience_id: "exercise_response_experiences",
  arrhythmia_id: "arrhythmias",
};

class ProcedureForm extends React.Component {
  constructor(props) {
    super(props);
    const fields = {};

    Object.keys(fieldValidator).forEach((key) => {
      fields[key] = {
        value:
          props.entity !== null &&
          props.entity[key] !== undefined &&
          props.entity[key] !== null
            ? props.entity[key]
            : "",
        visited: false,
        error: false,
      };
    });

    Object.keys(fieldLists).forEach((item, key) => {
      if (
        props.entity !== null &&
        props.entity[item] !== undefined &&
        props.entity[item] !== "" &&
        props.entity[item] !== null
      ) {
        console.log(fieldLists[item]);
        fields[item].value = {
          value: fields[item].value,
          label: props[fieldLists[item]].find(
            (x) => x.value === props.entity[item]
          ).label,
        };
      }
    });
    if (fields.administration_location_id.value.length === 0) {
      fields.administration_location_id.value = 2;
    }
    this.state = { ...fields };
  }

  componentDidMount() {
    const { childRef } = this.props;
    if (childRef !== undefined) {
      childRef(this);
    }
  }
  componentWillUnmount() {
    const { childRef } = this.props;
    if (childRef !== undefined) {
      childRef(undefined);
    }
  }

  updateForm = (event, name) => {
    const value =
      event !== null && event.target !== undefined ? event.target.value : event;

    const inputData = {
      value: value,
      visited: true,
      error: false,
    };

    for (let i = 0; i < fieldValidator[name].length; i++) {
      const validationMsg = fieldValidator[name][i](inputData.value);
      if (validationMsg !== undefined) {
        inputData.error = validationMsg;
        break;
      }
    }
    const toUpdate = {};
    toUpdate[name] = inputData;
    if (name === "stress_test_heart_rate") {
      toUpdate["stress_test_percentage"] = {
        value: Math.round((value / (220 - this.props.patientAge)) * 100),
        visited: true,
        error: false,
      };
    }

    this.setState(toUpdate);
  };

  onSubmit = (callback) => {
    const formData = JSON.parse(JSON.stringify(this.state));
    const postData = {};
    let isValid = true;

    Object.keys(fieldValidator).forEach((key) => {
      postData[key] = formData[key].value;
      for (let i = 0; i < fieldValidator[key].length; i++) {
        const validationMsg = fieldValidator[key][i](formData[key].value);
        formData[key].visited = true;
        if (validationMsg !== undefined) {
          formData[key].error = validationMsg;
          isValid = false;
          break;
        } else {
          formData[key].error = false;
        }
      }
    });

    this.setState({ ...formData, successMessage: false, errorMessage: false });

    if (isValid) {
      axiosInstance()
        .post(`/reports/save-procedure/${this.props.reportId}.json`, postData)
        .then((response) => {
          callback(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return false;
  };

  render = () => {
    return (
      <React.Fragment>
        {/* this.props.entity.nuclear_protocol !== null && (
          <Row>
            <Col xs={4}>
              <LabelInput
                type={"text"}
                disabled={true}
                value={this.props.entity.nuclear_protocol.name}
                name={"Protocol"}
              />
            </Col>
          </Row>
        ) */}
        <Row>
          <Col>
            <div className={"form-group"}>
              <label className={"font-weight-bold d-block"}>
                Administration
              </label>
              {this.props.administration_locations.map((item) => {
                return (
                  <label className={"mr-2"}>
                    <input
                      type={"radio"}
                      checked={
                        this.state.administration_location_id.value ===
                        item.value
                      }
                      onChange={(event) =>
                        this.updateForm(
                          item.value,
                          `administration_location_id`
                        )
                      }
                    />{" "}
                    <span>{item.label}</span>
                  </label>
                );
              })}

              {/* <Select
                value={this.state.administration_location_id.value}
                options={this.props.administration_locations}
                menuPlacement={"auto"}
                isClearable={true}
                isDisabled={this.state.is_custom_administration.value === 1}
                onChange={(event) => {
                  this.updateForm(event, "administration_location_id");
                }}
              /> */}
            </div>
          </Col>
          <div
            className={
              "flex-shrink-1 px-0 pt-4 font-weight-bold text-center mt-1"
            }
          >
            <label className={"font-weight-bold pt-2"}>or</label>
          </div>
          <Col>
            <div className="checkbox mb-0 text-left">
              <label className={"font-weight-bold"}>
                <input
                  type={"checkbox"}
                  checked={this.state.is_custom_administration.value === 1}
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 1 : 0,
                      "is_custom_administration"
                    )
                  }
                />{" "}
                Custom Administration Location
              </label>
            </div>
            <LabelInput
              type={"text"}
              required={true}
              disabled={this.state.is_custom_administration.value !== 1}
              {...this.state.custom_administration_location}
              onChange={(event) =>
                this.updateForm(event, "custom_administration_location")
              }
            />
          </Col>
          <div
            className={
              "flex-shrink-1 px-0 pt-4 font-weight-bold text-center mt-1"
            }
          >
            <label className={"font-weight-bold pt-2"}>by</label>
          </div>
          <Col>
            <LabelInput
              name={"Initials"}
              type={"text"}
              required={false}
              {...this.state.initials}
              onChange={(event) => this.updateForm(event, "initials")}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={3}>
            <InputGroup
              append={"mCI"}
              name={"Rest Dose"}
              type={"text"}
              required={false}
              {...this.state.rest_dose_amount}
              onChange={(event) => this.updateForm(event, "rest_dose_amount")}
            />
          </Col>
          <Col xs={6} sm={3}>
            <div className={"form-group"}>
              <label className={"font-weight-bold"}>&nbsp;</label>
              <Select
                value={this.state.rest_dose_type_id.value}
                options={this.props.rest_dose_types}
                menuPlacement={"auto"}
                isClearable={true}
                onChange={(event) => {
                  this.updateForm(event, "rest_dose_type_id");
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={3}>
            <InputGroup
              append={"mCI"}
              name={"Stress Dose"}
              type={"text"}
              required={false}
              {...this.state.stress_dose_amount}
              onChange={(event) => this.updateForm(event, "stress_dose_amount")}
            />
          </Col>
          <Col xs={6} sm={3}>
            <div className={"form-group"}>
              <label className={"font-weight-bold"}>&nbsp;</label>
              <Select
                value={this.state.stress_dose_type_id.value}
                options={this.props.stress_dose_types}
                menuPlacement={"auto"}
                isClearable={true}
                onChange={(event) => {
                  this.updateForm(event, "stress_dose_type_id");
                }}
              />
            </div>
          </Col>
        </Row>
        <div>
          <label className={"font-weight-bold"}>Limit Exceeded</label>
          <br />
          <label>
            <input
              type={"checkbox"}
              checked={this.state.limit_exceeded.value === 1}
              onChange={(event) =>
                this.updateForm(
                  event.currentTarget.checked ? 1 : 0,
                  "limit_exceeded"
                )
              }
            />{" "}
            Check if the administered patient doses did exceed Appendix U: Table
            U.1 and Table U.2 limits.
          </label>
        </div>
        {this.props.reportEntity.report_type.slug === "exercise" && (
          <Row>
            <Col>
              <div className={"form-group"}>
                <label className={"font-weight-bold"}>Stress Test</label>
                <Select
                  value={this.state.stress_test_protocol_id.value}
                  options={this.props.stress_test_protocols}
                  menuPlacement={"auto"}
                  isClearable={true}
                  onChange={(event) => {
                    this.updateForm(event, "stress_test_protocol_id");
                  }}
                />
              </div>
            </Col>
            <div
              className={
                "flex-shrink-1 px-0 pt-4 font-weight-bold text-center mt-1"
              }
            >
              <label className={"font-weight-bold pt-2"}>protocol for</label>
            </div>
            <Col>
              <InputGroup
                name={"&nbsp;"}
                append={"minutes"}
                type={"text"}
                required={false}
                {...this.state.stress_test_length}
                onChange={(event) =>
                  this.updateForm(event, "stress_test_length")
                }
              />
            </Col>
            <Col>
              <InputGroup
                name={"Maximum Heart Rate"}
                append={"bpm"}
                type={"number"}
                required={false}
                {...this.state.stress_test_heart_rate}
                onChange={(event) =>
                  this.updateForm(event, "stress_test_heart_rate")
                }
              />
            </Col>
            <Col>
              <InputGroup
                name={"&nbsp;"}
                append={"%"}
                type={"number"}
                required={false}
                {...this.state.stress_test_percentage}
                onChange={(event) =>
                  this.updateForm(event, "stress_test_percentage")
                }
              />
            </Col>
          </Row>
        )}
        {this.props.reportEntity.report_type.slug === "exercise" && (
          <Row>
            <Col xs={4}>
              <div className={"form-group"}>
                <label className={"font-weight-bold"}>Rest ECG</label>
                <Select
                  value={this.state.rest_ecg_1_id.value}
                  options={this.props.rest_ecgs_1}
                  menuPlacement={"auto"}
                  isClearable={true}
                  onChange={(event) => {
                    this.updateForm(event, "rest_ecg_1_id");
                  }}
                />
              </div>
            </Col>
            <Col xs={4}>
              <div className={"form-group"}>
                <label className={"font-weight-bold"}>&nbsp;</label>
                <Select
                  value={this.state.rest_ecg_2_id.value}
                  options={this.props.rest_ecgs_2}
                  menuPlacement={"auto"}
                  isClearable={true}
                  onChange={(event) => {
                    this.updateForm(event, "rest_ecg_2_id");
                  }}
                />
              </div>
            </Col>
          </Row>
        )}
        {this.props.reportEntity.report_type.slug === "exercise" && (
          <Row>
            <Col>
              <div className={"form-group"}>
                <label className={"font-weight-bold"}>Stress Response</label>
                <Select
                  value={this.state.exercise_response_id.value}
                  options={this.props.exercise_responses}
                  menuPlacement={"auto"}
                  isClearable={true}
                  onChange={(event) => {
                    this.updateForm(event, "exercise_response_id");
                  }}
                />
              </div>
            </Col>
            <div
              className={
                "flex-shrink-1 px-0 pt-4 font-weight-bold text-center mt-1"
              }
            >
              <label className={"font-weight-bold pt-2"}>
                and the patient experienced
              </label>
            </div>
            <Col>
              <div className={"form-group"}>
                <label className={"font-weight-bold"}>&nbsp;</label>
                <Select
                  value={this.state.exercise_response_experience_id.value}
                  options={this.props.exercise_response_experiences}
                  menuPlacement={"auto"}
                  isClearable={true}
                  onChange={(event) => {
                    this.updateForm(event, "exercise_response_experience_id");
                  }}
                />
              </div>
            </Col>
          </Row>
        )}
        {this.props.reportEntity.report_type.slug === "exercise" && (
          <Row>
            <Col xs={6}>
              <div className={"form-group"}>
                <label className={"font-weight-bold"}>Arrhythmias</label>
                <Select
                  value={this.state.arrhythmia_id.value}
                  options={this.props.arrhythmias}
                  menuPlacement={"auto"}
                  isClearable={true}
                  onChange={(event) => {
                    this.updateForm(event, "arrhythmia_id");
                  }}
                />
              </div>
            </Col>
          </Row>
        )}

        {this.props.reportEntity.report_type.slug === "regadenoson" && (
          <React.Fragment>
            <div className={"form-group mb-0"}>
              <label className={"font-weight-bold mb-0"}>Reponses</label>
            </div>
            <Row>
              <div
                className={
                  "flex-shrink-1 pl-3 pr-0 font-weight-bold text-center mt-1"
                }
              >
                <label className={"font-weight-bold pt-2"}>
                  The patient was noted to have{" "}
                </label>
              </div>
              <Col xs={4}>
                <div className={"form-group pt-2"}>
                  <Select
                    value={this.state.rest_ecg_2_id.value}
                    options={this.props.rest_ecgs_2}
                    menuPlacement={"auto"}
                    isClearable={true}
                    onChange={(event) => {
                      this.updateForm(event, "rest_ecg_2_id");
                    }}
                  />
                </div>
              </Col>
              <div
                className={
                  "flex-shrink-1 px-0 font-weight-bold text-center mt-1"
                }
              >
                <label className={"font-weight-bold pt-2"}>
                  {" "}
                  during Lexiscan.
                </label>
              </div>
            </Row>
            <Row>
              <div
                className={
                  "flex-shrink-1 pl-3 pr-0 font-weight-bold text-center mt-1"
                }
              >
                <label className={"font-weight-bold pt-2"}>
                  The patient was noted to have{" "}
                </label>
              </div>
              <Col xs={4}>
                <div className={"form-group pt-2"}>
                  <Select
                    value={this.state.exercise_response_experience_id.value}
                    options={this.props.exercise_response_experiences}
                    menuPlacement={"auto"}
                    isClearable={true}
                    onChange={(event) => {
                      this.updateForm(event, "exercise_response_experience_id");
                    }}
                  />
                </div>
              </Col>
              <div
                className={
                  "flex-shrink-1 px-0 font-weight-bold text-center mt-1"
                }
              >
                <label className={"font-weight-bold pt-2"}>
                  {" "}
                  during Lexiscan.
                </label>
              </div>
            </Row>
            <Row>
              <div
                className={
                  "flex-shrink-1 pl-3 pr-0 font-weight-bold text-center mt-1"
                }
              >
                <label className={"font-weight-bold pt-2"}>
                  Two minutes into the Lexiscan infusion, the patient had
                </label>
              </div>
              <Col xs={4}>
                <div className={"form-group pt-2"}>
                  <input
                    value={this.state.lexiscan_infusion.value}
                    className={"form-control"}
                    onChange={(event) => {
                      this.updateForm(event, "lexiscan_infusion");
                    }}
                  />
                </div>
              </Col>
              <div
                className={
                  "flex-shrink-1 px-0 font-weight-bold text-center mt-1"
                }
              >
                <label className={"font-weight-bold pt-2"}>
                  {" "}
                  mCi of Sestamiby injected
                </label>
              </div>
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };
}
export default ProcedureForm;
