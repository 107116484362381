import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router";

import EditReportModal from "components/Elements/Modals/EditReport";
import StatusBadge from "components/Elements/Reports/StatusBadge";
import NextStageButton from "components/Elements/Reports/NextStageButton";

// Cards
import ReportCardHeader from "components/Elements/Reports/CardHeader";
import BasicInfo from "./BasicInfo/BasicInfo";

import Procedure from "./Procedure/Procedure";
import PharmacologicProcedure from "./Procedure/PharmacologicProcedure";
import PetProcedure from "./Procedure/PetProcedure";
import ProcedureForm from "./ProcedureForm/ProcedureForm";
import PharmacologicProcedureForm from "./ProcedureForm/PharmacologicProcedureForm";
import PetProcedureForm from "./ProcedureForm/PetProcedureForm";

import Findings from "./Findings/Findings";
import FindingsForm from "./FindingsForm/FindingsForm";
import PetFindingsForm from "./FindingsForm/PetFindingsForm";
import Conclusions from "./Conclusions/Conclusions";
import ConclusionsForm from "./ConclusionsForm/ConclusionsForm";
import Activities from "components/Elements/Reports/Activities";

// Status Modals
import ReportStatusModal from "components/Elements/Modals/ReportStatus";
import DeleteModal from "components/Elements/Modals/Confirm";
import AmendModal from "components/Elements/Modals/Confirm";

import axiosInstance from "axios-instance";

class ExerciseView extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.findingChild = React.createRef();
    this.conclusionChild = React.createRef();

    const authData = JSON.parse(localStorage.getItem("auth"));
    this.state = {
      measurements: {},
      showBasicInfo: true,
      showProcedure: true,
      showProcedureForm: false,
      showFindings: true,
      showFindingsForm: false,
      showConclusions: true,
      showConclusionsForm: false,
      editModalActive: false,
      statusModalActive: false,
      allowAmend:
        authData.user.role === "Physician" || authData.user.role === "Manager",
    };
  }

  toggle = (modalType) => {
    this.setState((prevState) => ({
      [modalType]: !prevState[modalType],
    }));
  };

  deleteAction = () => {
    const { entity } = this.props;
    axiosInstance()
      .delete(`/reports/${entity.id}.json`)
      .then((response) => {
        this.props.history.push(`/`);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  amendAction = () => {
    this.props.amendAction(() => {
      this.setState({
        showAmendModal: false,
      });
    });
  };

  saveProcedure = () => {
    this.child.onSubmit((data) => {
      this.props.getEntity();
      this.toggle("showProcedureForm");
    });
  };

  saveFindings = () => {
    this.findingChild.onSubmit((data) => {
      this.props.getEntity();
      this.toggle("showFindingsForm");
    });
  };

  saveConclusions = () => {
    this.conclusionChild.onSubmit((data) => {
      this.props.getEntity();
      this.toggle("showConclusionsForm");
    });
  };

  render = () => {
    const { entity } = this.props;
    const {
      showBasicInfo,
      showProcedure,
      showFindings,
      showConclusions,
    } = this.state;

    const isLocked = entity.status === 2;
    return (
      <div className={"pb-5"}>
        <div className={"d-flex align-items-center pt-3"}>
          <h3 className={"mr-2 mb-0"}>Report-{entity.id}</h3>
          <div className={"flex-grow-1"}>
            <StatusBadge status={entity.status} style={{ fontSize: "13px" }} />
          </div>
          <div>
            <UncontrolledDropdown className={"mr-3"}>
              <DropdownToggle caret color={"outline-primary"} className={"btn"}>
                More Options
              </DropdownToggle>
              <DropdownMenu right>
                {entity.status === 2 && this.state.allowAmend && (
                  <span
                    onClick={() => this.toggle("showAmendModal")}
                    className={"dropdown-item d-block pointer"}
                  >
                    Amend Report
                  </span>
                )}
                {entity.status < 2 && entity.status !== -1 && (
                  <span
                    onClick={() => this.toggle("showDeleteModal")}
                    className={"dropdown-item d-block pointer"}
                  >
                    Delete
                  </span>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <div>
            <a
              href={
                axiosInstance().defaults.baseURL +
                "reports/preview/" +
                entity.id
              }
              className={"btn btn-outline-primary mr-2"}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Preview
            </a>
          </div>
          <div>
            <NextStageButton
              entity={entity}
              onClick={() => this.toggle("statusModalActive")}
            />
          </div>
        </div>

        <Row className={"pt-3"}>
          <Col>
            <Card>
              <CardHeader className={"d-flex p-1 align-items-center"}>
                <div
                  className={"pointer py-2 px-3 flex-grow-1"}
                  onClick={() => {
                    this.setState((prevState) => ({
                      showBasicInfo: !prevState.showBasicInfo,
                    }));
                  }}
                >
                  Basic Info
                  {isLocked && (
                    <FontAwesomeIcon
                      className={"ml-2"}
                      icon={["fas", "lock"]}
                    />
                  )}
                  {showBasicInfo ? (
                    <FontAwesomeIcon
                      className={"ml-3"}
                      icon={["fas", "chevron-up"]}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className={"ml-3"}
                      icon={["fas", "chevron-down"]}
                    />
                  )}
                </div>
                {!isLocked && (
                  <Button
                    color={"primary"}
                    size={"sm px-4 mr-2"}
                    onClick={() => this.toggle("editModalActive")}
                  >
                    Edit
                  </Button>
                )}
              </CardHeader>
              {showBasicInfo && (
                <CardBody>
                  <BasicInfo
                    entity={entity}
                    isLocked={isLocked}
                    technicianLabel={"Nuclear Technician"}
                  />
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        {/* Procedure */}
        <Row className={"pt-3"}>
          <Col>
            <Card>
              <ReportCardHeader
                title={"Procedure"}
                isLocked={isLocked}
                showContent={showProcedure}
                showEdit={this.state.showProcedureForm}
                onSaveClick={this.saveProcedure}
                onEditClick={() => this.toggle("showProcedureForm")}
                onHeaderClick={() => this.toggle("showProcedure")}
              />
              {showProcedure && (
                <CardBody>
                  {[3].includes(entity.report_type_id) && (
                    <React.Fragment>
                      {!this.state.showProcedureForm && (
                        <Procedure
                          {...entity.report_procedure}
                          reportEntity={entity}
                        />
                      )}
                      {this.state.showProcedureForm && (
                        <ProcedureForm
                          childRef={(ref) => (this.child = ref)}
                          patientAge={entity.age}
                          entity={entity.report_procedure}
                          reportEntity={entity}
                          reportId={entity.id}
                          {...this.props.procedureOptions}
                        />
                      )}
                    </React.Fragment>
                  )}

                  {[2, 4].includes(entity.report_type_id) && (
                    <React.Fragment>
                      {!this.state.showProcedureForm && (
                        <PharmacologicProcedure
                          {...entity.report_procedure}
                          reportEntity={entity}
                        />
                      )}
                      {this.state.showProcedureForm && (
                        <PharmacologicProcedureForm
                          childRef={(ref) => (this.child = ref)}
                          patientAge={entity.age}
                          entity={entity.report_procedure}
                          reportEntity={entity}
                          reportId={entity.id}
                          {...this.props.procedureOptions}
                        />
                      )}
                    </React.Fragment>
                  )}

                  {[5].includes(entity.report_type_id) && (
                    <React.Fragment>
                      {!this.state.showProcedureForm && (
                        <PetProcedure
                          {...entity.report_procedure}
                          reportEntity={entity}
                        />
                      )}
                      {this.state.showProcedureForm && (
                        <PetProcedureForm
                          childRef={(ref) => (this.child = ref)}
                          entity={entity.report_procedure}
                          reportEntity={entity}
                          reportId={entity.id}
                          {...this.props.procedureOptions}
                        />
                      )}
                    </React.Fragment>
                  )}
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        {/* Findings */}
        <Row className={"pt-3"}>
          <Col>
            <Card>
              <CardHeader className={"d-flex p-1 align-items-center"}>
                <div
                  className={"pointer py-2 px-3 flex-grow-1"}
                  onClick={() => {
                    this.setState((prevState) => ({
                      showFindings: !prevState.showFindings,
                    }));
                  }}
                >
                  Findings
                  {isLocked && (
                    <FontAwesomeIcon
                      className={"ml-2"}
                      icon={["fas", "lock"]}
                    />
                  )}
                  {showFindings ? (
                    <FontAwesomeIcon
                      className={"ml-3"}
                      icon={["fas", "chevron-up"]}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className={"ml-3"}
                      icon={["fas", "chevron-down"]}
                    />
                  )}
                </div>
                {this.state.showFindingsForm === true ? (
                  <Button
                    color={"success"}
                    size={"sm px-4 mr-2"}
                    onClick={this.saveFindings}
                  >
                    Save
                  </Button>
                ) : (
                  <React.Fragment>
                    {!isLocked && (
                      <Button
                        color={"primary"}
                        size={"sm px-4 mr-2"}
                        onClick={() => this.toggle("showFindingsForm")}
                      >
                        Edit
                      </Button>
                    )}
                  </React.Fragment>
                )}
              </CardHeader>
              {showFindings && (
                <CardBody>
                  <React.Fragment>
                    {!this.state.showFindingsForm && (
                      <Findings
                        {...entity.report_exercise_finding}
                        findings={this.props.findings}
                        isPetType={[5].includes(entity.report_type_id)}
                      />
                    )}
                    {this.state.showFindingsForm && (
                      <React.Fragment>
                        {[5].includes(entity.report_type_id) ? (
                          <PetFindingsForm
                            entity={entity.report_exercise_finding}
                            reportId={entity.id}
                            findings={this.props.findings}
                            childRef={(ref) => (this.findingChild = ref)}
                          />
                        ) : (
                          <FindingsForm
                            entity={entity.report_exercise_finding}
                            reportId={entity.id}
                            findings={this.props.findings}
                            childRef={(ref) => (this.findingChild = ref)}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        {/* Conclusions */}
        <Row className={"pt-3"}>
          <Col>
            <Card>
              <CardHeader className={"d-flex p-1 align-items-center"}>
                <div
                  className={"pointer py-2 px-3 flex-grow-1"}
                  onClick={() => {
                    this.setState((prevState) => ({
                      showConclustions: !prevState.showConclustions,
                    }));
                  }}
                >
                  Conclusions
                  {isLocked && (
                    <FontAwesomeIcon
                      className={"ml-2"}
                      icon={["fas", "lock"]}
                    />
                  )}
                  {showConclusions ? (
                    <FontAwesomeIcon
                      className={"ml-3"}
                      icon={["fas", "chevron-up"]}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className={"ml-3"}
                      icon={["fas", "chevron-down"]}
                    />
                  )}
                </div>
                {this.state.showConclusionsForm === true ? (
                  <Button
                    color={"success"}
                    size={"sm px-4 mr-2"}
                    onClick={this.saveConclusions}
                  >
                    Save
                  </Button>
                ) : (
                  <React.Fragment>
                    {!isLocked && (
                      <Button
                        color={"primary"}
                        size={"sm px-4 mr-2"}
                        onClick={() => this.toggle("showConclusionsForm")}
                      >
                        Edit
                      </Button>
                    )}
                  </React.Fragment>
                )}
              </CardHeader>
              {![5].includes(entity.report_type_id) && showConclusions && (
                <CardBody>
                  {!this.state.showConclusionsForm && (
                    <Conclusions
                      {...entity.report_exercise_conclusion}
                      report_exercise_finding={{
                        ...entity.report_exercise_finding,
                      }}
                      findings={this.props.findings}
                      conclusions={this.props.conclusions}
                      conclusionType={this.props.conclusionType}
                    />
                  )}
                  {this.state.showConclusionsForm && (
                    <ConclusionsForm
                      entity={entity.report_exercise_conclusion}
                      report_exercise_finding={{
                        ...entity.report_exercise_finding,
                      }}
                      findings={this.props.findings}
                      reportId={entity.id}
                      conclusions={this.props.conclusions}
                      conclusionType={this.props.conclusionType}
                      childRef={(ref) => (this.conclusionChild = ref)}
                    />
                  )}
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        <Row className={"pt-3 pb-5"}>
          <Col>
            <Activities
              show={this.state.showActivities}
              toggle={() => {
                this.setState((prevState) => ({
                  showActivities: !prevState.showActivities,
                }));
              }}
              entities={entity.report_activities}
            />
          </Col>
        </Row>

        {this.state.editModalActive === true && (
          <EditReportModal
            isActive={this.state.editModalActive}
            toggle={() => this.toggle("editModalActive")}
            technicianLabel={"Nuclear Technician"}
            entity={entity}
            getEntity={this.props.getEntity}
          />
        )}
        {this.state.statusModalActive && (
          <ReportStatusModal
            isActive={this.state.statusModalActive}
            toggle={() => this.toggle("statusModalActive")}
            entity={this.props.entity}
            getEntity={this.props.getEntity}
          />
        )}

        {this.state.showDeleteModal && (
          <DeleteModal
            isActive={this.state.showDeleteModal}
            toggle={
              this.state.showDeleteModal !== true
                ? null
                : () => this.toggle("showDeleteModal")
            }
            title={"Confirmation"}
            body={
              "Please confirm to delete they report. All report data will be lost and this action cannot be reversed."
            }
            confirmAction={this.deleteAction}
            confirmLabel={"Yes, delete this report"}
            confirmClass={"danger"}
          />
        )}

        {this.state.showAmendModal && (
          <AmendModal
            isActive={this.state.showAmendModal}
            toggle={
              this.state.showAmendModal !== true
                ? null
                : () => this.toggle("showAmendModal")
            }
            title={"Confirm"}
            body={
              "Please confirm to amend this report. This page will be unlocked. The report will need to be" +
              " re-finalized."
            }
            confirmAction={this.amendAction}
            confirmLabel={"Yes, I’d like to amend this report"}
            confirmClass={"danger"}
          />
        )}
      </div>
    );
  };
}
export default withRouter(ExerciseView);
