import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Container } from "reactstrap";

import axiosInstance from "axios-instance";

import EchoView from "./EchoView/EchoView";
import ExerciseView from "./ExerciseView/ExerciseView";
import VascularView from "./VascularView/VascularView";

class ViewReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      entity: {},
      measurements: {},
      findings: {},
      procedureOptions: {},
      notFound: false,
    };

    this.getEntity();
  }

  getEntity = (entity) => {
    if (entity !== undefined) {
      this.setState({
        entity: entity,
      });
      return true;
    }
    axiosInstance()
      .get(`/reports/${this.props.id}.json`)
      .then((response) => {
        this.setState({
          entity: response.data.entity,
          findings: response.data.findings,
          conclusions: response.data.conclusions,
          conclusionType: response.data.conclusionType,
          procedureOptions: response.data.procedureOptions,
          loaded: true,
        });
      })
      .catch((err) => {
        this.setState({
          notFound: true,
          loaded: true,
        });
      });
  };

  toggle = (modalType) => {
    this.setState((prevState) => ({
      [modalType]: !prevState[modalType],
    }));
  };

  saveSummaryItem = (body, callback) => {
    axiosInstance()
      .post(`/reports/save-summaries/${this.props.id}.json`, {
        body: body,
      })
      .then((response) => {
        const entity = JSON.parse(JSON.stringify(this.state.entity));
        entity.report_summaries = response.data;
        this.setState({
          entity: entity,
        });
        if (callback !== undefined) {
          callback();
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  toggleSummaryItem = (body, callback) => {
    axiosInstance()
      .post(`/reports/toggle-summaries/${this.props.id}.json`, {
        body: body,
      })
      .then((response) => {
        const entity = JSON.parse(JSON.stringify(this.state.entity));
        entity.report_summaries = response.data;
        this.setState({
          entity: entity,
        });
        if (callback !== undefined) {
          callback();
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  deleteSummaryItem = (itemId, callback) => {
    axiosInstance()
      .post(`/reports/delete-summaries/${this.props.id}.json`, {
        item_id: itemId,
      })
      .then((response) => {
        const entity = JSON.parse(JSON.stringify(this.state.entity));
        entity.report_summaries = response.data;
        this.setState(
          {
            entity: entity,
          },
          callback
        );
      })
      .catch((err) => {
        alert(err);
      });
  };

  saveSummaryOrder = (entities) => {
    const entity = JSON.parse(JSON.stringify(this.state.entity));
    entity.report_summaries = entities;
    this.setState(
      {
        entity: entity,
      },
      () =>
        axiosInstance()
          .post(`/reports/save-summaries-order/${this.props.id}.json`, {
            entities: entities,
          })
          .then((response) => {
            const entity = JSON.parse(JSON.stringify(this.state.entity));
            entity.report_summaries = response.data;
            this.setState({
              entity: entity,
            });
          })
          .catch((err) => {
            alert(err);
          })
    );
  };

  saveIsNormalEcho = (isNormalEcho) => {
    axiosInstance()
      .post(`/reports/save-normal-echo/${this.props.id}.json`, {
        is_normal_echo: isNormalEcho,
      })
      .then((response) => {
        const entity = JSON.parse(JSON.stringify(this.state.entity));
        entity.is_normal_echo = isNormalEcho;
        this.setState({
          entity: entity,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  saveIsNormalFindings = (isNormalEcho, callback) => {
    axiosInstance()
      .post(`/reports/save-normal-findings/${this.props.id}.json`, {
        is_normal_findings: isNormalEcho,
      })
      .then((response) => {
        this.setState(
          {
            entity: response.data,
          },
          () => {
            callback();
          }
        );
      })
      .catch((err) => {
        alert(err);
      });
  };

  amendAction = (callback) => {
    axiosInstance()
      .get(`/reports/amend/${this.props.id}.json`)
      .then((response) => {
        this.setState(
          {
            entity: response.data.entity,
          },
          () => {
            callback();
          }
        );
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  render = () => {
    const { entity } = this.state;
    return (
      <Container>
        <Helmet>
          <title>NewportX | Report View</title>
        </Helmet>
        {this.state.loaded && (
          <React.Fragment>
            {this.state.notFound && (
              <h1 className={"text-center pt-4"}>Not Found</h1>
            )}
            {entity.report_type_id === 1 && (
              <EchoView
                id={entity.id}
                entity={entity}
                getEntity={this.getEntity}
                findings={this.state.findings}
                saveSummaryOrder={this.saveSummaryOrder}
                amendAction={this.amendAction}
                deleteSummaryItem={this.deleteSummaryItem}
                saveSummaryItem={this.saveSummaryItem}
                toggleSummaryItem={this.toggleSummaryItem}
                saveIsNormalEcho={this.saveIsNormalEcho}
                saveIsNormalFindings={this.saveIsNormalFindings}
              />
            )}
            {[2, 3, 4, 5].includes(entity.report_type_id) && (
              <ExerciseView
                entity={entity}
                getEntity={this.getEntity}
                procedureOptions={this.state.procedureOptions}
                findings={this.state.findings}
                amendAction={this.amendAction}
                conclusions={this.state.conclusions}
                conclusionType={this.state.conclusionType}
              />
            )}
            {[6].includes(entity.report_type_id) && (
              <VascularView
                entity={entity}
                getEntity={this.getEntity}
                amendAction={this.amendAction}
              />
            )}
          </React.Fragment>
        )}
      </Container>
    );
  };
}
export default ViewReport;
