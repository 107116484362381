import React from "react";
import { displayValue } from "functions";
import { Row, Col } from "reactstrap";

export default (props) => {
  console.log(props);
  return (
    <React.Fragment>
      <div className={"pb-2"}>
        <div className={"font-weight-bold"}>Administration</div>
        <div>
          {props.is_custom_administration === 1 ? (
            <React.Fragment>
              {displayValue(props.custom_administration_location)}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {displayValue(props.administration_location)}
            </React.Fragment>
          )}{" "}
          by {displayValue(props.initials)}
        </div>
      </div>
      <div className={"pb-2"}>
        <div className={"font-weight-bold"}>Rest Dose</div>
        <div>
          {displayValue(props.rest_dose_amount)} mCi of{" "}
          {displayValue(props.rest_dose_type)}
        </div>
      </div>
      <div className={"pb-2"}>
        <div className={"font-weight-bold"}>Stress Dose</div>
        <div>
          {(props.stress_dose_statement & 1) > 0 && (
            <React.Fragment>
              0.4mg of regadenoson followed by a normal saline flush then{" "}
              {displayValue(props.stress_dose_amount)} mCi of{" "}
              {displayValue(props.stress_dose_type)} 10-20 seconds later
            </React.Fragment>
          )}
          {(props.stress_dose_statement & 2) > 0 && (
            <React.Fragment>
              adenosine at 140mcg/kg for a total of four minutes receiving{" "}
              {displayValue(props.stress_dose_amount)} mCi of{" "}
              {displayValue(props.stress_dose_type)} after two minutes
            </React.Fragment>
          )}
          {(props.stress_dose_statement & 4) > 0 && (
            <React.Fragment>
              Dobutamine at {displayValue(props.stress_dose_amount)} mcg/kg/min
              and increased at 2-3 minute intervals to a maximum dose of{" "}
              {displayValue(props.stress_dose_free_1)} mcg/kg/min.{" "}
              {displayValue(props.stress_dose_free_2)} mCi of{" "}
              {displayValue(props.stress_dose_type)} was administered at peak
              stress and dobutamine was continued for one minute longer.{" "}
              {displayValue(props.stress_dose_free_3)} mg of atropine was also
              used.
            </React.Fragment>
          )}
          {(props.stress_dose_statement & 8) > 0 && (
            <React.Fragment>
              {displayValue(props.stress_dose_amount)} mCi of{" "}
              {displayValue(props.stress_dose_type)}
              <div>
                <div>
                  <div>
                    {displayValue(props.stress_test_protocol)} protocol for{" "}
                    {displayValue(props.stress_test_length)} minutes and
                    achieved a maximum heart rate of{" "}
                    {displayValue(props.stress_test_heart_rate)} bpm which was{" "}
                    {displayValue(props.stress_test_percentage)} %
                  </div>
                </div>
              </div>
              <Row>
                <Col>
                  Rest Heart Rate (bpm):{" "}
                  {displayValue(props.rest_heart_rate_amount)}
                </Col>
                <Col>
                  Rest Blood Pressure (mmHg):{" "}
                  {displayValue(props.rest_blood_pressure_amount)}
                </Col>
                <Col>
                  Stress Blood Pressure (mmHg):{" "}
                  {displayValue(props.stress_blood_pressure_amount)}
                </Col>
              </Row>
            </React.Fragment>
          )}
        </div>
      </div>
      {(props.stress_dose_statement & 4) > 0 && (
        <div className={"pb-2"}>
          <div className={"font-weight-bold"}>Maximum Heart Rate</div>
          <div>
            The maximum heart rate achieved was{" "}
            {displayValue(props.stress_test_heart_rate)} which represents{" "}
            {displayValue(props.stress_test_percentage)}% of predicted.
          </div>
        </div>
      )}

      <div className={"pb-2"}>
        <div className={"font-weight-bold"}>Limits Exceeded</div>
        <div>{props.limit_exceeded === 1 ? "did" : "did not"}</div>
      </div>
      <div className={"pb-2"}>
        <div className={"font-weight-bold"}>Low Level Exercise</div>
        <div>
          Low level exercise {displayValue(props.low_level_exercise)} used.
        </div>
      </div>
      <React.Fragment>
        <div className={"pb-2"}>
          <div className={"font-weight-bold"}>Rest ECG</div>
          <div>
            {props.rest_ecg_1_free_type !== null &&
            props.rest_ecg_1_free_type.length > 0
              ? displayValue(props.rest_ecg_1_free_type)
              : displayValue(props.rest_ecg1)}{" "}
            and{" "}
            <strong>
              {props.rest_ecg_2_free_type !== null &&
              props.rest_ecg_2_free_type.length > 0
                ? displayValue(props.rest_ecg_2_free_type)
                : displayValue(props.rest_ecg2)}
            </strong>
          </div>
        </div>
        <div className={"pb-2"}>
          <div className={"font-weight-bold"}>Stress Response</div>
          <div>
            {displayValue(props.exercise_response)} and the patient experienced{" "}
            {props.exercise_response_experience_free_type !== null &&
            props.exercise_response_experience_free_type.length > 0
              ? displayValue(props.exercise_response_experience_free_type)
              : displayValue(props.exercise_response_experience)}
          </div>
        </div>
        <div className={"pb-2"}>
          <div className={"font-weight-bold"}>Arrhythmias</div>
          <div>
            {props.arrhythmia_free_type !== null &&
            props.arrhythmia_free_type.length > 0
              ? displayValue(props.arrhythmia_free_type)
              : displayValue(props.arrhythmia)}
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};
