import validator from 'validator';

const required = value => {
  if (value === null || !value.toString().trim().length) {
    // We can return string or jsx as the 'error' prop for the validated Component
    return 'Please fill out this field.';
  }
};

const email = value => {
  if (!validator.isEmail(value)) {
    // We can return string or jsx as the 'error' prop for the validated Component
    return 'Please enter a valid email address.';
  }
};

const multiEmail = value => {
  if (value.length > 0) {
    let emails = value.split(',');
    for (let i = 0; i < emails.length; i++) {
      if (!validator.isEmail(emails[i])) {
        return 'Please enter one or more valid email addresses here.';
      }
    }
  }
};

const passwordLength = value => {
  if (value.length < 8 || value.length > 50) {
    // We can return string or jsx as the 'error' prop for the validated Component
    return '8 characters miminum. 50 characters maximum.';
  }
};

const maxLength = (value, max) => {
  if (value.length > max) {
    // We can return string or jsx as the 'error' prop for the validated Component
    return max + ' characters maximum.';
  }
};

const passwordCompare = (value, password) => {
  if (value !== password) {
    // We can return string or jsx as the 'error' prop for the validated Component
    return 'Must match your password.';
  }
};

export {
  required,
  email,
  passwordLength,
  passwordCompare,
  maxLength,
  multiEmail
};
