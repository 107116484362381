import React, { Component } from "react";
import Select from "react-select";
import InputGroup from "components/Elements/InputGroup/InputGroup";
import axiosInstance from "axios-instance";
import LabelInput from "components/Elements/LabelInput/LabelInput";
import { Button } from "reactstrap";
import ConfirmModal from "components/Elements/Modals/Confirm";
import Sentences from "./Sentences";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AreaChart from "./AreaChart";

const _objectWithoutProperties = (obj, keys) => {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
};

class FindingsForm extends Component {
  constructor(props) {
    super(props);
    const fields = {};
    this.chartRef = React.createRef();

    Object.keys(this.props.findings).map((fieldNo) => {
      let key = `field_${fieldNo}`;
      fields[key] = {
        value:
          props.entity !== null &&
          props.entity[key] !== undefined &&
          props.entity[key] !== null
            ? isNaN(props.entity[key])
              ? props.findings[fieldNo].find(
                  (x) => x.label === props.entity[key]
                )
              : props.entity[key]
            : "",
        visited: false,
        error: false,
      };
      return null;
    });
    [
      "field_17",
      "field_30",
      "field_31",
      "field_7_free_type",
      "field_13_free_type",
      "field_27_free_type",
      "field_27_free_type",
      "item_2_free_type",
    ].forEach((value, index) => {
      if (props.entity[value] !== undefined) {
        fields[value] = {
          value: props.entity[value],
          visited: false,
          error: false,
        };
      }
    });

    fields["sentence_1"] = [];
    fields["sentence_2"] = [];
    fields["sentence_3"] = [];
    fields["sentence_4"] = [];
    let index = 0;
    props.entity.report_exercise_finding_sentences.map((sentence) => {
      const arrKey = sentence.type;
      const tmpSentence = {
        type: arrKey,
        field_1: sentence.field_1,
        field_2: sentence.field_2,
        field_2_free_type: sentence.field_2_free_type,
        field_3: sentence.field_3,
        field_4: sentence.field_4,
        field_5: sentence.field_5,
        field_6: sentence.field_6,
        field_7: sentence.field_7,
        sort_order: index,
      };
      index++;
      fields["sentence_" + arrKey].push(tmpSentence);
      return null;
    });

    this.state = { ...fields, activeSentence: false, areaChartActive: false };
  }

  componentDidMount() {
    const { childRef } = this.props;
    if (childRef !== undefined) {
      childRef(this);
    }
  }
  componentWillUnmount() {
    const { childRef } = this.props;
    if (childRef !== undefined) {
      childRef(undefined);
    }
  }

  updatePieValue(activeItems) {
    const areaLabels = [];
    let findings = [];
    let key = 0;
    for (const datasetIndex in activeItems) {
      for (let index = 0; index < activeItems[datasetIndex].length; index++) {
        key = activeItems[datasetIndex][index];
        if (datasetIndex === "0") {
          findings = this.props.findings["17"].slice(0, 6).reverse();
        } else if (datasetIndex === "1") {
          findings = this.props.findings["17"].slice(6, 12).reverse();
        } else if (datasetIndex === "2") {
          findings = this.props.findings["17"].slice(12, 16).reverse();
        } else {
          findings = this.props.findings["17"].slice(16, 17).reverse();
        }
        areaLabels.push(findings[key].label);
      }
    }

    let pieValue = "";
    if (areaLabels.length === 1) {
      pieValue = areaLabels.pop();
    } else if (areaLabels.length > 1) {
      const last = areaLabels.pop();
      pieValue = areaLabels.join(", ") + " and " + last;
    }
    const inputData = {
      value: pieValue,
      visited: true,
      error: false,
    };

    this.setState({
      field_17: inputData,
      areaChartActive: false,
    });
  }

  updateForm = (event, name) => {
    let value =
      event !== null && event.target !== undefined ? event.target.value : event;

    if (name === "field_20") {
      value = parseInt(this.state.field_20.value) + value;
    }

    const inputData = {
      value: value,
      visited: true,
      error: false,
    };

    this.setState({
      [name]: inputData,
    });
  };

  onSubmit = (callback) => {
    const formData = JSON.parse(JSON.stringify(this.state));
    const postData = { findings: {} };
    let isValid = true;

    Object.keys(formData).map((key) => {
      if (formData[key] === null || formData[key].value === undefined) {
        return null;
      }
      postData["findings"][key] = formData[key].value;
      return null;
    });

    postData["sentence_1"] = formData["sentence_1"];
    postData["sentence_2"] = formData["sentence_2"];
    postData["sentence_3"] = formData["sentence_3"];
    postData["sentence_4"] = formData["sentence_4"];

    if (isValid) {
      axiosInstance()
        .post(
          `/reports/save-exercise-findings/${this.props.reportId}.json`,
          postData
        )
        .then((response) => {
          callback(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return false;
  };

  toggle = (modalType) => {
    this.setState((prevState) => ({
      [modalType]: !prevState[modalType],
    }));
  };

  onSentenceRemove = () => {
    const { key, index } = this.state.activeSentence;
    const formData = JSON.parse(
      JSON.stringify(this.state["sentence_" + index])
    );
    formData.splice(key, 1);

    const toUpdate = {
      confirmModalActive: false,
      activeSentence: null,
    };
    toUpdate[`sentence_${index}`] = formData;
    this.setState(toUpdate);
  };

  addSentence = (type) => {
    const formData = JSON.parse(JSON.stringify(this.state["sentence_" + type]));
    const newData = formData.map((item, key) => {
      return { ...item, sort_order: key };
    });
    newData.push({
      type: type,
      field_1: "",
      field_2: "",
      field_3: "",
      field_4: "",
      field_5: "",
      field_6: "",
      field_7: "",
      sort_order: formData.length + 1,
    });
    const toUpdate = {};
    toUpdate[`sentence_${type}`] = newData;
    this.setState(toUpdate);
  };

  updateSentence = (type, index, field, event) => {
    const value =
      event !== null && event.target !== undefined ? event.target.value : event;
    let formData = JSON.parse(JSON.stringify(this.state["sentence_" + type]));
    formData[index][field] = value.label !== undefined ? value.label : value;
    if (field === "field_2" && value === null) {
      formData[index]["field_2_free_type"] = value;
    }
    const toUpdate = {};
    toUpdate[`sentence_${type}`] = formData;
    this.setState(toUpdate);
  };

  render = () => {
    const fields = {};

    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: "30px",
        minHeight: "30px",
      }),
      singleValue: (styles) =>
        _objectWithoutProperties(styles, ["maxWidth", "position", "transform"]),
      menu: (styles) => ({
        ...styles,
        whiteSpace: "nowrap",
        width: "auto",
        right: 0,
      }),
    };

    Object.keys(this.props.findings).map((key) => {
      fields[key] =
        this.props.findings[key].length > 1 ? (
          (disabled) => (
            <div
              className={"d-inline-block px-2 mb-1"}
              style={{ minWidth: "160px" }}
            >
              <Select
                value={this.state[`field_${key}`].value}
                options={this.props.findings[key]}
                menuPlacement={"auto"}
                styles={customStyles}
                isClearable={true}
                isDisabled={disabled}
                autoSize={true}
                onChange={(event) => {
                  this.updateForm(event, `field_${key}`);
                }}
              />
            </div>
          )
        ) : (
          <span>{this.props.findings[key][0].label}</span>
        );
      return null;
    });

    const field14 = parseInt(this.state["field_14"].value) === 2;
    return (
      <div className={"position-relative"}>
        <ol>
          <li>
            Review of rotating images demonstrates {fields[1]()} patient motion
            during image acquisition at rest and {fields[2]()} patient motion
            during acquisition with stress.
            <br />
            Motion correction{" "}
            {this.state.field_3.value !== null &&
            this.state.field_3.value.label === "was not" ? (
              <React.Fragment>{fields[3]()} used.</React.Fragment>
            ) : (
              <React.Fragment>
                {fields[3]()} used for the {fields[4]()} images.
              </React.Fragment>
            )}{" "}
            <br />
            The overall study quality is {fields[5]()}.
          </li>
          <li>
            Abnormal extracardiac uptake
            {this.state.field_6.value !== null &&
            this.state.field_6.value.label === "is not" ? (
              <React.Fragment>{fields[6]()} noted.</React.Fragment>
            ) : (
              <React.Fragment>
                {fields[6]()} noted in the{" "}
                {fields[7](
                  this.state.field_7.value !== null &&
                    this.state.field_7.value.label === "[free type]"
                )}
                {this.state.field_7.value !== null &&
                  this.state.field_7.value.label === "[free type]" && (
                    <div className={"d-inline-block"}>
                      <div class={"d-flex align-items-center"}>
                        <LabelInput
                          type={"text"}
                          size={"sm"}
                          formGroupClass={"mb-0"}
                          className={"form-control form-control-sm"}
                          {...this.state.field_7_free_type}
                          onChange={(event) =>
                            this.updateForm(event, "field_7_free_type")
                          }
                        />
                        <FontAwesomeIcon
                          className={"mr-2 ml-2 pointer"}
                          icon={["fas", "times"]}
                          onClick={(event) => {
                            this.updateForm(null, "field_7_free_type");
                            this.updateForm(null, "field_7");
                          }}
                        />
                      </div>
                    </div>
                  )}
                .
              </React.Fragment>
            )}{" "}
            <Button
              color={"primary"}
              size={"sm"}
              className={"ml-2"}
              onClick={() => this.addSentence(4)}
            >
              Repeat
            </Button>
            <br />
            <Sentences
              entities={this.state.sentence_4}
              type={4}
              findings={this.props.findings}
              styles={customStyles}
              disabled={false}
              updateSentence={(type, key, field, event) =>
                this.updateSentence(type, key, field, event)
              }
              addSentence={(type) => this.addSentence(type)}
              onDelete={(index, key) => {
                this.setState(
                  {
                    activeSentence: {
                      index: index,
                      key: key,
                    },
                  },
                  () => {
                    this.toggle("confirmModalActive");
                  }
                );
              }}
            />
            The left ventricular size apears {fields[8]()}.<br />
            The right ventricular size appears {fields[9]()}.<br />
            There is {fields[10]()} left ventricular cavity dilitation noted
            with stress imaging.
            <br />
            Abnormal lung uptakes {fields[11]()} present.
            <br />
            There is decreased tracer uptake in the {fields[12]()} wall(s)
            consistent with{" "}
            {fields[13](
              this.state.field_13.value !== null &&
                this.state.field_13.value.label === "[free type]"
            )}
            {this.state.field_13.value !== null &&
              this.state.field_13.value.label === "[free type]" && (
                <div className={"d-inline-block"}>
                  <div className={"d-flex align-items-center"}>
                    <LabelInput
                      type={"text"}
                      size={"sm"}
                      formGroupClass={"mb-0"}
                      className={"form-control form-control-sm"}
                      {...this.state.field_13_free_type}
                      onChange={(event) =>
                        this.updateForm(event, "field_13_free_type")
                      }
                    />
                    <FontAwesomeIcon
                      className={"mr-2 ml-2 pointer"}
                      icon={["fas", "times"]}
                      onClick={(event) => {
                        this.updateForm(null, "field_13_free_type");
                        this.updateForm(null, "field_13");
                      }}
                    />
                  </div>
                </div>
              )}
            .
            <Button
              color={"primary"}
              size={"sm"}
              className={"ml-2"}
              onClick={() => this.addSentence(1)}
            >
              Repeat
            </Button>
            <Sentences
              entities={this.state.sentence_1}
              type={1}
              findings={this.props.findings}
              styles={customStyles}
              disabled={false}
              updateSentence={(type, key, field, event) =>
                this.updateSentence(type, key, field, event)
              }
              addSentence={(type) => this.addSentence(type)}
              onDelete={(index, key) => {
                this.setState(
                  {
                    activeSentence: {
                      index: index,
                      key: key,
                    },
                  },
                  () => {
                    this.toggle("confirmModalActive");
                  }
                );
              }}
            />
            <div className={"pt-1"}>
              <LabelInput
                type={"text"}
                size={"sm"}
                formGroupClass={"mb-0"}
                className={"form-control form-control-sm"}
                {...this.state.item_2_free_type}
                onChange={(event) => this.updateForm(event, "item_2_free_type")}
              />
            </div>
          </li>
          <li>
            <div className="checkbox text-left">
              <label>
                <input
                  type={"checkbox"}
                  checked={parseInt(this.state["field_14"].value) === 0}
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 0 : 2,
                      `field_14`
                    )
                  }
                />{" "}
                <span>
                  Otherwise, there is normal tracer uptake throughout the
                  myocardium.
                </span>
              </label>
            </div>
            <hr />
            <div className="checkbox mb-1 text-left">
              <label>
                <input
                  type={"checkbox"}
                  checked={parseInt(this.state["field_14"].value) === 1}
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 1 : 2,
                      `field_14`
                    )
                  }
                />{" "}
                <span>{fields[14]}</span>
              </label>
            </div>

            <React.Fragment>
              <hr />
              <div style={field14 ? {} : { color: "#b7b7b7" }}>
                Myocardial perfusion imaging demonstrates a{" "}
                {fields[15](!field14)} size perfusion abnormality of{" "}
                {fields[16](!field14)} intensity in the{" "}
                <span
                  className={field14 ? "btn-link pointer" : ""}
                  onClick={
                    field14 ? () => this.toggle("areaChartActive") : null
                  }
                >
                  {this.state.field_17.value !== null &&
                  this.state.field_17.value !== ""
                    ? this.state.field_17.value
                    : "Select Area"}
                </span>{" "}
                wall segment(s) that is {fields[18](!field14)}{" "}
                {this.state.field_18.value !== null &&
                  this.state.field_18.value.label === "reversible" && (
                    <React.Fragment>{fields["18_1"](!field14)}.</React.Fragment>
                  )}
                {this.state.field_18.value !== null &&
                  this.state.field_18.value.label === "fixed" && (
                    <React.Fragment>
                      {fields["18_2"](!field14)}
                      {this.state.field_18_2.value !== null &&
                        this.state.field_18_2.value.label === "with" && (
                          <React.Fragment>
                            a {fields[19](!field14)} amount of peri-infarct
                            ischemia
                          </React.Fragment>
                        )}
                      .
                    </React.Fragment>
                  )}
                {this.state.field_18.value !== null &&
                  (this.state.field_18.value.label === "partially reversible" ||
                    this.state.field_18.value.label === "mostly fixed") && (
                    <React.Fragment>.</React.Fragment>
                  )}
                {field14 && (
                  <Button
                    color={"primary"}
                    size={"sm"}
                    className={"ml-2"}
                    onClick={() => this.addSentence(2)}
                  >
                    Repeat
                  </Button>
                )}
                <Sentences
                  entities={this.state.sentence_2}
                  type={2}
                  findings={this.props.findings}
                  styles={customStyles}
                  disabled={!field14}
                  updateSentence={(type, key, field, event) =>
                    this.updateSentence(type, key, field, event)
                  }
                  addSentence={(type) => this.addSentence(type)}
                  onDelete={(index, key) => {
                    this.setState(
                      {
                        activeSentence: {
                          index: index,
                          key: key,
                        },
                      },
                      () => {
                        this.toggle("confirmModalActive");
                      }
                    );
                  }}
                />
              </div>
            </React.Fragment>

            <hr />
            <div className="checkbox mb-1 text-left">
              <label>
                <input
                  type={"checkbox"}
                  checked={parseInt(this.state["field_14"].value) === 3}
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 3 : 2,
                      `field_14`
                    )
                  }
                />{" "}
                <span>No Statement.</span>
              </label>
            </div>
          </li>
          <li>
            <React.Fragment>
              <div>
                <div className="radio mb-1 text-left">
                  <span>{fields[20]}</span>
                  {fields[21]()}
                </div>
                <div className="radio mb-1 text-left">
                  <label>
                    <input
                      type={"checkbox"}
                      name={"field_20"}
                      checked={
                        this.state["field_20"].value > 0 &&
                        (this.state["field_20"].value & 2) > 0
                      }
                      onChange={(event) =>
                        this.updateForm(
                          event.currentTarget.checked ? 2 : -2,
                          `field_20`
                        )
                      }
                    />{" "}
                    <span>{fields[22]}</span>
                  </label>
                </div>
                <div className="radio mb-1 text-left">
                  <span>{fields[23]}</span>
                  {fields[24]()}
                </div>
                <div className="radio mb-1 text-left">
                  <span>
                    Gated SPECT imaging was performed and demonstrates
                  </span>
                  {fields[26]()} of the{" "}
                  {fields[27](
                    this.state.field_27.value !== null &&
                      this.state.field_27.value.label === "[free type]"
                  )}
                  {this.state.field_27.value !== null &&
                    this.state.field_27.value.label === "[free type]" && (
                      <div className={"d-inline-block"}>
                        <div className={"d-flex align-items-center"}>
                          <LabelInput
                            type={"text"}
                            size={"sm"}
                            formGroupClass={"mb-0"}
                            className={"form-control form-control-sm"}
                            {...this.state.field_27_free_type}
                            onChange={(event) =>
                              this.updateForm(event, "field_27_free_type")
                            }
                          />
                          <FontAwesomeIcon
                            className={"mr-2 ml-2 pointer"}
                            icon={["fas", "times"]}
                            onClick={(event) => {
                              this.updateForm(null, "field_27_free_type");
                              this.updateForm(null, "field_27");
                            }}
                          />
                        </div>
                      </div>
                    )}{" "}
                  wall(s).
                  <Button
                    color={"primary"}
                    size={"sm"}
                    className={"ml-2"}
                    onClick={() => this.addSentence(3)}
                  >
                    Repeat
                  </Button>
                </div>
                <div>
                  <Sentences
                    entities={this.state.sentence_3}
                    type={3}
                    findings={this.props.findings}
                    styles={customStyles}
                    disabled={false}
                    updateSentence={(type, key, field, event) =>
                      this.updateSentence(type, key, field, event)
                    }
                    addSentence={(type) => this.addSentence(type)}
                    onDelete={(index, key) => {
                      this.setState(
                        {
                          activeSentence: {
                            index: index,
                            key: key,
                          },
                        },
                        () => {
                          this.toggle("confirmModalActive");
                        }
                      );
                    }}
                  />
                </div>
              </div>
              <hr />
              <div>
                {/* <div className="radio mb-1 text-left">
                    <label>
                      <input
                        type={"radio"}
                        name={"field_28"}
                        checked={this.state["field_28"].value === 28}
                        onChange={(event) =>
                          this.updateForm(
                            event.currentTarget.checked ? 28 : 0,
                            `field_28`
                          )
                        }
                      />{" "}
                      <span>{fields[28]}</span>
                    </label>
                  </div> */}
                <div className="radio mb-1 text-left">
                  <span>{fields[29]}</span>
                  <div className={"d-inline-block pl-2"}>
                    <InputGroup
                      append={"%"}
                      type={"text"}
                      required={false}
                      inputgroupclass={"input-group-sm"}
                      {...this.state.field_30}
                      onChange={(event) => this.updateForm(event, "field_30")}
                    />
                  </div>
                </div>
                <div>
                  <LabelInput
                    name={"Free Type"}
                    type={"text"}
                    {...this.state.field_31}
                    onChange={(event) => this.updateForm(event, "field_31")}
                  />
                </div>
              </div>
            </React.Fragment>
          </li>
        </ol>

        <AreaChart
          isActive={this.state.areaChartActive}
          toggle={() => this.toggle("areaChartActive")}
          confirmLabel={"Submit"}
          activeChartElement={this.state.field_17.value}
          findings={this.props.findings[17]}
          confirmAction={(activeItems) => this.updatePieValue(activeItems)}
        />

        <ConfirmModal
          isActive={this.state.confirmModalActive}
          toggle={() => this.toggle("confirmModalActive")}
          body={"Please confirm to delete this sentence."}
          confirmLabel={"Yes, delete this sentence"}
          confirmAction={this.onSentenceRemove}
        />
      </div>
    );
  };
}

export default FindingsForm;
