import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export default (props) => {
  return (
    <Modal isOpen={props.isActive} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        {props.title !== undefined ? props.title : "Confirmation"}
      </ModalHeader>
      <ModalBody className={"p-4"}>{props.body}</ModalBody>
      <ModalFooter className={"text-right justify-content-end"}>
        <Button color="light" onClick={props.toggle}>
          Cancel
        </Button>
        {props.submitting !== undefined && props.submitting === true ? (
          <Button
            color={
              props.confirmClass !== undefined ? props.confirmClass : "danger"
            }
            className="px-5"
            type="button"
            disabled
          >
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            &nbsp;&nbsp; Loading...
          </Button>
        ) : (
          <Button
            color={
              props.confirmClass !== undefined ? props.confirmClass : "danger"
            }
            onClick={props.confirmAction}
          >
            {props.confirmLabel}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
