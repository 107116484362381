import React, { Component } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import ConfirmModal from "components/Elements/Modals/Confirm";

const SortableItem = SortableElement(({ value, toggle }) => (
  <div
    className={"d-flex py-1 px-1 mb-1 border rounded align-items-center"}
    style={{ cursor: "grab" }}
  >
    <div>
      <FontAwesomeIcon className={"mr-2 ml-1"} icon={["fas", "ellipsis-v"]} />
    </div>
    <div className={"flex-grow-1 px-2"} style={{ wordBreak: "break-all" }}>
      <span
        dangerouslySetInnerHTML={{
          __html: value.body.replace(/\n/g, "<br>"),
        }}
      ></span>
    </div>
    <div>
      <Button
        size={"sm"}
        color={"danger"}
        onClick={() => toggle("confirmModalActive", value.id)}
      >
        <FontAwesomeIcon className={"pointer"} icon={["fas", "trash-alt"]} />
      </Button>
    </div>
  </div>
));

const SortableList = SortableContainer(({ items, toggle }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value.id}`}
          index={index}
          value={value}
          toggle={(modelType, itemId) => toggle(modelType, itemId)}
        />
      ))}
    </div>
  );
});

class Summaries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmModalActive: false,
      additionalSummary: "",
    };
  }

  toggle = (modalType, itemId) => {
    this.setState((prevState) => ({
      [modalType]: !prevState[modalType],
      deleteItemId: itemId,
    }));
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const entities = arrayMove(this.props.entities, oldIndex, newIndex);
    this.props.saveOrder(entities);
  };

  render = () => {
    const { isLocked } = this.props;
    return (
      <React.Fragment>
        <div className={"pb-2"}>
          <div className="checkbox text-left">
            <label>
              <input
                type={"checkbox"}
                disabled={isLocked}
                checked={this.props.isNormalEcho === 1}
                onChange={
                  isLocked
                    ? null
                    : (e) => {
                        this.props.saveIsNormalEcho(e.target.checked ? 1 : 0);
                      }
                }
              />
              &nbsp;&nbsp;This is a normal echo.
            </label>
          </div>
        </div>
        {isLocked ? (
          <ol className={"position-relative"}>
            {this.props.isNormalEcho === 1 && (
              <li key={"summary-item-normal"}>This is normal echo.</li>
            )}
            {this.props.entities.map((item) => {
              return <li key={"summary-item-" + item.id}>{item.body}</li>;
            })}
          </ol>
        ) : (
          <React.Fragment>
            {this.props.isNormalEcho === 1 && (
              <ol className={"position-relative mb-2"}>
                <li className={"mb-1"} key={"summary-item-normal"}>
                  This is normal echo.
                </li>
              </ol>
            )}
            <SortableList
              items={this.props.entities}
              distance={1}
              lockAxis={"y"}
              onSortEnd={this.onSortEnd}
              toggle={(modelType, itemId) => this.toggle(modelType, itemId)}
            />
          </React.Fragment>
        )}

        {!isLocked && (
          <React.Fragment>
            <div className="form-group mt-4">
              <label htmlFor="addition-summary">Add additional Summary</label>
              <textarea
                className="form-control"
                id="additional-summary"
                onChange={(event) => {
                  this.setState({
                    additionalSummary: event.target.value,
                  });
                }}
                value={this.state.additionalSummary}
              ></textarea>
            </div>
            <Button
              color={"primary"}
              onClick={() =>
                this.props.saveSummaryItem(this.state.additionalSummary, () =>
                  this.setState({
                    additionalSummary: "",
                  })
                )
              }
            >
              Add Summary
            </Button>
          </React.Fragment>
        )}
        <ConfirmModal
          isActive={this.state.confirmModalActive}
          toggle={() => this.toggle("confirmModalActive")}
          confirmLabel={"Yes, delete this summary statement"}
          body={"Please confirm to delete this summary statement."}
          confirmAction={() => {
            this.props.deleteSummaryItem(this.state.deleteItemId, () =>
              this.setState({
                confirmModalActive: false,
              })
            );
          }}
        />
      </React.Fragment>
    );
  };
}
export default Summaries;
