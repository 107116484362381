import React from 'react';
import Css from './ProgressBox.module.scss';

const progressBox = props => {
  return (
    <div className={Css.loader + ' d-flex align-items-center ' + props.loader}>
      <div className={Css.content}>
        {props.icon}
        {props.text}
      </div>
    </div>
  );
};

export default progressBox;
