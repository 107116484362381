import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
} from "reactstrap";
import LabelInput from "components/Elements/LabelInput/LabelInput";
import { required, maxLength } from "validation";
import axiosInstance from "axios-instance";

const fieldValidator = {
  first_name: [required, (value) => maxLength(value, 255)],
  last_name: [required, (value) => maxLength(value, 255)],
  suffix: [(value) => maxLength(value, 255)],
  fax: [(value) => maxLength(value, 64)],
};
class DeveloperModal extends React.Component {
  constructor(props) {
    super(props);
    const fields = {};

    Object.keys(fieldValidator).forEach((key) => {
      fields[key] = {
        value:
          props.entity[key] !== undefined && props.entity[key] !== null
            ? props.entity[key]
            : "",
        visited: false,
        error: false,
      };
    });

    this.state = { ...fields };
  }

  updateForm = (event, name) => {
    const inputData = {
      value: event.target.value,
      visited: true,
      error: false,
    };

    for (let i = 0; i < fieldValidator[name].length; i++) {
      const validationMsg = fieldValidator[name][i](inputData.value);
      if (validationMsg !== undefined) {
        inputData.error = validationMsg;
        break;
      }
    }

    this.setState({
      [name]: inputData,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const formData = JSON.parse(JSON.stringify(this.state));
    const postData = {};
    let isValid = true;

    Object.keys(fieldValidator).forEach((key) => {
      postData[key] = formData[key].value;
      for (let i = 0; i < fieldValidator[key].length; i++) {
        const validationMsg = fieldValidator[key][i](formData[key].value);
        formData[key].visited = true;
        if (validationMsg !== undefined) {
          formData[key].error = validationMsg;
          isValid = false;
          break;
        } else {
          formData[key].error = false;
        }
      }
    });

    this.setState({ ...formData });

    if (isValid) {
      axiosInstance()
        .post(`/referring-physicians.json`, postData)
        .then((response) => {
          this.props.updatePhysician(
            response.data.entityId,
            response.data.physicians
          );
          this.props.toggle();
        })
        .catch((err) => {});
    }
    return false;
  };

  render = () => {
    return (
      <Modal
        isOpen={this.props.isActive}
        toggle={this.props.toggle}
        className={this.props.className}
        size={"lg"}
      >
        <form
          ref={(c) => {
            this.form = c;
          }}
          onSubmit={this.submitHandler.bind(this)}
        >
          <ModalHeader toggle={this.props.toggle}>
            {this.props.entity.id !== undefined
              ? "Edit Referring Physician"
              : "New Referring Physician"}
          </ModalHeader>
          <div className={"position-relative"}>
            <ModalBody className={"p-4"}>
              <Row>
                <Col xs={6}>
                  <LabelInput
                    name={"First Name"}
                    type={"text"}
                    required={true}
                    {...this.state.first_name}
                    onChange={(event) => this.updateForm(event, "first_name")}
                  />
                </Col>
                <Col xs={6}>
                  <LabelInput
                    name={"Last Name"}
                    type={"text"}
                    required={true}
                    {...this.state.last_name}
                    onChange={(event) => this.updateForm(event, "last_name")}
                  />
                </Col>
                <Col xs={6}>
                  <LabelInput
                    name={"Suffix"}
                    type={"text"}
                    {...this.state.suffix}
                    onChange={(event) => this.updateForm(event, "suffix")}
                  />
                </Col>
                <Col xs={6}>
                  <LabelInput
                    name={"Fax"}
                    type={"text"}
                    placeholder={"Optional. Example: 9491111111"}
                    {...this.state.fax}
                    onChange={(event) => this.updateForm(event, "fax")}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className={"py-4"}>
              <Button
                color="light"
                className={"btn px-3"}
                type={"button"}
                onClick={this.props.toggle}
              >
                Cancel
              </Button>
              <Button color="primary" className={"btn px-3"} type={"submit"}>
                Save
              </Button>
            </ModalFooter>
          </div>
        </form>
      </Modal>
    );
  };
}

export default DeveloperModal;
