import React, { Component } from "react";
import Select from "react-select";
import axiosInstance from "axios-instance";
import LabelInput from "components/Elements/LabelInput/LabelInput";
import AreaChart from "./AreaChart";

const _objectWithoutProperties = (obj, keys) => {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
};

class PetFindingsForm extends Component {
  constructor(props) {
    super(props);
    const fields = {};
    this.chartRef = React.createRef();

    const findings = {
      1: [
        { value: "no", label: "no" },
        { value: "mild", label: "mild" },
        { value: "moderate", label: "moderate" },
        { value: "severe", label: "severe" },
      ],
      2: [
        { value: "excellent", label: "excellent" },
        { value: "good", label: "good" },
        { value: "poor", label: "poor" },
      ],
      3: [
        { value: "excellent", label: "excellent" },
        { value: "good", label: "good" },
        { value: "poor", label: "poor" },
      ],
    };
    console.log(this.props.findings);

    Object.keys(this.props.findings).map((fieldNo) => {
      let key = `field_${fieldNo}`;
      if (fieldNo === "17") {
        fields[key] = {
          value:
            props.entity !== null &&
            props.entity[key] !== undefined &&
            props.entity[key] !== null &&
            this.props.findings[fieldNo] !== undefined &&
            isNaN(props.entity[key])
              ? this.props.findings[fieldNo].find(
                  (x) => x.label === props.entity[key]
                )
              : props.entity[key],
          visited: false,
          error: false,
        };
      } else {
        fields[key] = {
          value:
            props.entity !== null &&
            props.entity[key] !== undefined &&
            props.entity[key] !== null &&
            findings[fieldNo] !== undefined &&
            isNaN(props.entity[key])
              ? findings[fieldNo].find((x) => x.label === props.entity[key])
              : props.entity[key],
          visited: false,
          error: false,
        };
      }

      return null;
    });
    if (props.entity["field_7_free_type"] !== undefined) {
      fields["field_7_free_type"] = {
        value: props.entity["field_7_free_type"],
        visited: false,
        error: false,
      };
    }
    console.log(fields);
    this.state = { ...fields, areaChartActive: false, findings: findings };
  }

  componentDidMount() {
    const { childRef } = this.props;
    if (childRef !== undefined) {
      childRef(this);
    }
  }
  componentWillUnmount() {
    const { childRef } = this.props;
    if (childRef !== undefined) {
      childRef(undefined);
    }
  }

  updatePieValue(index, datasetIndex) {
    let findings = this.props.findings["17"];
    if (datasetIndex === 0) {
      findings = this.props.findings["17"].slice(0, 6).reverse();
    } else if (datasetIndex === 1) {
      findings = this.props.findings["17"].slice(6, 12).reverse();
    } else if (datasetIndex === 2) {
      findings = this.props.findings["17"].slice(12, 16).reverse();
    } else {
      findings = this.props.findings["17"].slice(16, 17).reverse();
    }
    const inputData = {
      value: findings[index],
      visited: true,
      error: false,
    };

    this.setState({
      field_17: inputData,
      areaChartActive: false,
    });
  }

  updateForm = (event, name) => {
    let value =
      event !== null && event.target !== undefined ? event.target.value : event;

    if (name === "field_20") {
      value = parseInt(this.state.field_20.value) + value;
    }

    const inputData = {
      value: value,
      visited: true,
      error: false,
    };

    this.setState({
      [name]: inputData,
    });
  };

  onSubmit = (callback) => {
    const formData = JSON.parse(JSON.stringify(this.state));
    const postData = { findings: {} };
    let isValid = true;

    Object.keys(formData).map((key) => {
      if (formData[key] === null || formData[key].value === undefined) {
        return null;
      }
      postData["findings"][key] = formData[key].value;
      return null;
    });

    if (isValid) {
      axiosInstance()
        .post(
          `/reports/save-exercise-findings/${this.props.reportId}.json`,
          postData
        )
        .then((response) => {
          callback(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return false;
  };

  toggle = (modalType) => {
    this.setState((prevState) => ({
      [modalType]: !prevState[modalType],
    }));
  };

  render = () => {
    const fields = {};

    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: "30px",
        minHeight: "30px",
      }),
      singleValue: (styles) =>
        _objectWithoutProperties(styles, ["maxWidth", "position", "transform"]),
      menu: (styles) => ({
        ...styles,
        whiteSpace: "nowrap",
        width: "auto",
        right: 0,
      }),
    };

    Object.keys(this.state.findings).map((key) => {
      fields[key] =
        this.state.findings[key].length > 1 ? (
          (disabled) => (
            <div
              className={"d-inline-block px-2 mb-1"}
              style={{ minWidth: "160px" }}
            >
              <Select
                value={this.state[`field_${key}`].value}
                options={this.state.findings[key]}
                menuPlacement={"auto"}
                styles={customStyles}
                isClearable={true}
                isDisabled={disabled}
                autoSize={true}
                onChange={(event) => {
                  this.updateForm(event, `field_${key}`);
                }}
              />
            </div>
          )
        ) : (
          <span>{this.props.findings[key][0].label}</span>
        );
      return null;
    });

    return (
      <div className={"position-relative"}>
        <ol>
          <li>
            There was {fields[1]()} patient motion.
            <br />
            There was {fields[2]()} cardiac/thoracic alignment.
            <br />
            The quality of the study is {fields[3]()}.
          </li>
          <li>
            <div className="checkbox mb-1 text-left">
              <label>
                <input
                  type={"checkbox"}
                  checked={parseInt(this.state["field_4"].value) === 1}
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 1 : 0,
                      `field_4`
                    )
                  }
                />{" "}
                <span>
                  there is homogeneous myocardial perfusion noted with excellent
                  target to background ratio.
                </span>
              </label>
            </div>
            <div className="checkbox mb-1 text-left">
              <label>
                <input
                  type={"checkbox"}
                  checked={parseInt(this.state["field_5"].value) === 1}
                  onChange={(event) =>
                    this.updateForm(
                      event.currentTarget.checked ? 1 : 0,
                      `field_5`
                    )
                  }
                />{" "}
                <span>there is no evidence of ischemia noted.</span>
              </label>
            </div>
            <div className={"d-flex align-items-center"}>
              <span>There is</span>
              <div className={"px-2 flex-grow-1"}>
                <LabelInput
                  type={"text"}
                  size={"sm"}
                  formGroupClass={"mb-0"}
                  className={"form-control form-control-sm"}
                  {...this.state.field_7_free_type}
                  onChange={(event) =>
                    this.updateForm(event, "field_7_free_type")
                  }
                />
              </div>
              <span>
                left ventricular cavity dilatation noted with stress imaging.
              </span>
            </div>

            <div className={"pt-3"}>
              There is reversibility in the{" "}
              <span
                className={"btn-link pointer"}
                onClick={() => this.toggle("areaChartActive")}
              >
                {this.state.field_17.value !== null &&
                this.state.field_17.value !== ""
                  ? this.state.field_17.value.label
                  : "Select Area"}
              </span>{" "}
              wall consistent with ischemia.
            </div>
          </li>
        </ol>

        <AreaChart
          isActive={this.state.areaChartActive}
          toggle={() => this.toggle("areaChartActive")}
          confirmLabel={"Submit"}
          activeChartElement={
            this.state.field_17.value !== null
              ? this.state.field_17.value.label
              : null
          }
          findings={this.props.findings[17]}
          confirmAction={(index, datasetIndex) =>
            this.updatePieValue(index, datasetIndex)
          }
        />
      </div>
    );
  };
}

export default PetFindingsForm;
