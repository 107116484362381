import React, { Component } from "react";
import { withRouter } from "react-router";
import { Col, Row, Button, Table, Card, CardBody } from "reactstrap";
import LabelInput from "components/Elements/LabelInput/LabelInput";
import ReferringPhysicianModal from "components/Elements/Modals/ReferringPhysician";
import ConfirmModal from "components/Elements/Modals/Confirm";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import axiosInstance from "axios-instance";
import moment from "moment";
import Select from "react-select";

import { required } from "validation";
import AsyncSelect from "react-select/async/dist/react-select.esm";
const validator = {
  report_type_id: [required],
  study_date: [required],
  patient: [required],
  gender: [required],
  age: [required],
  notes: [],
  technician_id: [required],
  physician_id: [],
  physicians: [],
};

let patientTimeout = null;
class VascularForm extends Component {
  constructor(props) {
    super(props);
    const fields = {};
    Object.keys(validator).forEach((key) => {
      fields[key] = {
        value:
          this.props[key] !== undefined && this.props[key] !== null
            ? this.props[key]
            : "",
        visited: false,
        error: false,
      };
    });
    if (fields.study_date.value.length === 0) {
      fields.study_date.value = moment();
    } else {
      fields.study_date.value = moment(fields.study_date.value * 1000);
    }

    if (fields.gender.value.length === 0) {
      fields.gender.value = "male";
    }

    if (
      props.patient !== undefined &&
      props.patient !== "" &&
      props.patient !== null
    ) {
      fields.patient.value = {
        value: props.patient.ecw_uid,
        label:
          `${props.patient.ecw_uid}, ${props.patient.first_name} ${props.patient.last_name}, ` +
          moment(props.patient.dob).format("MM/DD/YYYY"),
      };
    }

    if (props.technician_id !== undefined && props.technician_id !== "") {
      fields.technician_id.value = {
        value: props.technician_id,
        label: props.listTechnician.find((x) => x.value === props.technician_id)
          .label,
      };
    }
    if (
      props.physician_id !== undefined &&
      props.physician_id !== "" &&
      props.physician_id !== null
    ) {
      fields.physician_id.value = {
        value: props.physician_id,
        label: props.listProviders.find((x) => x.value === props.physician_id)
          .label,
      };
    }

    fields.physicians.value =
      props.referring_physicians !== undefined
        ? props.referring_physicians.map((item) => {
            return item.id;
          })
        : [];

    this.state = {
      ...fields,
      loaded: this.props.loaded,
      modalActive: false,
      confirmModalActive: false,
      activePhysicianId: null,
      errorMessage: false,
      validator: validator,
      reportTypes: props.reportTypes,
      heartRhythms: props.heartRhythms,
      listPhysicians: props.listPhysicians,
      listTechnician: props.listTechnician,
      listProviders: props.listProviders,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    if (childRef !== undefined) {
      childRef(this);
    }
  }
  componentWillUnmount() {
    const { childRef } = this.props;
    if (childRef !== undefined) {
      childRef(undefined);
    }
  }

  toggle = (modalType) => {
    this.setState((prevState) => ({
      [modalType]: !prevState[modalType],
    }));
  };

  loadOptions = () => {
    axiosInstance()
      .get(
        this.props.id !== undefined
          ? `/reports/load-options/${this.props.id}.json`
          : `/reports/load-options.json`
      )
      .then((response) => {
        const fields = {};
        if (this.state.technician_id.value !== "") {
          fields["technician_id"] = {
            value: {
              value: this.props.technician_id,
              label: response.data.technicians.find(
                (x) => x.value === this.props.technician_id
              ).label,
            },
            visited: false,
            error: false,
          };
        }
        if (this.state.physician_id.value !== "") {
          fields["physician_id"] = {
            value: {
              value: this.props.physician_id,
              label: response.data.providers.find(
                (x) => x.value === this.props.physician_id
              ).label,
            },
            visited: false,
            error: false,
          };
        }
        console.log(fields);
        this.setState({
          ...fields,
          loaded: true,
          reportTypes: response.data.reportTypes,
          heartRhythms: response.data.heartRhythms,
          listPhysicians: response.data.physicians,
          listTechnician: response.data.technicians,
          listProviders: response.data.providers,
        });
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      });
  };

  updatePhysician = (physicianId, isNew) => {
    const url =
      isNew !== undefined
        ? `/referring-physicians/${physicianId}.json?new=1`
        : `/referring-physicians/${physicianId}.json`;
    axiosInstance()
      .get(url)
      .then((response) => {
        const toUpdate = {
          physicians: JSON.parse(JSON.stringify(this.state.physicians)),
          listPhysicians: JSON.parse(JSON.stringify(this.state.listPhysicians)),
        };
        toUpdate.physicians.value.push(response.data.physicianId);
        toUpdate.listPhysicians.push(response.data.physician);
        this.setState({
          ...toUpdate,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  updateForm = (event, name) => {
    const inputData = {
      value:
        event !== null && event.target !== undefined
          ? event.target.value
          : event,
      visited: true,
      error: false,
    };

    for (let i = 0; i < this.state.validator[name].length; i++) {
      const validationMsg = this.state.validator[name][i](inputData.value);
      if (validationMsg !== undefined) {
        inputData.error = validationMsg;
        break;
      }
    }

    const toUpdate = {
      [name]: inputData,
    };

    if (name === "patient") {
      const gender =
        toUpdate.patient.value !== null ? toUpdate.patient.value.sex : "male";

      let age = "";
      if (toUpdate.patient.value !== null) {
        let today = new Date();
        let birthDate = new Date(toUpdate.patient.value.dob);
        age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
      } else {
        age = "";
      }

      toUpdate.gender = {
        value: gender,
        visited: true,
        error: false,
      };
      toUpdate.age = {
        value: age,
        visited: true,
        error: false,
      };
    }

    this.setState(toUpdate);
  };

  onSubmit = (e) => {
    e.preventDefault();
    const formData = JSON.parse(JSON.stringify(this.state));
    const postData = {};
    let isValid = true;

    Object.keys(this.state.validator).forEach((key) => {
      postData[key] =
        formData[key].value !== null && formData[key].value.value !== undefined
          ? formData[key].value.value
          : formData[key].value;
      for (let i = 0; i < this.state.validator[key].length; i++) {
        const validationMsg = this.state.validator[key][i](formData[key].value);
        formData[key].visited = true;
        if (validationMsg !== undefined) {
          formData[key].error = validationMsg;
          isValid = false;
          break;
        } else {
          formData[key].error = false;
        }
      }
    });

    formData.study_date.value =
      formData.study_date.value !== null
        ? moment(formData.study_date.value)
        : null;

    formData.validator = validator;
    this.setState({ ...formData, successMessage: false, errorMessage: false });
    if (isValid) {
      postData.study_date = formData.study_date.value.unix();
      if (this.props.id !== undefined) {
        axiosInstance()
          .put(`/reports/${this.props.id}.json`, postData)
          .then((response) => {
            this.props.onSubmit();
          })
          .catch((err) => {
            alert(err);
          });
      } else {
        axiosInstance()
          .post(`/reports.json`, postData)
          .then((response) => {
            this.props.history.push(`/reports/${response.data.entity_id}`);
          })
          .catch((err) => {
            alert(err);
          });
      }
    }
    return false;
  };

  loadPatientList = (inputValue, callback) => {
    clearTimeout(patientTimeout);
    patientTimeout = setTimeout(() => {
      axiosInstance()
        .get(`/patients.json`, { params: { query: inputValue } })
        .then((response) => {
          callback(response.data);
        })
        .catch((err) => {
          alert(err);
          callback([]);
        });
    }, 1000);
  };

  loadPhysiciansList = (inputValue, callback) => {
    clearTimeout(patientTimeout);
    patientTimeout = setTimeout(() => {
      axiosInstance()
        .get(`/referring-physicians.json`, { params: { query: inputValue } })
        .then((response) => {
          callback(response.data);
        })
        .catch((err) => {
          alert(err);
          callback([]);
        });
    }, 1000);
  };

  render = () => {
    const optionTypes = [];
    if (
      this.state.listPhysicians !== undefined &&
      this.state.listPhysicians !== null
    ) {
      this.state.listPhysicians.map((item) => {
        if (!this.state.physicians.value.includes(item.id)) {
          optionTypes.push({
            value: item.id,
            label: item.name,
          });
        }
        return true;
      });
    }

    return (
      <React.Fragment>
        {this.state.loaded && (
          <React.Fragment>
            <Row>
              <Col>
                <div className={"form-group"}>
                  <label className={"font-weight-bold"}>Study Date *</label>
                  <div
                    className={this.state.study_date.error ? "is-invalid" : ""}
                  >
                    <SingleDatePicker
                      date={this.state.study_date.value} // momentPropTypes.momentObj or null
                      onDateChange={(startDate) =>
                        this.updateForm(startDate, "study_date")
                      } // PropTypes.func.isRequired
                      focused={this.state.startDateFocused} // PropTypes.bool
                      block={true}
                      isOutsideRange={() => false}
                      onFocusChange={({ focused }) =>
                        this.setState({ startDateFocused: focused })
                      } // PropTypes.func.isRequired
                      id="study-date" // PropTypes.string.isRequired,
                    />
                  </div>
                  {this.state.study_date.error && (
                    <span className={"error"}>
                      {this.state.study_date.error}
                    </span>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={"form-group"}>
                  <label className={"font-weight-bold"}>Patient *</label>
                  <AsyncSelect
                    value={this.state.patient.value}
                    isClearable={true}
                    loadOptions={(inputValue, callback) => {
                      if (inputValue.length < 3) {
                        return callback([]);
                      }
                      this.loadPatientList(inputValue, callback);
                    }}
                    defaultOptions
                    className={
                      this.state.patient.error !== false ? "is-invalid" : ""
                    }
                    menuPlacement={"bottom"}
                    onChange={(event) => {
                      this.updateForm(event, "patient");
                    }}
                  />
                  {this.state.patient.error !== false && (
                    <span className="error">Please fill out this field.</span>
                  )}
                </div>
              </Col>
              <Col>
                <div className={"form-group"}>
                  <label className="font-weight-bold d-block">Gender *</label>
                  <div className="form-check form-check-inline pt-1 pr-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="inlineCheckbox1"
                      value="male"
                      name={"gender"}
                      checked={this.state.gender.value === "male"}
                      onChange={(event) =>
                        this.updateForm(event.target.value, "gender")
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineCheckbox1"
                    >
                      Male
                    </label>
                  </div>
                  <div
                    className={
                      "form-check form-check-inline pt-1 " +
                      (this.state.gender.error ? "is-invalid" : "")
                    }
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      id="inlineCheckbox2"
                      value={"female"}
                      name={"gender"}
                      checked={this.state.gender.value === "female"}
                      onChange={(event) =>
                        this.updateForm(event.target.value, "gender")
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineCheckbox2"
                    >
                      Female
                    </label>
                  </div>
                  {this.state.gender.error && (
                    <span className={"error d-block"}>
                      {this.state.gender.error}
                    </span>
                  )}
                </div>
              </Col>
              <Col>
                <LabelInput
                  name={"Age"}
                  type={"number"}
                  required={true}
                  {...this.state.age}
                  onChange={(event) => this.updateForm(event, "age")}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <LabelInput
                  name={"Clinical Indications"}
                  type={"textarea"}
                  {...this.state.notes}
                  onChange={(event) => this.updateForm(event, "notes")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className={"form-group"}>
                  <label className={"font-weight-bold"}>Technician*</label>
                  <Select
                    value={this.state.technician_id.value}
                    isClearable={true}
                    options={this.state.listTechnician}
                    menuPlacement={"top"}
                    onChange={(event) => {
                      this.updateForm(event, "technician_id");
                    }}
                  />
                </div>
              </Col>
              <Col xs={6}>
                <div className={"form-group"}>
                  <label className={"font-weight-bold"}>
                    Reading Physician
                  </label>
                  <Select
                    value={this.state.physician_id.value}
                    options={this.state.listProviders}
                    isClearable={true}
                    menuPlacement={"top"}
                    onChange={(event) => {
                      this.updateForm(event, "physician_id");
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <label className={"font-weight-bold"}>
                  Referring Physician
                </label>
                <AsyncSelect
                  value={null}
                  isClearable={false}
                  loadOptions={(inputValue, callback) => {
                    if (inputValue.length < 3) {
                      return callback([]);
                    }
                    this.loadPhysiciansList(inputValue, callback);
                  }}
                  defaultOptions
                  menuPlacement={"top"}
                  onChange={(event) => {
                    this.updatePhysician(event.value);
                  }}
                />
              </Col>
              <Col>
                <div className={"d-flex pt-4 mt-2"}>
                  <div className={"pr-3 pt-2"}>or</div>
                  <div className={"flex-grow-1"}>
                    <Button
                      className={"btn-block"}
                      color={"primary"}
                      //disabled={true}
                      onClick={() => this.toggle("modalActive")}
                    >
                      Add New Referring Physician
                    </Button>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                {this.state.physicians.value.map((entityId) => {
                  let physician = this.state.listPhysicians.find(
                    (x) => x.id === entityId
                  );
                  return (
                    <Card
                      className={"mt-2"}
                      key={"referring-physician-" + entityId}
                    >
                      <CardBody className={"p-2"}>
                        <div className={"d-flex"}>
                          <div className={"flex-grow-1"}>
                            <Table size={"sm"} borderless={true}>
                              <tbody>
                                <tr>
                                  <th style={{ width: "170px" }}>
                                    Referring Physician
                                  </th>
                                  <td>
                                    {physician.last_name},{" "}
                                    {physician.first_name}, {physician.suffix}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Fax</th>
                                  <td>{physician.fax}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                          <div className={"p-3"}>
                            <FontAwesomeIcon
                              className={"fa-2x pointer"}
                              icon={["fas", "trash-alt"]}
                              onClick={() => {
                                this.setState({
                                  confirmModalActive: true,
                                  activePhysicianId: physician.id,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  );
                })}
                <ConfirmModal
                  isActive={this.state.confirmModalActive}
                  toggle={() => this.toggle("confirmModalActive")}
                  confirmLabel={"Yes, unassign this referring physician"}
                  body={
                    "Please confirm to unassign this referring physician from the report."
                  }
                  confirmAction={() => {
                    this.setState({
                      confirmModalActive: false,
                      physicians: {
                        value: this.state.physicians.value.filter(
                          (value) => value !== this.state.activePhysicianId
                        ),
                        error: false,
                        visited: true,
                      },
                    });
                  }}
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
        {this.props.showSubmit && (
          <div className={"text-right pt-4"}>
            <Button color="primary" type={"submit"} onClick={this.onSubmit}>
              Create New Report
            </Button>
          </div>
        )}
        <ReferringPhysicianModal
          entity={{}}
          isActive={this.state.modalActive}
          updatePhysician={(physicianId, physicians) =>
            this.updatePhysician(physicianId, physicians)
          }
          toggle={() => this.toggle("modalActive")}
        />
      </React.Fragment>
    );
  };
}
export default withRouter(VascularForm);
