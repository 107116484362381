import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  CardBody,
  CardHeader,
  Card,
  Button,
  Alert,
} from "reactstrap";
import { Helmet } from "react-helmet";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import axiosInstance from "axios-instance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arrayMove from "array-move";

import CreateStatement from "../../Elements/Modals/CreateStatement";
import DeleteModal from "components/Elements/Modals/Confirm";

const SortableItem = SortableElement(
  ({ value, toggle, onClick, onDeleteClick, usedStatements }) => (
    <div
      className={"d-flex py-1 px-1 mb-1 border rounded align-items-center"}
      style={{ cursor: "grab" }}
    >
      <div>
        <FontAwesomeIcon className={"mr-2 ml-1"} icon={["fas", "ellipsis-v"]} />
      </div>
      <div className={"flex-grow-1 px-2"} style={{ wordBreak: "break-all" }}>
        {value.name}{" "}
        {value.is_normal === 1 && (
          <span className="badge badge-primary">Normal Statement</span>
        )}
      </div>
      <div className={"text-nowrap"}>
        <Button
          size={"sm"}
          color={"light"}
          className={"mr-2"}
          onClick={() => onClick(value)}
        >
          <FontAwesomeIcon className={"pointer"} icon={["fas", "edit"]} />
        </Button>
        <Button
          size={"sm"}
          color={"danger"}
          onClick={() => onDeleteClick(value)}
          className={
            value.is_normal !== 1 && !usedStatements.includes(value.id)
              ? ""
              : "invisible"
          }
        >
          <FontAwesomeIcon className={"pointer"} icon={["fas", "trash-alt"]} />
        </Button>
      </div>
    </div>
  )
);

const SortableList = SortableContainer(
  ({ items, toggle, onClick, onDeleteClick, usedStatements }) => {
    return (
      <div>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value.id}`}
            index={index}
            value={value}
            toggle={(modelType, itemId) => toggle(modelType, itemId)}
            usedStatements={usedStatements}
            onClick={onClick}
            onDeleteClick={onDeleteClick}
          />
        ))}
      </div>
    );
  }
);

class Findings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      findings: [],
      createModalActive: false,
      deleteModalActive: false,
      findingName: "",
      item: null,
      submitting: false,
      entity: {},
      errorMsg: "",
      successMsg: "",
    };
    this.loadFindings();
  }

  toggle = (modalType, findingName, item, entity) => {
    this.setState((prevState) => ({
      [modalType]: !prevState[modalType],
      findingName: findingName,
      item: item,
      entity: entity,
    }));
  };

  loadFindings = () => {
    axiosInstance()
      .get("/admin/findings.json")
      .then((response) => {
        this.setState({
          findings: response.data.findings,
          usedStatements: response.data.usedStatements,
          loaded: true,
        });
      });
  };

  deleteAction = () => {
    const { entity } = this.state;
    axiosInstance()
      .delete(`/admin/findings/${entity.id}.json`)
      .then((response) => {
        this.setState(
          {
            findings: response.data.findings,
            usedStatements: response.data.usedStatements,
            submitting: false,
            deleteModalActive: false,
            successMsg: "Finding deleted successfully.",
          },
          () => {
            setTimeout(() => {
              this.setState({
                successMsg: "",
              });
            }, 5000);
          }
        );
      })
      .catch((err) => {
        this.setState(
          {
            submitting: false,
            deleteModalActive: false,
            errorMsg: err.response.data.message,
          },
          () => {
            setTimeout(() => {
              this.setState({
                errorMsg: "",
              });
            }, 5000);
          }
        );
      });
  };

  saveStatement = (postData) => {
    this.setState(
      {
        submitting: true,
      },
      () => {
        axiosInstance()
          .post(
            `/admin/findings/save-statement/${this.state.item.id}.json`,
            postData
          )
          .then((response) => {
            this.setState({
              findings: response.data.findings,
              usedStatements: response.data.usedStatements,
              submitting: false,
              createModalActive: false,
            });
          })
          .catch((err) => {
            alert(err);
          });
      }
    );
  };

  onSortEnd = ({ oldIndex, newIndex }, event, statements) => {
    const entities = arrayMove(statements, oldIndex, newIndex);
    axiosInstance()
      .post(`/admin/findings/save-statements-order.json`, {
        entities: entities,
      })
      .then((response) => {
        this.setState({
          findings: response.data.findings,
          loaded: true,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  render = () => {
    return (
      <Container>
        <Helmet>
          <title>NewportX | Admin | Manage Users</title>
        </Helmet>
        {this.state.loaded && (
          <React.Fragment>
            <div className={"pt-4"}>
              <div className={"d-flex align-items-center"}>
                <h2 className={"flex-grow-1 font-weight-bold"}>
                  Echo Findings Database
                </h2>
              </div>
              <ul>
                <li>
                  If a finding is in use, it cannot be deleted from the
                  database.
                </li>
                <li>
                  Editing a statement will impact the non-finalized reports. The
                  language on that report will also be updated.
                </li>
                <li>
                  Finalized reports' findings language will not be affected by
                  editions here.
                </li>
                <li>
                  "Normal" finding statements cannot be deleted through this
                  interface. Please contact system admin at
                  support@squeezetech.com to make changes for normal findings.
                </li>
              </ul>
            </div>
            {this.state.errorMsg.length > 0 && (
              <Alert color="danger">{this.state.errorMsg}</Alert>
            )}
            {this.state.successMsg.length > 0 && (
              <Alert color="success">{this.state.successMsg}</Alert>
            )}
            <Row>
              {this.state.findings.map((finding) => {
                return (
                  <Col xs={6} key={"finding-" + finding.id}>
                    <Card className={"mb-4"}>
                      <CardHeader>{finding.name}</CardHeader>
                      <CardBody>
                        {finding.finding_items.map((item) => {
                          return (
                            <div
                              className={"pb-4"}
                              key={"finding-item-" + item.id}
                            >
                              <div className={"d-flex pb-2"}>
                                <div className={"flex-grow-1 font-weight-bold"}>
                                  {item.name}
                                </div>
                                <Button
                                  color={"outline-primary"}
                                  size={"sm"}
                                  onClick={() =>
                                    this.toggle(
                                      "createModalActive",
                                      finding.name,
                                      item,
                                      {}
                                    )
                                  }
                                >
                                  Add New Statement
                                </Button>
                              </div>
                              <SortableList
                                items={item.finding_statements}
                                distance={1}
                                lockAxis={"y"}
                                onClick={(entity) =>
                                  this.toggle(
                                    "createModalActive",
                                    finding.name,
                                    item,
                                    entity
                                  )
                                }
                                onDeleteClick={(entity) =>
                                  this.toggle(
                                    "deleteModalActive",
                                    finding.name,
                                    item,
                                    entity
                                  )
                                }
                                onSortEnd={(oldIndex, newIndex) =>
                                  this.onSortEnd(
                                    oldIndex,
                                    newIndex,
                                    item.finding_statements
                                  )
                                }
                                usedStatements={this.state.usedStatements}
                              />
                            </div>
                          );
                        })}
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </React.Fragment>
        )}

        {this.state.createModalActive && (
          <CreateStatement
            isActive={this.state.createModalActive}
            toggle={
              this.state.submitting
                ? null
                : () => this.toggle("createModalActive")
            }
            entity={this.state.entity}
            findingName={this.state.findingName}
            findingItem={this.state.item}
            onSubmit={this.saveStatement}
            submitting={this.state.submitting}
          />
        )}

        {this.state.deleteModalActive && (
          <DeleteModal
            isActive={this.state.deleteModalActive}
            toggle={
              this.state.submitting === true
                ? null
                : () => this.toggle("deleteModalActive")
            }
            title={"Confirmation"}
            body={
              "Please confirm before deleting this statement. This action cannot be undone."
            }
            confirmAction={this.deleteAction}
            confirmLabel={"Confirm"}
            confirmClass={"danger"}
          ></DeleteModal>
        )}
      </Container>
    );
  };
}

export default Findings;
