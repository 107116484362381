import React from "react";
import { displayValue } from "functions";

export default (props) => {
  return (
    <div className={"position-relative"}>
      {props.isPetType ? (
        <ol type={"(1)"}>
          <li>
            There was {displayValue(props.field_1)} patient motion.
            <br />
            There was {displayValue(props.field_2)} cardiac/thoracic alignment.
            <br />
            The quality of the study is {displayValue(props.field_3)}.
          </li>
          <li>
            {props.field_4 === "1" && (
              <div>
                There is homogeneous myocardial perfusion noted with excellent
                target to background ratio.
              </div>
            )}
            {props.field_5 === "1" && (
              <div>There is no evidence of ischemia noted.</div>
            )}
            There is {displayValue(props.field_7_free_type)} left ventricular
            cavity dilatation noted with stress imaging.
            <br />
            There is reversibility in the {displayValue(props.field_17)} wall
            consistent with ischemia.
          </li>
        </ol>
      ) : (
        <ol type={"(1)"}>
          <li>
            Review of the rotating images demonstrates{" "}
            {displayValue(props.field_1)} patient motion during image
            acquisition at rest and {displayValue(props.field_2)} patient motion
            during asquisition with stress.
            <br />
            Motion correction{" "}
            {props.field_3 === "was not" ? (
              <React.Fragment>
                {displayValue(props.field_3)} used.
              </React.Fragment>
            ) : (
              <React.Fragment>
                {displayValue(props.field_3)} used for the{" "}
                {displayValue(props.field_4)} images.
              </React.Fragment>
            )}{" "}
            <br />
            The overall study quality is {displayValue(props.field_5)}.
          </li>
          <li>
            Abnormal extracardiac uptake{" "}
            {props.field_6 === "is not" ? (
              <React.Fragment>
                {displayValue(props.field_6)} noted.
              </React.Fragment>
            ) : (
              <React.Fragment>
                {displayValue(props.field_6)} noted in the{" "}
                {props.field_7 !== "[free type]"
                  ? displayValue(props.field_7)
                  : displayValue(props.field_7_free_type)}
                .
              </React.Fragment>
            )}
            <br />
            {props.report_exercise_finding_sentences.map((item) => {
              if (item.type !== 4) return null;
              return (
                <React.Fragment key={`item-` + item.id}>
                  Abnormal extracardiac uptake{" "}
                  {item.field_1 === "is not" ? (
                    <React.Fragment>
                      {displayValue(item.field_1)} noted.
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {displayValue(item.field_1)} noted in the{" "}
                      {item.field_2 !== "[free type]"
                        ? displayValue(item.field_2)
                        : displayValue(item.field_2_free_type)}
                      .
                    </React.Fragment>
                  )}
                  <br />
                </React.Fragment>
              );
            })}
            The left ventricular size apears {displayValue(props.field_8)}.
            <br />
            The right ventricular size appears {displayValue(props.field_9)}.
            <br />
            There is {displayValue(props.field_10)} left ventricular cavity
            dilitation noted with stress imaging.
            <br />
            Abnormal lung uptake {displayValue(props.field_11)} present.
            <br />
            There is decreased tracer uptake in the{" "}
            {displayValue(props.field_12)} wall(s) consistent with{" "}
            {props.field_13 !== "[free type]"
              ? displayValue(props.field_13)
              : displayValue(props.field_13_free_type)}
            .
            {props.report_exercise_finding_sentences.map((item) => {
              if (item.type !== 1) return null;
              return (
                <div key={`item-` + item.id}>
                  There is decreased tracer uptake in the{" "}
                  {displayValue(item.field_1)} wall(s) consistent with{" "}
                  {item.field_2 !== "[free type]"
                    ? displayValue(item.field_2)
                    : displayValue(item.field_2_free_type)}
                  .
                </div>
              );
            })}
            {props.item_2_free_type !== null &&
              props.item_2_free_type.length > 0 && (
                <div>{displayValue(props.item_2_free_type)}</div>
              )}
          </li>
          <li>
            {props.field_14 === "0" && (
              <span>
                Otherwise, there is normal tracer uptake throughout the
                myocardium.
              </span>
            )}
            {props.field_14 === "3" && <span>No Statement.</span>}
            {props.field_14 === "1" && (
              <span>{props.findings[14][0].label}</span>
            )}
            {props.field_14 === "2" && (
              <div>
                Myocardial perfusion imaging demonstrates a{" "}
                {displayValue(props.field_15)} size perfusion abnormality of{" "}
                {displayValue(props.field_16)} intensity in the{" "}
                {displayValue(props.field_17)} wall segment(s) that is{" "}
                {displayValue(props.field_18)}{" "}
                {props.field_18 === "reversible" &&
                  displayValue(props.field_18_1)}
                {props.field_18 === "fixed" && (
                  <React.Fragment>
                    {displayValue(props.field_18_2)}{" "}
                    {props.field_18_2 === "with" && (
                      <React.Fragment>
                        a {displayValue(props.field_19)} amount of peri-infarct
                        ischemia
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                .
                {props.report_exercise_finding_sentences.map((item) => {
                  if (item.type !== 2) return null;
                  return (
                    <div key={`item-` + item.id}>
                      There is also a {displayValue(item.field_1)} size
                      perfusion abnormality of {displayValue(item.field_2)}{" "}
                      intensity in the {displayValue(item.field_3)} wall
                      segment(s) that is {displayValue(item.field_4)}{" "}
                      {item.field_4 === "reversible" &&
                        displayValue(item.field_5)}
                      {item.field_4 === "fixed" && (
                        <React.Fragment>
                          {displayValue(item.field_6)}{" "}
                          {item.field_6 === "with" && (
                            <React.Fragment>
                              a {displayValue(item.field_7)} amount of
                              peri-infarct ischemia
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                      .
                    </div>
                  );
                })}
              </div>
            )}
          </li>
          <li>
            <React.Fragment>
              {props.field_21 !== null && props.field_21.length > 0 && (
                <div>
                  {props.findings[20][0].label} {displayValue(props.field_21)}.
                </div>
              )}
              {(props.field_20 & 2) > 0 && (
                <div>{props.findings[22][0].label}</div>
              )}
              {props.field_24 !== null && props.field_24.length > 0 && (
                <div>
                  {props.findings[23][0].label} {displayValue(props.field_24)}.
                </div>
              )}
              {props.field_26 !== null && props.field_26.length > 0 && (
                <div>
                  Gated SPECT imaging was performed and demonstrates{" "}
                  {displayValue(props.field_26)} of the{" "}
                  {props.field_27 !== "[free type]"
                    ? displayValue(props.field_27)
                    : displayValue(props.field_27_free_type)}{" "}
                  wall(s).
                  {props.report_exercise_finding_sentences.map((item) => {
                    if (item.type !== 3) return null;
                    return (
                      <div key={`item-` + item.id}>
                        Gated SPECT imaging was performed and demonstrates{" "}
                        {displayValue(item.field_1)} of the{" "}
                        {item.field_2 !== "[free type]"
                          ? displayValue(item.field_2)
                          : displayValue(item.field_2_free_type)}{" "}
                        wall(s).
                      </div>
                    );
                  })}
                </div>
              )}
            </React.Fragment>

            {props.field_30 !== null && props.field_30.length > 0 && (
              <div>
                {props.findings[29][0].label} {displayValue(props.field_30)}
                %.
              </div>
            )}
            {props.field_31}
          </li>
        </ol>
      )}
    </div>
  );
};
