import React, { Component } from "react";
import Select from "react-select";
import axiosInstance from "axios-instance";
import { Col, Row, Table } from "reactstrap";
import { historyFields } from "field-config";

const _objectWithoutProperties = (obj, keys) => {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
};

class HistoryForm extends Component {
  constructor(props) {
    super(props);
    const fields = {};
    Object.keys(historyFields).map((fieldNo) => {
      let key = `${fieldNo}`;
      fields[key] = {
        value:
          props.entity !== null &&
          props.entity[key] !== undefined &&
          props.entity[key] !== null
            ? isNaN(props.entity[key])
              ? historyFields[fieldNo].find(
                  (x) => x.label === props.entity[key]
                )
              : props.entity[key]
            : "",
        visited: false,
        error: false,
      };
      return null;
    });
    console.log(fields);

    this.state = { ...fields };
  }

  componentDidMount() {
    const { childRef } = this.props;
    if (childRef !== undefined) {
      childRef(this);
    }
  }
  componentWillUnmount() {
    const { childRef } = this.props;
    if (childRef !== undefined) {
      childRef(undefined);
    }
  }

  updateForm = (event, name) => {
    let value =
      event !== null && event.target !== undefined ? event.target.value : event;

    if (name === "field_20") {
      value = parseInt(this.state.field_20.value) + value;
    }

    const inputData = {
      value: value,
      visited: true,
      error: false,
    };

    this.setState({
      [name]: inputData,
    });
  };

  onSubmit = (callback) => {
    const formData = JSON.parse(JSON.stringify(this.state));
    const postData = { findings: {} };
    let isValid = true;

    Object.keys(formData).map((key) => {
      if (formData[key] === null || formData[key].value === undefined) {
        return null;
      }
      postData["findings"][key] = formData[key].value;
      return null;
    });

    if (isValid) {
      axiosInstance()
        .post(
          `/reports/save-exercise-findings/${this.props.reportId}.json`,
          postData
        )
        .then((response) => {
          callback(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return false;
  };

  onSubmit = (callback) => {
    const formData = JSON.parse(JSON.stringify(this.state));
    const postData = {};
    let isValid = true;

    Object.keys(formData).map((key) => {
      if (formData[key] === null || formData[key].value === undefined) {
        return null;
      }
      postData[key] = formData[key].value;
      return null;
    });

    if (isValid) {
      axiosInstance()
        .post(`/reports/save-history/${this.props.reportId}.json`, postData)
        .then((response) => {
          callback(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return false;
  };

  render = () => {
    const fields = {};

    const customStyles = {
      control: (base, state) => ({
        ...base,
        height: "30px",
        minHeight: "30px",
      }),
      singleValue: (styles) =>
        _objectWithoutProperties(styles, ["maxWidth", "position", "transform"]),
      menu: (styles) => ({
        ...styles,
        whiteSpace: "nowrap",
        left: 0,
      }),
    };

    Object.keys(historyFields).map((key) => {
      fields[key] =
        historyFields[key].length > 0 ? (
          (disabled) => (
            <div
              className={"d-inline-block px-2 mb-1"}
              style={{ minWidth: "160px" }}
            >
              <Select
                value={this.state[`${key}`].value}
                options={historyFields[key]}
                menuPlacement={"auto"}
                styles={customStyles}
                isClearable={true}
                isDisabled={disabled}
                autoSize={true}
                onChange={(event) => {
                  this.updateForm(event, `${key}`);
                }}
              />
            </div>
          )
        ) : (
          <span>{historyFields[key][0].label}</span>
        );
      return null;
    });

    return (
      <div className={"position-relative"}>
        <Row>
          <Col className={"pr-0"}>
            <Table borderless={true} size={"sm"} className={"mb-0"}>
              <tbody>
                <tr>
                  <th>Smoke</th>
                  <td>{fields["smoke"]()}</td>
                </tr>
                <tr>
                  <th>Claudication</th>
                  <td>{fields["claudication"]()}</td>
                </tr>
                <tr>
                  <th>Gangrene</th>
                  <td>{fields["gangrene"]()}</td>
                </tr>
                <tr>
                  <th>Prev Vasc Surg</th>
                  <td>{fields["prev_vasc_surg"]()}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col className={"px-1"}>
            <Table borderless={true} size={"sm"} className={"mb-0"}>
              <tbody>
                <tr>
                  <th>Diabetic</th>
                  <td>{fields["diabetic"]()}</td>
                </tr>
                <tr>
                  <th>Skin Color Change</th>
                  <td>{fields["skin_color_change"]()}</td>
                </tr>
                <tr>
                  <th>Hyperlipidemia</th>
                  <td>{fields["hyperlipidemia"]()}</td>
                </tr>
                <tr>
                  <th>Prev Angioplasty</th>
                  <td>{fields["prev_angioplasty"]()}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col className={"pl-0"}>
            <Table borderless={true} size={"sm"} className={"mb-0"}>
              <tbody>
                <tr>
                  <th>Rest Pain</th>
                  <td>{fields["rest_pain"]()}</td>
                </tr>
                <tr>
                  <th>Vascular Ulcers</th>
                  <td>{fields["vascular_ulcers"]()}</td>
                </tr>
                <tr>
                  <th>Hypertension</th>
                  <td>{fields["hypertension"]()}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    );
  };
}

export default HistoryForm;
