import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CardHeader } from "reactstrap";

export default (props) => {
  return (
    <CardHeader className={"d-flex p-1 align-items-center"}>
      <div
        className={"pointer py-2 px-3 flex-grow-1"}
        onClick={props.onHeaderClick}
      >
        {props.title}
        {props.isLocked && (
          <FontAwesomeIcon className={"ml-2"} icon={["fas", "lock"]} />
        )}
        {props.showContent ? (
          <FontAwesomeIcon className={"ml-3"} icon={["fas", "chevron-up"]} />
        ) : (
          <FontAwesomeIcon className={"ml-3"} icon={["fas", "chevron-down"]} />
        )}
      </div>

      {props.showEdit === true ? (
        <Button
          color={"success"}
          size={"sm px-4 mr-2"}
          onClick={props.onSaveClick}
        >
          Save
        </Button>
      ) : (
        <React.Fragment>
          {!props.isLocked && (
            <Button
              color={"primary"}
              size={"sm px-4 mr-2"}
              onClick={props.onEditClick}
            >
              Edit
            </Button>
          )}
        </React.Fragment>
      )}
    </CardHeader>
  );
};
