import axios from "axios";
const instance = () => {
  const authData = localStorage.getItem("auth");
  let apiUrl = "http://newportx-api.localhost:8001/";
  switch (window.location.hostname) {
    case "nph.squeezetech.com":
      apiUrl = "https://nph-api.squeezetech.com/";
      break;
    case "imaging-staging.squeezetech.com":
      apiUrl = "https://imaging-api-staging.squeezetech.com/";
      break;
    case "imaging.newportheart.com":
      apiUrl = "https://imaging-api.newportheart.com/";
      break;
    default:
      //apiUrl = "http://192.168.0.163:8001/";
      apiUrl = "http://newportx-api.localhost:8001/";
      break;
  }

  let axiosInstance = null;
  if (authData !== null) {
    axiosInstance = axios.create({
      baseURL: apiUrl,
      headers: { Authorization: "Bearer " + JSON.parse(authData).token },
    });
  } else {
    axiosInstance = axios.create({
      baseURL: apiUrl,
    });
  }
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      // Do something with response error
      if (error.response.status === 401) {
        const auth = localStorage.getItem("auth");
        if (auth !== null) {
          window.location.href = "/logout";
        }
      } else if (error.response.status === 403) {
        window.location.href = "/unathorized";
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default instance;
