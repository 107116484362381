import React from "react";
import { Badge } from "reactstrap";

export default (props) => {
  return (
    <React.Fragment>
      {props.status === -1 && (
        <Badge color={"danger"} {...props}>
          Amendment In Progress
        </Badge>
      )}

      {props.status === 1 && (
        <Badge color={"primary"} {...props}>
          Ready for Review
        </Badge>
      )}
      {props.status === 0 && (
        <Badge color={"warning"} {...props}>
          In Progress
        </Badge>
      )}
      {props.status === 2 && (
        <Badge color={"success"} {...props}>
          Finalized
        </Badge>
      )}
      {props.status === 3 && (
        <Badge color={"dark"} {...props}>
          Sent
        </Badge>
      )}
    </React.Fragment>
  );
};
