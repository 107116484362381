import React from "react";
import { Table, Row, Col } from "reactstrap";
import moment from "moment";

export default (props) => {
  const { entity } = props;
  return (
    <React.Fragment>
      <Row>
        <Col xs={6}>
          <Table size={"sm"} borderless={true} className={"mb-0"}>
            <tbody>
              <tr>
                <th className={"w-50"}>Report Type</th>
                <td>{entity.report_type.name}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <hr className={"my-1"} />
      <Row>
        <Col>
          <Table size={"sm"} borderless={true} className={"mb-0"}>
            <tbody>
              <tr>
                <th className={"w-50"}>Patient</th>
                <td className={"w-50"}>
                  {entity.patient.first_name} {entity.patient.last_name} (
                  {entity.patient.ecw_uid})
                </td>
              </tr>
              <tr>
                <th className={"w-50"}>Age</th>
                <td className={"w-50"}>{entity.age}</td>
              </tr>
              <tr>
                <th className={"w-50"}>HR</th>
                <td className={"w-50"}>{entity.hr}</td>
              </tr>
              <tr>
                <th className={"w-50"}>BP</th>
                <td className={"w-50"}>{entity.bp}</td>
              </tr>
              <tr>
                <th className={"w-50"}>Heart Rhythm</th>
                <td className={"w-50"}>
                  {entity.is_heart_rhythm_custom === 1 ? (
                    <React.Fragment>
                      {entity.heart_rhythm_custom}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {entity.heart_rhythm_id !== null
                        ? entity.heart_rhythm.description
                        : " "}
                    </React.Fragment>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col>
          <Table size={"sm"} borderless={true} className={"mb-0"}>
            <tbody>
              <tr>
                <th className={"w-50"}>Study Date</th>
                <td className={"w-50"}>
                  {moment(entity.study_date * 1000).format("MM/DD/YYYY")}
                </td>
              </tr>
              <tr>
                <th className={"w-50"}>DOB</th>
                <td className={"w-50"}>
                  {moment(entity.patient.dob).format("MM/DD/YYYY")}
                </td>
              </tr>
              <tr>
                <th className={"w-50"}>Height (inches)</th>
                <td className={"w-50"}>{entity.height}</td>
              </tr>
              <tr>
                <th className={"w-50"}>Weight (lbs)</th>
                <td className={"w-50"}>{entity.weight}</td>
              </tr>
              <tr>
                <th className={"w-50"}>BSA</th>
                <td className={"w-50"}>{entity.bsa}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <hr className={"my-1"} />
      <Row>
        <Col>
          <Table size={"sm"} borderless={true} className={"mb-0"}>
            <tbody>
              <tr>
                <th className={"w-50"}>Sonographer</th>
                <td className={"w-50"}>
                  {entity.technician_id !== null && (
                    <React.Fragment>
                      {`${entity.technician.first_name} ${entity.technician.last_name}`}
                      {entity.technician.designation.length > 0 && (
                        <React.Fragment>
                          , {entity.technician.designation}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </td>
              </tr>
              <tr>
                <th className={"w-50"}>Reading Physician</th>
                <td className={"w-50"}>
                  {entity.physician !== null && (
                    <React.Fragment>
                      {`${entity.physician.first_name} ${entity.physician.last_name}`}
                      {entity.physician.designation.length > 0 && (
                        <React.Fragment>
                          , {entity.physician.designation}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col>
          <Table size={"sm"} borderless={true} className={"mb-0"}>
            <tbody>
              <tr>
                <th className={"w-50"}>Reason for Study</th>
                <td className={"w-50"}>{entity.reason_for_study}</td>
              </tr>
              {entity.referring_physicians.map((physician) => {
                return (
                  <React.Fragment key={"report-view-physician-" + physician.id}>
                    <tr>
                      <th className={"w-50"}>Referring Physician</th>
                      <td className={"w-50"}>
                        {physician.last_name}, {physician.first_name},{" "}
                        {physician.suffix}
                      </td>
                    </tr>
                    <tr>
                      <th className={"w-50"}>Fax</th>
                      <td className={"w-50"}>{physician.fax}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </React.Fragment>
  );
};
