import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Alert, Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { email, maxLength, passwordCompare, required } from "validation";
import connect from "react-redux/es/connect/connect";
import LabelInput from "components/Elements/LabelInput/LabelInput";
import axiosInstance from "axios-instance";
import * as actions from "store/actions";
import PasswordModal from "./PasswordModal/PasswordModal";
import ConfirmModal from "components/Elements/Modals/Confirm";

const validator = {
  email: [required, email, (value) => maxLength(value, 255)],
  first_name: [required, (value) => maxLength(value, 64)],
  last_name: [required, (value) => maxLength(value, 64)],
  role: [required, (value) => maxLength(value, 64)],
  designation: [(value) => maxLength(value, 64)],
  echo_findings_access: [],
  status: [],
};

class MyAccount extends Component {
  constructor(props) {
    super(props);
    const authData = JSON.parse(localStorage.getItem("auth")).user;

    this.state = {
      loaded: false,
      isManager: authData.role === "Manager" ? 1 : 0,
      errorMessage: false,
      successMessage: false,
      validator: validator,
      modalPasswordActive: false,
      passwordError: false,
      successSignature: false,
      signature: "",
      entity: {},
    };

    this.getUser();
    this.getSignature(authData.id);
  }

  toggle = (modalType) => {
    this.setState((prevState) => ({
      [modalType]: !prevState[modalType],
      passwordError: false,
    }));
  };

  componentDidMount = () => {
    document.getElementById("root").click();
  };

  updateForm = (event, name) => {
    const inputData = {
      value: event.target !== undefined ? event.target.value : event,
      visited: true,
      error: false,
    };

    for (let i = 0; i < this.state.validator[name].length; i++) {
      const validationMsg = this.state.validator[name][i](inputData.value);
      if (validationMsg !== undefined) {
        inputData.error = validationMsg;
        break;
      }
    }
    if (name === "repassword" && inputData.error === false) {
      let validationPass = passwordCompare(
        inputData.value,
        this.state.password.value
      );
      if (validationPass !== undefined) {
        inputData.error = validationPass;
      }
    }

    console.log(inputData);
    this.setState({
      [name]: inputData,
      loaded: true,
    });
  };

  changePassword = (postData) => {
    axiosInstance()
      .put(`/users/update-my-account.json`, postData)
      .then((response) => {
        this.setState({
          modalPasswordActive: false,
          passwordError: false,
        });
      })
      .catch((err) => {
        this.setState({
          passwordError: err.response.data.message,
        });
      });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const formData = JSON.parse(JSON.stringify(this.state));
    const postData = {};
    let isValid = true;

    Object.keys(this.state.validator).forEach((key) => {
      postData[key] = formData[key].value;
      for (let i = 0; i < this.state.validator[key].length; i++) {
        const validationMsg = this.state.validator[key][i](formData[key].value);
        formData[key].visited = true;
        if (validationMsg !== undefined) {
          formData[key].error = validationMsg;
          isValid = false;
          break;
        } else {
          formData[key].error = false;
        }
      }
    });

    formData.validator = validator;
    this.setState({ ...formData, successMessage: false, errorMessage: false });

    if (isValid) {
      axiosInstance()
        .put(`/users/update-my-account.json`, postData)
        .then((response) => {
          this.setState({
            errorMessage: false,
            successMessage: "Cool! Your account has been updated. ",
          });
          this.props.updateStore({ ...postData });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return false;
  };

  onSignatureRemove = (e) => {
    axiosInstance()
      .get("/users/remove-signature.json")
      .then((response) => {
        this.setState({
          signature: "",
          confirmModalActive: false,
          successSignature: "Signature has been removed.",
        });
      })
      .catch((err) => {});
  };

  openFilePicker = () => {
    this.fileUpload.click();
  };

  getUser = () => {
    axiosInstance()
      .get(`/users/get-current.json`)
      .then((response) => {
        const fields = {};
        const user = response.data;
        Object.keys(validator).forEach((key) => {
          fields[key] = {
            value:
              user[key] !== undefined && user[key] !== null ? user[key] : "",
            visited: false,
            error: false,
          };
        });
        this.setState({
          ...fields,
          loaded: true,
        });
      });
  };

  getSignature = (userId) => {
    axiosInstance()
      .get(`/users/signature/${userId}.json`)
      .then((response) => {
        this.setState({
          signature: response.data.signature,
        });
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  onFileSelect = (e) => {
    if (this.fileUpload.files.length > 0) {
      const formData = new FormData();
      formData.append("file", this.fileUpload.files[0]);

      axiosInstance()
        .post("/users/signature.json", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.setState({
            signature: response.data.signature,
            successSignature: "Signature has been updated.",
          });
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
      this.fileUpload.value = "";
    }
  };

  render = () => {
    return (
      <Container>
        <Helmet>
          <title>NewportX | Manage My Account</title>
        </Helmet>
        <div className={"py-3"}>
          <h3>Manage My Account</h3>
          {this.state.loaded && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs={12} md={6}>
                        {this.state.errorMessage !== false && (
                          <Alert color={"danger"}>
                            {this.state.errorMessage}
                          </Alert>
                        )}
                        {this.state.successMessage !== false && (
                          <Alert color={"success"}>
                            {this.state.successMessage}
                          </Alert>
                        )}
                        <div className={"pb-3"}>
                          <strong>User Status:</strong>
                          {"  "}
                          {this.state.status.value === 1
                            ? "Active"
                            : "Inactive"}
                        </div>
                        <LabelInput
                          name={"Email"}
                          type={"email"}
                          required={true}
                          {...this.state.email}
                          onChange={(event) => this.updateForm(event, "email")}
                        />
                        <div className={"form-group"}>
                          <label className="font-weight-bold">Role</label>
                          <select
                            className={"form-control"}
                            value={this.state.role.value}
                            onChange={(event) => this.updateForm(event, "role")}
                            disabled={this.state.role.value !== "Manager"}
                          >
                            <option value={"Technician"}>Technician</option>
                            <option value={"Physician"}>Physician</option>
                            <option value={"Manager"}>Manager</option>
                          </select>
                        </div>
                        <div className="checkbox mb-3 text-left">
                          <label>
                            <input
                              type={"checkbox"}
                              checked={
                                this.state.echo_findings_access.value === 1
                              }
                              disabled={this.state.isManager === 0}
                              value={1}
                              onChange={(event) =>
                                this.updateForm(
                                  event.currentTarget.checked ? 1 : 0,
                                  "echo_findings_access"
                                )
                              }
                            />
                            &nbsp;&nbsp;&nbsp;Echo Findings Database Access
                          </label>
                        </div>
                        <Row>
                          <Col>
                            <LabelInput
                              name={"First Name"}
                              type={"text"}
                              required={true}
                              {...this.state.first_name}
                              onChange={(event) =>
                                this.updateForm(event, "first_name")
                              }
                            />
                          </Col>
                          <Col>
                            <LabelInput
                              name={"Last Name"}
                              type={"text"}
                              required={true}
                              {...this.state.last_name}
                              onChange={(event) =>
                                this.updateForm(event, "last_name")
                              }
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <LabelInput
                              name={"Designation"}
                              type={"text"}
                              {...this.state.designation}
                              onChange={(event) =>
                                this.updateForm(event, "designation")
                              }
                            />
                          </Col>
                        </Row>

                        <Button
                          color="primary"
                          className={"btn px-3"}
                          type={"submit"}
                          onClick={this.onSubmit}
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <h3>Signature</h3>
                        <p>Supported file formats: PNG, JPG/JPEG</p>
                        <p>
                          <strong>Preview</strong>
                        </p>
                        <Row>
                          <Col xs={9}>
                            {this.state.successSignature !== false && (
                              <Alert color="success">
                                {this.state.successSignature}
                              </Alert>
                            )}
                            <div className={"border-bottom text-center"}>
                              {this.state.signature.length === 0 ? (
                                "NO SIGNATURE AVAILABLE"
                              ) : (
                                <img
                                  alt={"Signature"}
                                  className={"img-fluid"}
                                  src={this.state.signature}
                                />
                              )}
                            </div>
                          </Col>
                          <Col xs={3}>
                            {this.state.signature.length !== 0 && (
                              <Button
                                color={"danger"}
                                onClick={() =>
                                  this.toggle("confirmModalActive")
                                }
                              >
                                Remove
                              </Button>
                            )}
                          </Col>
                        </Row>
                        <div>
                          <Button
                            color={"primary"}
                            className={"float-left mt-2 mb-2"}
                            size={"sm"}
                            onClick={this.openFilePicker}
                          >
                            Upload New
                          </Button>
                          <input
                            ref={(ref) => (this.fileUpload = ref)}
                            type={"file"}
                            className={"d-none"}
                            onChange={this.onFileSelect}
                            accept={".png,.jpg,.jpeg"}
                          />
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Button
                      color="primary"
                      className={"btn px-3"}
                      type={"submit"}
                      onClick={() => this.toggle("modalPasswordActive")}
                    >
                      Reset My Password
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>

        {this.state.modalPasswordActive && (
          <PasswordModal
            isActive={this.state.modalPasswordActive}
            toggle={(entity) => this.toggle("modalPasswordActive")}
            onSubmit={(postData) => this.changePassword(postData)}
            entity={{ email: this.props.email }}
            error={this.state.passwordError}
          />
        )}

        <ConfirmModal
          isActive={this.state.confirmModalActive}
          toggle={() => this.toggle("confirmModalActive")}
          body={"Please confirm to delete this signature."}
          confirmLabel={"Yes, delete this signature"}
          confirmAction={this.onSignatureRemove}
        />
      </Container>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    email: state.auth.email,
    name: state.auth.name,
    ...state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateStore: (user) => dispatch(actions.updateAccount({ ...user })),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
